export class MixPanelConstants {
  public static get EVENT_NAME() {
    return {
      HAVE_AN_ACCOUNT: "Have an account login clicked",
      LOGIN_FORGOT_PASSWORD: "Forgot password click",
      NEW_COIN_SIGNUP: "New to CoinBuck",
      CAPTCHA_VERIFIED: "Captcha verified",
      CAPTCHA_VERIFICATION_FAILED: "Captcha verification failed",
      LOGIN_UNSUCCESSFULL: "Login unsuccessful",
      LOGIN_SUCCESSFULL: "Login successful",
      SIGN_IN_WITH_GOOGLE: "Sign in with google",
      ALREADY_HAVE_AN_ACCOUNT: "Already have an account",
      SIGNUP_UNSUCCESSFULL: " Signup unsuccessful",
      SIGNUP_SUCCESSFULL: "Signup successful",
      SIGN_UP_WITH_GOOGLE: "Sign up with google",
      UPDATE_TASK_FAILED: "Update Task failed",
      UPDATE_TASK_SUCCESSFULL: "Update Task successfully",
      SAVE_TASK_FAILED: "Save Task failed",
      SAVE_TASK_SUCCESSFULLY: "Save Task successfully",
      ADD_CAMPAIGN: "Add Campaign",
      CREATE_CAMPAIGN: "Create Campaign",
      VIEW_CAMPAIGN_DETAIL: "View Campaign Details",
      EDIT_CAMPAIGN: "Edit Campaign",
      PUBLISH_CAMPAIGN: "Publish Campaign",
      DELETE_CAMPAIGN: "Delete Campaign ",
      PACKAGE_SELECT_AND_PROCEED: "Package select and proceed",
      PURCHASE_PACKAGE_SUCCESSFULLY: "Purchase Package Successfully",
      APPLY_COUPON: "Apply Coupon",
      BUY_NOW: "Buy Now Click",
      BUY_NOW_PACKAGE: "Buy Now Package Click",
      CLAIM_CANCEL_CLICK: "Claim cancel click",
      CLAIM_CLICK: "Claim click",
      SPLIT_DASHBOARD_CLICK: "Split Dashboard Click",
      TWITTER_TASK_SUBMITTED: "Twitter Task Submitted",
      CAMPAIGN_NOT_PERFORM: "Campaign not performed click : No",
      CAMPAIGN_PERFORM: "Campaign performed click : Yes",
      WITHDRAW: "Withdraw",
      WITHDRAW_DASHBOARD: "Withdraw Dashboard Page",
      WITHDRAW_NEW_DASHBOARD: "Withdraw New Dashboard Page",
      EXPLORE_CAMPAIGN_NEW_DASHBOARD: "Explore Campaign New Dashboard Page",
      ADD_CAMPAIGN_NEW_DASHBOARD: "Add Campaign New Dashboard Page",
      VIEW_ALL_NEW_DASHBOARD: "View All Button New Dashboard Page",
      COPY_REFER_CODE_NEW_DASHBOARD: "Copy Refer Code New Dashboard Page",
      PERFORM_CAMPAIGN_TASK_NEW_DASHBOARD: "Perform Campaign Task New Dashboard Page",
      PERFORM_COMMUNITY_TASK_NEW_DASHBOARD: "Perform Campaign Task New Dashboard Page",
      KYC_PROCEED: "Kyc proceed click",
      START_KYC_PROCESS: "Start KYC process",
      KYC_SKIP_NOW: "Kyc Skip Now",
      ERROR_KYC_PAY: "Error : KYC Pay With Buck",
      SUCCESS_KYC_PAY: "Success : KYC Pay With Buck",
      BUY_BUCK_KYC: "Buy Buck KYC Click",
      BUY_BUCK:"Buy Buck Click",
      ADD_FUND: "Add Fund Valid Click",
      USER_DOES_SOME_CAMPAIGN_TASKS: "User does some campaign tasks",
      WALLET_TRANSACTIONS: "Wallet Transactions",
      WALLET_ACTIVITIES: "Wallet Activities",
      VESTING: "Vesting",
      CONFIRM_WITHDRAW: "Confirm Wallet Address",
      CONFIRM_WITHDRAWAL_ADDRESS: "Confirm Wallet Address",
      WITHDRAWAL_SUBMITTED_SUCCESSFULLY: "Withdrawal Submitted successfully ",
      WITHDRAWAL_SUBMITTED_ERROR: "Withdrawal Submitted unsuccessfully : Error ",
      NEXT_BUTTON_CLICK: "Next Button Clicked",
      I_HAVE_PAID: "I have paid button",
      NOT_PAID: "Not paid",
      PROCEED_TO_DASHBOARD: "Proceed to dashboard",
      DASHBOARD_CLICK: "Dashboard click",
      ALL_CAMPAIGN_CLICK: "All Campaign click",
      REWARD_HISTORY_CLICK: "Reward History click",
      BUY_PACKAGE_CLICK: "Buy Package click",
      MY_CAMPAIGNS_CLICK: "My Campaigns click",
      WALLET_CLICK: "Wallet click",
      MY_TEAM_CLICK: "My Team click",
      PROMOTIONS_CLICK: "Promotions click",
      FOOTER_DASHBOARD_CLICK: "Footer Dashboard Click",
      FOOTER_EARN_CLICK: "Footer Earn Click",
      FOOTER_PACKAGES_CLICK: "Footer Packages Click",
      FOOTER_WALLET_CLICK: "Footer Wallet Click",
      FOOTER_REFERRALS_CLICK: "Footer Referrals Click",
      KYC_VERIFICATION_SUCCESSFUL: "KYC Verification successful",
      KYC_VERIFICATION_FAILED: "KYC Verification failed",
      GOOGLE_SIGNUP_SUCCESSFULLY:"Google Sign up successfully",
      GOOGLE_SIGNIN_SUCCESSFULLY:"Google Sign in successfully",
      CHANGE_WITHDRAW:"Change Withdraw Address",
      PROCEED_BUTTON_CLICK: "Proceed Withdraw Sussessfully",
      CANCEL_WITHDRAW: "Cancel Withdraw",
      BUY_IN_SALE: "Buy In Sale Click",
      YES_DELETED_CAMPAIGN: " Campaign Deletted Successfully",
      NO_DELETED_CAMPAIGN: "Campaign Not Deletted",
      CANCEL_CAMPAIGN : "Cancel Campaign Click",
      CONTINUE_CAMPAIGN : "Continue Campaign Click",
      OK_CAMPAIGN : "Ok Campaign Click",
      WITHDRWAR_CLAIM : "Withdraw Claim Click aftre Wallet address confirm",
      CANCEL_TWITTER_HANDLE : "Cancel Enter Twitter Handle Click",
      TWITTER_DATA_ADDED : "Add Click on Enter Twitter Handle ",
      CONNECT_TELEGRAM_OK:"OK Click, on please connect your telegram",
      KYC_BACK_CLICK:"Kyc Back Button Click",
      VERIFY_BUTTON_CLICK:"Verify Button Click",
      RESEND_OTP:"Resend OTP Button Click",
      VERIFY_OTP:"Verify OTP Button Click",
      WALLET_V2_CLICK:"Split Wallet Click",
      SPLIT_ADD_FUND:"Split Add Fund Click",
      SPLIT_WITHDRAW:"Split Withdraw Click",
      SPLIT_WALLET_TRANSACTIONS: "Split Wallet Transactions",
      SPLIT_WALLET_ACTIVITIES: "Split Wallet Activities",
      SPLIT_VESTING: "Split Vesting",
      REPORT_ISSUE_CLICK_BUTTON:"Report issue click button",
      SUBMIT_REPORT_SUCCESSFULLY:"Submit Report Successfully",
      ADD_FUND_HEADER:"Add Fund Header Click",
      DASHBOARD_BOOSTER_REWARDS_CLICK:"Dashboard Booster Rewards Click",
      ALL_CAMPAIGNS_BOOSTER_REWARDS_CLICK: "All Campaigns Booster Rewards Click",
      PURCHASE_ADD_BUTTON_CLICK:"Click Add Button Buy Page",
      CLICK_HERE_TO_ADD_FUND:"Click here to insufficient balance",
      ADD_FUND_BUY_PACKAGE:"Click Add Fund Buy Page ",
      WITHDRAW_TOKEN_REWARD: "Withdraw token reward"

    };
  }
  
  public static get PROPERTIES() {
    return {
      FORGOT_PASSWORD_FIELD: "Forget Password",
      LOGIN_METHOD: "Email Login",
      LOGIN_ATTEMPT_FIELD: "Login Attempt",
      GOOGLE_LOGIN: "Google Login",
      SIGNUP_ATTEMPT: "Signup Attempt",
      EMAIL_SIGNUP: "Email Signup",
      GOOGLE_SIGNUP: "Google Signup",
      PAYMENT_DETAILS: "payment details",
      PACKAGES_PAGE: "Packages page",
      EARNING_OPPORTUNITIES: "Earning opportunities",
      TASK_CLAIM: "Task Claim (Earning-Opportunity)",
      DASHBOARD: "Dashboard",
      NEW_DASHBOARD: "New Dashboard",
      KYC: "KYC",
      LOGIN_ATTEMPT: "Login Attempt",
      KYC_PAGE: "Kyc Page",
      EARNING_OPPORTUNITIE: "Earning opportunities",
      WALLET_PAGE: "Wallet page",
      WITHDRAW_PAGE: "Withdraw page",
      CLICK_HERE: "Click Here",
      SIGNUP_SUCCESSFULLS: "Signup Successfull",
      BUY_PAGE: "Buy page",
      CAMPAIGNS_LIST:"Campaigns List",
      PACKAGES:"Packages",
      SIGN_UP_SUCCESSFULLY:"Sign up successfully",
      SIGN_IN_SUCCESSFULLY:"Sign in successfully",
      NEW_BUY_PACKAGE:"Visit Buy Package Page",
    };
  }

  public static get UTM_CONST(){
    return {
      CHANGE_PASSWORD:"Visit Change Password Page",
      FORGOT_PASSWORD:"Visit Forgot Password Page",
      LOGIN:"Visit Login Page",
      OTP_VERIFICATION:"Visit OTP Verification Page",
      SIGNUP:"Visit Signup Page",
      SOCIAL_LOGIN:"Visit Social Login Page",
      ADD_CAMPAIGN:"Visit Add Campaign Page",
      CAMPAIGN_DETAIL:"Visit Campaign Detail Page",
      CAMPAIGN_LIST:"Visit Campaign List Page",
      CAMPAIGN_TRANSACTION:"Visit Campaign Transaction Page",
      MY_CAMPAIGN:"Visit My Campaign Page",
      // PACKAGE_CARD:"Visit Package Card Page",
      PACKAGES:"Visit Packages Page",
      PAYMENT_DETAILS:"Visit Payment Details Page",
      ACTIVITY_DASHBOARD:"Dashboard Visit Activity Page",
      // EARN_DETAILS:"Visit Earning Details Page",
      EARNED_CAMPAIGNS:"Visit Earned Campaigns Page",
      EARNING_OPPORTUNITIES:"Visit Earning opportunity Page",
      PRE_SALE:"Visit Pre-Sale Page",
      RAISE_ISSUE:"Visit Raise Issue Page",
      DASHBOARD:"Visit Dashboard Page",
      EARNINGS:"Visit Earnings Page",
      KYC:"Visit Kyc Page",
      KYC_FEE:"Visit Kyc Fee Page",
      SPLIT_WALLET:"Visit Split Wallet Page",
      REFERRAL_CAMPAIGNS:"Visit Referral Campaigns Page",
      USER_REFERALS:"Visit User Referrals Page",
      PERFORM_TASK:"Visit Perform Task Page",
      TASK_CATEGORY:"Visit Task Category Page",
      UPDATE_PROFILE:"Visit Update Profile Page",
      VESTING:"Visit Vesting Page",
      WALLET:"Visit Wallet Page",
      WITHDRAW:"Visit Withdraw Page",
      WITHDRAW_LISTING:"Visit Withdraw Listing Page",
      ONBOARDING_QUESTINS:"Visit Onboarding Questions Page",
      YOUTUBE_WATCH:"Visit Youtibe Watch Task Page",
      BUY:"Visit Buy Page",
      PROMOTION_PAGE:"Visit Promotion Page",

    }
  }
}
 