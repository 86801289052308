<ng-template #claim let-modal>
	<div class="modal-header">
		<h4 *ngIf="!isWithdrawProceed" class="modal-title">Enter your wallet address for further process.</h4>
		<h4 *ngIf="isWithdrawProceed" class="modal-title">Confirm your withdrawal</h4>
		<button type="button" aria-label="Close" class="close" (click)="closeModal()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body pt-2 mb-3" style="padding-bottom: 0">

		<div class="custom_form" *ngIf="!isWithdrawProceed">
			<div class="card mb-0" style="border-color: #4e4e4e !important;">
				<div class="row align-items-center">
					<div class="col-9 col-lg-8"> <p> Total Tokens </p></div>
					<div class="col-3 col-lg-4 text-end"><p>{{tokenName}} <span>{{ totalWithdrawTokens | decimal: 2 }}</span></p></div>

				</div>
			</div>
			<!-- <input type="email" class="form-control" placeholder="Total Tokens">
			<div class="label_right"><label> BUCK</label> <span> 91.66</span></div> -->
		</div>
		<form [formGroup]="withdrawForm">
		<div class="custom_form mb-2">
			<input type="text" [readonly]="isWalletAddressConfirm" class="form-control" placeholder="Add your wallet address here" formControlName="walletAddress">
			<div class="label_right" style="top: 6px; right: 6px;">
				<button *ngIf="!isWalletAddressConfirm"  [ngClass]="walletAddress?.value ? 'btn_confirm_active': 'btn_confirm'" (click)="onConfirmWalletAddress();">Confirm</button>
				<span *ngIf="!isWithdrawProceed && isWalletAddressConfirm" class="verified-text">Verified</span>
				<button *ngIf="isWithdrawProceed && isWalletAddressConfirm" class="btn_change" (click)="onChangeWalletAddress();mixPanel.trackEvent(mixPanelConst?.EVENT_NAME?.CHANGE_WITHDRAW,{})">Change</button>
			</div>
			
			<p *ngIf="!isWithdrawProceed" class="text-secondary mt-1 mb-0" style="font-size: 12px; padding-left: 12px;"><i>Note : Please add valid BEP20 wallet address. Please share your BSC wallet addresses only.</i></p>
			<p *ngIf="isWithdrawProceed" class="text-white mt-1 mb-0 " style="font-size: 12px;"><i>Note : Incase of loss of amount due to incorrect wallet address,COINBUCK will not be held responsible.</i></p>
			<app-show-errors [control]="walletAddress" [fname]="'Wallet Address'" class=" ">
			</app-show-errors>
			
		
		</div>
		</form>
		<!-- <hr/> -->
		
		<div *ngIf="isWithdrawProceed" class="groupcheckbox mt-1 d-flex"><input type="checkbox" (change)="handleConfirmation()" id="html" style="cursor: pointer; margin-right: 5px;">
			<label for="html" class="text-white"> Yes, I want to proceed further</label></div>
		<span class="error_msg" *ngIf="errorMessage">{{ errorMessage }}</span>
	</div>
	<div *ngIf="!isWithdrawProceed" class="button-widthrawl modal-footer">
		<button [disabled]="errorMessage" class="btn btn-primary" (click)="onWithdrawProceed();mixPanel.trackEvent(mixPanelConst?.EVENT_NAME?.WITHDRWAR_CLAIM,{});">
			Withdrawal
		</button>
	</div>
	<div *ngIf="isWithdrawProceed" class="button-widthrawl modal-footer">
		<a href="javascript:void(0)" (click)="closeModal();mixPanel.trackEvent(mixPanelConst?.EVENT_NAME?.CANCEL_WITHDRAW,{})" class="btn btn-outline-light text-white"
		  style="margin-right: 10px; height: 40px !important; line-height: 40px !important;"> Cancel</a>
		<button class="btn btn-primary" (click)="onSubmitWithdraw();">
			Proceed
		</button>
	</div>

</ng-template>
