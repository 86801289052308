import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../services/app.service';
import { LoaderService } from '../shared/components/loader/loader.service';
import { ToastrService } from 'ngx-toastr';
import { Constants } from '../config/constant';
import { Messages } from '../config/messages';
import { Router } from '@angular/router';
import { CommonApiService } from '../shared/services/common-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AmountService } from '../shared/services/amount.service';
import { MixpanelService } from '../shared/services/mix-panel.service';
import { MixPanelConstants } from 'src/app/config/mix-panel-constant';

declare var $: any;
@Component({
	selector: 'app-onboarding-question',
	templateUrl: './onboarding-question.component.html',
	styleUrls: ['./onboarding-question.component.css']
})
export class OnboardingQuestionComponent implements OnInit {
	// onBoardingQuestions:any = [];
	// selectedIndex = 0;
	// selectedQuestion:any;
	// selectedQuestionId = '';
	// response = '';
	// responseArr = [];
	// showErrorMsg = false;
	// userDetails:any ;
	// showQuestions = true;
	// constructor(private appService: AppService, private loaderService: LoaderService, private toaster: ToastrService, private router: Router, private commonService: CommonApiService) { }

	tokenBonus = 0;
	mixPanelConst:any=MixPanelConstants;

	constructor(private mixPanel:MixpanelService,private router: Router, private amountService: AmountService, private commonApi: CommonApiService){}

	ngOnInit(): void{
		const urlParams = new URLSearchParams(window.location.search);
		const utmSource = urlParams.get('utm_source');
		const utmMedium = urlParams.get('utm_medium');
		const utmCampaign = urlParams.get('utm_campaign');
		if(utmSource || utmMedium || utmCampaign ){
			
			this.mixPanel.trackEvent(this.mixPanelConst?.UTM_CONST.ONBOARDING_QUESTINS);
		}
		this.tokenBonus = this.commonApi.getTokenBonus();
		if(!localStorage.getItem('new_user')){
		this.router.navigate(["/main/dashboard"])
		}
	}

	//   ngOnInit(): void {
	// 	this.userDetails = this.commonService.getCurrentUser();
	// 	if(this.userDetails?.onboardingQuestionAnswered){
	// 		this.router.navigate(["/main"]);
	// 		return;
	// 	}
	// 	this.getOnboardingQuestions();
	//   }

	//   getOnboardingQuestions(){
	// 	this.showQuestions = false;
	// 	this.loaderService.show();
	// 	this.appService.getOnboardingQuestions().subscribe((res)=>{
	// 	this.showQuestions = true;
	// 		this.loaderService.hide();
	// 		if(!res?.error){
	// 			this.onBoardingQuestions = res?.data || [];
	// 			let allAnswered = false;
	// 			for (const [i, element] of (this.onBoardingQuestions || []).entries()) {
	// 				if (!element?.ifAnswered) {
	// 				  allAnswered = false
	// 				  this.selectedIndex = i;
	// 				  this.selectedQuestion = element;
	// 				  this.selectedQuestionId = element?._id;
	// 				  break; 
	// 				}else{
	// 					allAnswered = true;
	// 				}
	// 			  }
	// 			  if(allAnswered){
	// 				this.router.navigate(["/main"])
	// 			  }


	// 		}
	// 	}, (error)=>{
	// 	this.showQuestions = true;
	// 		this.loaderService.hide();
	// 	})
	//   }

	//   submitResponse(){
	// 	if(!this.response && (this.responseArr?.length != this.selectedQuestion?.options?.length)){
	// 		this.showErrorMsg = true;
	// 		return;
	// 	}
	// 	let ans = [];
	// 	if(this?.response){
	// 		ans.push(this.response);
	// 	}else{
	// 		ans = this.responseArr;
	// 	}

	//   let dataToSend = {
	// 	"questionId": this.selectedQuestionId,
	// 	"answers": ans
	//   }
	// 	this.loaderService.show();
	// 	this.appService.submitResponse(dataToSend).subscribe((res)=>{
	// 		this.loaderService.hide();
	// 		if(res?.error){
	// 			this.toaster.error( Messages.CONST_MSG.TRY_AGAIN, res?.message || Constants.CONSTANTS.SOMETHING_WENT_WRONG )
	// 		}else{
	// 			if(this.selectedIndex == (this.onBoardingQuestions?.length-1)){
	// 				this.userDetails.onboardingQuestionAnswered = true;
	// 				localStorage.setItem("_u", JSON.stringify(this.userDetails))
	// 				this.router.navigate(["/main"])
	// 			}else{
	// 				this.response = '';
	// 				this.responseArr = [];
	// 				this.selectedIndex = this.selectedIndex +1;
	// 				this.selectedQuestion = this.onBoardingQuestions[this.selectedIndex];
	// 				this.selectedQuestionId = this.selectedQuestion?._id;
	// 			}

	// 		}
	// 	}, (error)=>{
	// 		this.loaderService.hide();
	// 		this.toaster.error(Messages.CONST_MSG.TRY_AGAIN, error?.error?.message || Constants.CONSTANTS.SOMETHING_WENT_WRONG );
	// 	})
	//   }

	//   handleResponse($event){
	// 	this.response = $event?.target?.value;
	// 	this.showErrorMsg = false;
	//   }
	//   handleMultipleResponse($event, i){
	// 	this.responseArr[i] = $event?.target.value;
	// 	if(this.responseArr?.length == this.selectedQuestion?.options?.length){
	// 		this.showErrorMsg = false;
	// 	}
	//   }
	//   handleArrowChange() {
	//     var angle = $("#image").data("angle") + 180 || 180;
	//     $("#image").css({ transform: "rotate(" + angle + "deg)" });
	//     $("#image").data("angle", angle);
	//   }


	// Function to navigate to Twitter group
	goToTwitter() {
		window.open('https://twitter.com/coinbuckX', '_blank');
	}

	// Function to navigate to Telegram group
	goToTelegram() {
		window.open('https://t.me/coinbuck_chat', '_blank');
	}

	// Function to navigate to the dashboard
	goToDashboard() {

		this.mixPanel.trackEvent(MixPanelConstants?.EVENT_NAME?.NEXT_BUTTON_CLICK,{field: MixPanelConstants?.PROPERTIES?.SIGNUP_SUCCESSFULLS});
		
		if(localStorage.getItem('performCampaignId')){
			this.router.navigate(['main/start-earning/details', localStorage.getItem('performCampaignId')])
		  }
		  else	if(localStorage.getItem('isWebBoosterRedirect')){
			this.router.navigate(['main/start-earning/details'], { queryParams: { isWebBoosterRedirect: 'true' } });
		  }else if(localStorage.getItem('selectedPackageRedirect')){
			this.router.navigate(['main/packages/payment', localStorage.getItem('selectedPackageRedirect')])
		  }else{
			this.router.navigate(['/main/dashboard']);
		  }
	}
}

