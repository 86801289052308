import { Injectable } from '@angular/core';
import { HttpencapService } from 'src/app/shared/services/httpencap.service';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private http: HttpencapService) { }

  getWalletLogs(data){
		return this.http.get('users/v1/user/wallet/logs', data);
	}
  getWalletTransaction(data){
		return this.http.get('funds/v1/user/fund/transaction',data);
	}
  cancelWalletTransaction(data){
		return this.http.patch('transactions/v1/admin/update/wallet/transaction',data);
  }
	
	getTodayStats(){
		return this.http.get('transactions/v1/user/coins/today/stats');
	}
	getWeeklyStats(){
		return this.http.get('transactions/v1/user/coins/weekly/stats');
	}
	getMothlyStats(){
		return this.http.get('transactions/v1/user/coins/monthly/stats');
	}
	getTotalEarning(){
		return this.http.get('transactions/v1/user/earnings');
	}
	getRewardBonus(){
		return this.http.get('affiliates/v1/all/referral/earning')
	}
	createWithdrawRequest(data){
		return this.http.post('withdrawals/v1/add',data);
  	}
	getCommunityTaskPerformed(data){
		return this.http.get("withdrawals/v1/check/elligibility", data)
	}
	verifyWithdrawConcern(data){
		return this.http.patch("users/v1/user/withdrawal/consent", data)
	}
	getTransactionFeeDeduct(data?){
		return this.http.get("withdrawals/v1/get/fee/deduct/component", data)
	}
	getOrderSummaryDetails(data){
		return this.http.get("funds/v1/order/summary",data)
	}
	createKometWallet(data){
		return this.http.post("kometWallets/v1/create",data)
	}
	checkKometWalletConnected(){
		return this.http.get("kometWallets/v1/exists")
	}
	checkKometWalletToShow(){
		return this.http.get("kometWallets/v1/check/gsuite/email")
	}
	checkUniqueWalletAddress(data){
		return this.http.get("withdrawals/v1/wallet/address/elligibility", data)
	}

}
