<!-- Footer opened -->
 
<div class="main-footer">
	<div class="container pt-0">
		<div class="row align-items-center">
			<div class="col-sm-9 pl-sm-0">
				<div class="text-left">
					<img src="../../../../assets/img/brand/logo-white.png" alt="footerLogo" class="logo-footer">
					<p>CoinBuck is a platform where one can perform unlimited tasks and get rewarded for their efforts.</p>
				</div>
			</div>
			<div class="col-sm-3 pl-sm-0">
				<div class="row">
					<div class="col-4 col-sm-6">
						<ul>
							<li><h6>Coinbuck</h6></li>
							<li><a routerLink="/main/dashboard" (click)="scrollToTop(mixPanelConst?.EVENT_NAME?.FOOTER_DASHBOARD_CLICK)">Dashboard</a></li>
							<li><a routerLink="/main/start-earning/details" (click)="scrollToTop(mixPanelConst?.EVENT_NAME?.FOOTER_EARN_CLICK)">Earn</a></li>
							<li><a routerLink="/main/packages" (click)="scrollToTop(mixPanelConst?.EVENT_NAME?.FOOTER_PACKAGES_CLICK)">Packages</a></li>
							<li><a routerLink="/main/wallet" (click)="scrollToTop(mixPanelConst?.EVENT_NAME?.FOOTER_WALLET_CLICK)">Wallet</a></li>
							<li><a routerLink="/main/refer/referred-users" (click)="scrollToTop(mixPanelConst?.EVENT_NAME?.FOOTER_REFERRALS_CLICK)">Referrals</a></li>
						</ul>
					</div>
					<div class="col-4 col-sm-6">
						<ul>
							<li><h6>Contact Us</h6></li>
							<li><a href="https://twitter.com/coinbuckX" target="_blank">Twitter</a>	</li>		
							<li><a href="https://t.me/coinbuck" target="_blank">Telegram</a></li>
							<li><a href="https://www.facebook.com/coinbuckX" target="_blank">Facebook</a></li>
							<li><a href="https://www.youtube.com/channel/UCLWxKeDgZifCdt0E_gjsueQ" target="_blank">Youtube</a></li>
							<li><a href="https://www.instagram.com/coinbuckx/" target="_blank">Instagram</a></li>
						</ul>
					</div>
					<div class="col-4 d-block d-sm-none p-sm-0">
						<ul>
							<li style="visibility: hidden;"><h6>Contact Us</h6></li>		

							<li><a target="_blank" href="https://coinbuck.com/privacy.html">Privacy Policy</a>	</li>		
							<li><a target="_blank" href="https://coinbuck.com/about.html">Youtube Policy</a></li>
							<li><a target="_blank" href="https://coinbuck.com/terms.html">Terms & Conditions</a></li> 
						</ul>
					</div>
				</div>
			</div>
			
		</div>
	
	</div>
	<div class="copyrightText ht-100p">
		<div class="container">
			<div class="row align-items-center">
				<div class="col-sm-7">
					<span>© 2024 CoinBuck. All rights reserved.</span>
				</div>
				<div class="col-sm-5 d-none d-sm-block">
					<div class="termsstyle">
						<a target="_blank" href="https://coinbuck.com/privacy.html">Privacy Policy</a>
						<a target="_blank" href="https://coinbuck.com/about.html">Youtube Policy</a>
						<a target="_blank" href="https://coinbuck.com/terms.html">Terms and Conditions</a>
					</div>
					
				</div>
			</div>
		</div>
		
		
	</div>
</div>

<!-- Footer closed -->