<!-- main-header opened -->
<div class="main-header nav nav-item hor-header">
	<div class="container">
		<div class="main-header-left ">

			<!-- sidebar-toggle-->
			<a class="header-brand d-none d-sm-block" routerLink="/main/dashboard" (click)="scrollToTop()">
				<img src="assets/img/brand/logo-white.png" class="logo-white">
			</a>
			<div class="d-block d-sm-none">
				<a class="header-brand" routerLink="/main/dashboard" (click)="scrollToTop()">
					<img src="../../../../assets/img/cb-icon.png" class="logo-white">
				</a>
			</div>
		</div>
		<!--Horizontal-main -->
		<div class="sticky">
			<div class="horizontal-main hor-menu clearfix side-header">
				<div class="horizontal-mainwrapper clearfix">
					<!--Nav-->
					<div>


						<nav class="horizontalMenu clearfix">
							<div class="user_name">
								<div class="d-flex mt-5 align-items-center">
									<!-- <div class="user_img"><img alt="User Profile Picture"
										[src]="userData?.profilePicture || 'assets/img/profile.png' "></div> -->
									<a class="user_img" href="javascript:void(0)"
										style="position:relative; border-radius: 12px; background: #fff; "
										[ngStyle]="{'background-color': userData?.profilePicture ? 'black' : '#fff'}">
										<img class="nav-link-img" *ngIf="userData?.profilePicture"
											[src]="userData?.profilePicture">
										<span *ngIf="!userData?.profilePicture">{{userData?.nameInitial}}</span>
									</a>
									<div class="ml-2">
										<h6 class="mb-2 mt-1">{{userData?.name || '--'}}</h6>
										<!-- <p class="mb-0"><a class="text-light" style="text-decoration: underline;font-size: 12px;
									font-weight: 400;"  (click)="goToKYC()" *ngIf="!kycVarified" href="javascript:void(0)"
									routerLink="/main/kyc">
								 
									Complete KYC
								</a></p> -->
										<!-- <p class="mb-0"><a  style="cursor: not-allowed !important;font-size: 12px;
									font-weight: 400; color: #fff !important; text-decoration: underline;"
									*ngIf="kycVarified" href="javascript:void(0)">
									
									 Verified <i class="fa ml-1"> <img src="../../../../assets/img/icons/verified.svg"></i>
								</a></p> -->
									</div>

									<!-- <div class="edit_profile" aria-haspopup="true">
								<a class="text-white" href="javascript:void(0)" routerLink="/main/edit-profile"
							(click)="editProfile()">
							Edit <i class="fa ml-1">
								<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
									<path d="M7.54743 0.444444C7.6789 0.308511 7.83616 0.200086 8.01004 0.125496C8.18392 0.0509061 8.37093 0.0116446 8.56016 0.0100025C8.7494 0.00836032 8.93707 0.0443705 9.11222 0.115932C9.28737 0.187493 9.44649 0.293172 9.58031 0.426803C9.71412 0.560434 9.81995 0.71934 9.89161 0.89425C9.96327 1.06916 9.99933 1.25657 9.99768 1.44555C9.99604 1.63452 9.95672 1.82128 9.88203 1.99492C9.80734 2.16856 9.69876 2.3256 9.56264 2.45689L9.17421 2.84479C9.07661 2.94226 8.9185 2.94226 8.8209 2.84479L7.15948 1.18565C7.06169 1.08799 7.06169 0.929515 7.15948 0.831856L7.54743 0.444444ZM6.15139 2.19133C6.05378 2.09389 5.8957 2.0939 5.79811 2.19136L0.077251 7.90435C0.0302925 7.95125 0.00390625 8.01489 0.00390625 8.08125V9.74004C0.00390625 9.87811 0.115835 9.99004 0.253906 9.99004H1.91568C1.98192 9.99004 2.04545 9.96375 2.09232 9.91695L7.81347 4.20435C7.91128 4.10668 7.91127 3.94817 7.81345 3.85051L6.15139 2.19133Z" fill="white"/>
									</svg>
							</i>
							 
						</a>
							</div> -->


								</div>
							</div>
							<ul class="horizontalMenu-list">
								<li aria-haspopup="true" (click)="setCurrentModule('dashboard')">
									<a href="javaScript:void(0)" routerLink="/main/dashboard"
										(click)="trackEvent(mixPanelConst?.EVENT_NAME?.DASHBOARD_CLICK)"
										routerLinkActive='active'>
										<i class="fa menu-icon" aria-hidden="true">
											<img src="../../../../assets/img/icons/dash.svg">
										</i>
										<!-- <i class="fa-solid fa-grid-horizontal"></i> -->
										Home
									</a>
								</li>
								<li aria-haspopup="true" (click)="setCurrentModule('income')">
									<a routerLinkActive='active' routerLink="/main/start-earning/details"
										(click)="trackEvent(mixPanelConst?.EVENT_NAME?.ALL_CAMPAIGN_CLICK)"
										class="sub-icon">
										<i class="fa menu-icon" aria-hidden="true">
											<img src="../../../../assets/img/icons/income.svg">
										</i>
										Earn <i class="fa fa-chevron-down horizontal-icon"></i>
									</a>
									<ul class="sub-menu">
										<li aria-haspopup="true"><a routerLinkActive='active'
												routerLink="/main/start-earning/details"
												(click)="trackEvent(mixPanelConst?.EVENT_NAME?.ALL_CAMPAIGN_CLICK )">All
												Campaigns</a></li>
										<!-- <li aria-haspopup="true">
										<a routerLinkActive='active' routerLink="/main/start-earning/perform-task">
											Category-wise Campaign</a>
									</li> -->
										<li aria-haspopup="true">
											<a routerLink="/main/earnings"
												(click)="trackEvent(mixPanelConst?.EVENT_NAME?.REWARD_HISTORY_CLICK)"
												routerLinkActive='active'>Rewards History</a>
										</li>

									</ul>
								</li>
								<li aria-haspopup="true" (click)="setCurrentModule('packages')">
									<a routerLinkActive='active' routerLink="/main/packages"
										(click)="trackEvent(mixPanelConst?.EVENT_NAME?.BUY_PACKAGE_CLICK)">
										<i class="fa  menu-icon"> <img src="../../../../assets/img/icons/pkg.svg"></i>
										Packages <i class="fa fa-chevron-down horizontal-icon"></i>
									</a>
									<ul class="sub-menu">
										<li aria-haspopup="true">
											<a routerLinkActive='active' routerLink="/main/packages"
												(click)="trackEvent(mixPanelConst?.EVENT_NAME?.BUY_PACKAGE_CLICK)">

												Buy Package
											</a>
										</li>
										<li aria-haspopup="true">
											<a routerLinkActive='active' routerLink="/main/packages/campaign-list"
												(click)="trackEvent(mixPanelConst?.EVENT_NAME?.MY_CAMPAIGNS_CLICK)">

												My Campaigns
											</a>
										</li>
									</ul>
								</li>
								<li aria-haspopup="true" (click)="setCurrentModule('wallet')">
									<a [routerLink]="['/main/wallet']" routerLinkActive='active'
										(click)="trackEvent(mixPanelConst?.EVENT_NAME?.WALLET_CLICK)">
										<i class="fa  menu-icon"> <img
												src="../../../../assets/img/icons/wallet.svg"></i>
										Wallet
									</a>

								</li>


								<li aria-haspopup="true" (click)="setCurrentModule('referrals')">
									<a routerLinkActive='active' routerLink="/main/refer/referred-users"
										(click)="trackEvent(mixPanelConst?.EVENT_NAME?.MY_TEAM_CLICK)">
										<i class="fa  menu-icon"> <img src="../../../../assets/img/icons/ref.svg"></i>
										Referrals <i class="fa fa-chevron-down horizontal-icon"></i>
									</a>
									<ul class="sub-menu">
										<li aria-haspopup="true">
											<a routerLinkActive='active' routerLink="/main/refer/referred-users"
												(click)="trackEvent(mixPanelConst?.EVENT_NAME?.MY_TEAM_CLICK)">

												My Team
											</a>
										</li>
										<li aria-haspopup="true">
											<a routerLinkActive='active' routerLink="/main/refer/referral-campaigns"
												(click)="trackEvent(mixPanelConst?.EVENT_NAME?.PROMOTIONS_CLICK)">

												Promotions
											</a>
										</li>


									</ul>
								</li>

								<!-- <li *ngIf="innerWidth <= 500" class="destop_none" aria-haspopup="true" (click)="setCurrentModule('connection', true)">
								<a>
									<i class="fa menu-icon"> <img src="../../../../assets/img/icons/contact.svg"></i>
									Connect <i class="fa fa-chevron-down horizontal-icon"></i>
								</a>
								<ul class="sub-menu">
								  
									<app-social-connection [mobileView]="true"></app-social-connection>

								</ul>
							</li> -->



							</ul>


							<ul class="destop_none logout horizontalMenu-list">

								<li class="mt-3" aria-haspopup="true">
									<a class="text-white" href="javascript:void(0)" routerLink="/main/edit-profile"
										(click)="editProfile()">
										<i class="fa  menu-icon"> <img
												src="../../../../assets/img/icons/profile.svg"></i>Profile

									</a>
								</li>
								<li>
									<a (click)="onSignOut()" class="dropdown-item" href="javascript:void(0)">
										<i class="fa  menu-icon"> <img
												src="../../../../assets/img/icons/signout.svg"></i>Sign Out
									</a>
								</li>
							</ul>




						</nav>
					</div>
					<!--Nav-->
				</div>
			</div>

		</div>
		<!--Horizontal-main -->
		<!-- search -->
		<div class="main-header-right">
			<div class="nav nav-item  navbar-nav-right ml-auto">
				<div class="wallet-header mt-0">
					<!-- <a class="walletbtn" id="add-fund" ngbTooltip="Will start after TGE" style="text-decoration: none; cursor: not-allowed !important;" >
						<i class="fa fa-plus" aria-hidden="true"></i>
						Buy Now
					</a> -->
					<!-- <a (click)="openModal(buyNowModal)" class="walletbtn" id="add-fund" style="text-decoration: none">
						<i class="fa fa-plus" aria-hidden="true"></i>
						Buy Now
					</a> -->
					<a (click)="openModal(buyNowModal)" class="walletbtn" id="add-fund" style="text-decoration: none">
						<i class="fa fa-plus" aria-hidden="true"></i>
						Add Fund
					</a>
					<!-- <a style="cursor: not-allowed;" ngbTooltip="Will open soon, keep checking telegram group." class="walletbtn"
							id="add-fund">
							<i class="fa fa-plus" aria-hidden="true"></i>
						   Add Fund
						</a> -->
					<!-- 					
					<p *ngIf="isLoggedIn && walletConnected">
							<img src="../../../../assets/img/TradeDogwallet.png" height="16" style="margin-right: 5px;" />
					
						<span class="balnce-text">
							Balance : </span>
						<b>  {{ amount || 0 }}</b>
						
	
					</p> -->
					<!-- <div *ngIf="isLoggedIn && !walletConnected">
						
						<button class="walletbtn" *ngIf="!walletConnected" (click)="creatUserWallet()">
							<img src="../../../../assets/img/TradeDogwallet.png" width="16">
							Enable Wallet
						</button>
					</div> -->

				</div>
			<button class="btn btn-primary" [hidden]="true" (click)="openModal(pleaseWaitConnectWallet)" ></button>
				<!-- <div class="nav-item main-header-notification">
					<a class="new nav-link" href="javascript:void(0)">
				
						<img src="../../../../assets/img/new-dashboard/bookmark.svg" alt="">
					 
				   </a>
				</div> -->
				<div class="dropdown nav-item main-header-notification">
					<a class="new nav-link" href="javascript:void(0)">
						<!-- <i class="fa-regular fa-bell"></i> -->
						<img src="../../../../assets/img/new-dashboard/notification.svg" alt="">
						<div><span *ngIf="notifications?.length" class="pulse"></span></div>
					</a>
					<div class="dropdown-menu">
						<div class="menu-header-content bg-primary-gradient text-left d-flex">
							<div class="">
								<h6 class="menu-header-title text-white mb-0">{{notifications?.length}} new
									Notifications</h6>
							</div>
							<div class="my-auto ml-auto" *ngIf="notifications?.length">
								<a class="badge badge-pill badge-warning float-right" href="javascript:void(0)"
									(click)="readAllNotification()">Mark All Read</a>
							</div>
						</div>
						<div class="main-notification-list Notification-scroll">
							<a class="d-flex p-3 border-bottom" href="javascript:void(0)"
								(click)="readOneNotification(notification.id)"
								*ngFor="let notification of notifications; let i=index" style="text-decoration: none;">
								<div class="notifyimg bg-success-transparent">
									<i class="fa fa fa-circle text-success"></i>
								</div>
								<div class="ml-3">
									<h5 class="notification-label mb-1" [ngbTooltip]="notification?.message">
										{{notification.message}}</h5>
									<div class="notification-subtext">{{notification.createdAt | date}}</div>
								</div>
								<div class="ml-auto">
									<i class="las la-angle-right text-right text-muted"></i>
								</div>
							</a>

						</div>
						<div class="dropdown-footer">
						</div>
					</div>
				</div>

				<div id="socialDropdown" class="dropdown nav-item main-header-notification">
					<a class="new nav-link socail-link ml-0" href="javascript:void(0)">
						<img src="../../../../assets/img/new-dashboard/globe.svg" alt="">

					</a>
					<div *ngIf="innerWidth > 500" class="dropdown-menu">
						<div class="main-header-profile header-img socail-connection">
							<h6>Connect Your Social Media</h6>
							<div class="socialicons-items">
								<app-social-connection></app-social-connection>
							</div>

						</div>
					</div>
					<div *ngIf="innerWidth <= 500" class="dropdown-menu">
						<div class="main-header-profile header-img socail-connection">
							<h6>Connect Your Social Media</h6>
							<div class="socialicons-items">
								<app-social-connection [mobileView]="true"></app-social-connection>
							</div>

						</div>
					</div>
				</div>

				<div id="myDropdown"
					class="dropdown main-profile-menu dropdown nav-item main-header-notification mobile_none tab_none">

					<a class="new nav-link profile-link ml-0 mr-0" href="javascript:void(0)" style="position:relative;"
						[ngStyle]="{'background-color': userData?.profilePicture ? 'black' : '#F1AC1C'}">
						<img class="nav-link-img" *ngIf="userData?.profilePicture" [src]="userData?.profilePicture">
						<span *ngIf="!userData?.profilePicture">{{userData?.nameInitial}}</span>
					</a>

					<div class="dropdown-menu">
						<div class="main-header-profile header-img">
							<div class="main-img-user"><img alt="User Profile Picture"
									[src]="userData?.profilePicture || 'assets/img/profile.png' "></div>
							<h6>{{userData?.name || '--'}}</h6>
						</div>

						<a class="dropdown-item" href="javascript:void(0)" routerLink="/main/edit-profile"
							(click)="editProfile()">
							<i class="fa fa-edit"></i>
							Edit Profile
						</a>
						<!-- <a class="dropdown-item" (click)="goToKYC()" *ngIf="!kycVarified" href="javascript:void(0)"
							routerLink="/main/kyc">
							<i class="fa fa-file"></i>
							Complete KYC
						</a>
						<a class="dropdown-item" style="cursor: not-allowed !important; color: #12d524 !important;"
							*ngIf="kycVarified" href="javascript:void(0)">
							<i class="fa fa-check"></i>
							 Verified
						</a> -->

						<a (click)="onSignOut()" class="dropdown-item" href="javascript:void(0)"><i
								class="fa fa-sign-out"></i> Sign Out
						</a>
					</div>
				</div>



			</div>
		</div>
		<a class="animated-arrow hor-toggle horizontal-navtoggle" id="sidebar-scroll"><span
				id="remove-scroll"></span></a>
	</div>

</div>

<!-- main-header closed -->

<ng-template #purchasePopupSecond let-modal>
	<div *ngIf="!gateResponse" class="modal-header">
		<h4 class="modal-title">Add Fund </h4>
		<button type="button" aria-label="Close" class="close" (click)="closeModal()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body seminor-login-modal-body" *ngIf="!gateResponse" style="padding-bottom:0;">

		<div class="create-new-fau">

			<div class="form-group mt-3">
				<label>Select payment method</label>
				<!-- <input type="text" [(ngModel)]="coinName" placeholder="Choose payment method" class="form-control"  required> -->
				<select [(ngModel)]="coinName" (change)="onChangeMethod($event)" class="form-control">
					<option value="" hidden selected>Choose payment method
					</option>
					<option *ngFor="let item of paymentMethodList" [disabled]="editMode" [value]="item">
						{{item}}</option>
				</select>


			</div>
			<div class="form-group">
				<label>Amount (In USD)</label>
				<input type="number" onwheel="event.preventDefault();" (input)="checkFundAmount()"
					(keydown)="restrictPlusMinus($event)" [(ngModel)]="amountToAdd" name="Amount" min="0"
					placeholder="00.00" class="form-control" required>

			</div>
			<p style="color: #feaf2e;">Approx. Token Amount: {{tokenAmount}} </p>
			<div class="form-group">
				<p *ngIf="errorMessage" style="color:red">{{errorMessage}} </p>
				<p *ngIf="successMessage" style="color:green">{{successMessage}} </p>
				<p> <span style="color: #f60; font-size:10px;">Note : </span>Due to humongous interest,
					it might take longer than
					usual for the funds to reflect in your wallet.</p>
			</div>

		</div>
	</div>
	<div class="button-widthrawl modal-footer" *ngIf="!gateResponse">
		<a href="javascript:void(0)" (click)="closeModal()" class="btn btn-danger btn-padding"
			style="margin-right: 10px;">Cancel</a>
		<a href="javascript:void(0)" (click)="createTransaction()" class="btn button-margin btn-padding">Add Amount</a>
	</div>
	<div *ngIf="gateResponse" class="modal-header">
		<h4 class="modal-title"> </h4>

	</div>
	<div class="modal-body seminor-login-modal-body" *ngIf="gateResponse">
		<div class="create-new-fau">
			<div class="col col-sm-12 col-xs-12 col-md-12 text-center p-0" style="position: relative;">
				<p class="qrHead">Please deposit <span>{{ gatewayData?.cryptoCoins }}
						{{gatewayData?.currency}}
					</span> to the below address.</p>
				<div class="col col-sm-12 col-xs-12 col-md-12 p-0">
					<p class="addressId">
						{{ gatewayData?.address }}

					</p>
					<img src="../../../../assets/img/copy.png" width="16px" title="Copy to clipboard"
						(click)="copyToClipboard(gatewayData?.address)" style="cursor: pointer;" class="cop-icon">
				</div>

			</div>
			<div class="qrcodeImage">
				<qrcode [qrdata]="walletAddress" [allowEmptyString]="true" [alt]="'A custom alt attribute'"
					[ariaLabel]="'QR Code image with the following content...'" [cssClass]="'center'"
					[colorDark]="'#000000ff'" [colorLight]="'#ffffffff'" [errorCorrectionLevel]="'M'" [margin]="4"
					[title]="'Copy this Address for payment'" [width]="300"></qrcode>
			</div>
			<div class="col col-sm-12 text-center " style="padding:10px 0px;">

				<button type="button" class="btn btn-success" (click)="closePopupPayment(1)">I've paid</button> &nbsp;
				&nbsp;
				<button type="button" class="btn btn-danger btn-padding" (click)="closePopupPayment(3)">Cancel</button>
			</div>
		</div>
	</div>
</ng-template>

<ng-template #transactionHashModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Confirmation</h4>
		<button type="button" aria-label="Close" class="close" (click)="closeModal()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body seminor-login-modal-body">
		<div class="create-new-fau">
			<div class="form-group">
				<label>Transaction Hash</label>
				<input type="text" (input)="checkFundAmount()" placeholder="Enter transaction hash"
					[(ngModel)]="transactionHash" name="Amount" min="25" class="form-control" required>
				<p *ngIf="errorMessage" style="color:red">{{errorMessage}} </p>
				<p *ngIf="successMessage" style="color:green">{{successMessage}} </p>
			</div>
		</div>
	</div>
	<div class="button-widthrawl modal-footer">
		<a href="javascript:void(0)" (click)="closePopupPayment(3,openCancelPopUp)" class="btn btn-danger btn-padding"
			style="margin-right: 10px;">Cancel</a>
		<a href="javascript:void(0)" (click)="submitTransactionHash()" class="btn button-margin btn-padding">Submit</a>
	</div>
	<!-- qr modal -->

</ng-template>

<!-- Cancel transaction pop-up -->
<ng-template #openCancelPopUp let-modal>
	<div class="modal-header">
		<h4 class="modal-title">Confirmation</h4>
		<button type="button" class="close" aria-label="Close" (click)="closeCancelTransModal()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body resnBody">
		<div>
			<p>Are you sure want to cancel this transaction ?</p>
		</div>
		<label>Reason : </label>
		<form [formGroup]="reasonForCancelTransactionForm">
			<textarea formControlName="reason" class="form-control"></textarea>
			<app-show-errors fname="Reason" [control]="reason"></app-show-errors>
		</form>
	</div>
	<div class="button-widthrawl modal-footer">
		<button [ngbTooltip]="'cancel transaction'" #t="ngbTooltip" placement="top"
			class="btn button-margin btn-padding" (click)="t.close();cancelTransaction()">
			Yes
		</button>
		<button type="button" class="btn btn-danger btn-padding" (click)="closeCancelTransModal()">
			Close
		</button>
	</div>
</ng-template>



<ng-template #buyNowModal let-modal>

	<div class="modal-header header_pdd">
		<h1 class="modal-title fs-5" id="addfundLabel">Select a Wallet to add Funds</h1>
		<button type="button" class="btn-close ConnectWalletCloseBtn" (click)="closeModal()">
			 
		</button>
	</div>
	<div class="modal-body body_pdd">
		<div class="Addbtn" (click)="openBuyNow();"
			data-bs-dismiss="modal">
			<img src="../../../assets/img/icons/coinbuck.png" alt="withdrawIcon">
			<span class="textbtn">Add through Coinbuck </span>
		</div>
		<div *ngIf="isKometWalletToShow" class="d-flex gap-2 devider_hr">
			<hr class="divider" />
			Or
			<hr class="divider" />
		</div>
		<div *ngIf="isKometWalletToShow" class="Addbtn" (click)="openKometWalletBuyNow(pleaseWaitConnectWallet);" data-bs-toggle="modal"
			data-bs-target="#wallet">
			<img src="../../../assets/img/icons/komet.png" alt="withdrawIcon">
			<span class="textbtn">Add through Komet Wallet</span>
		</div>
	</div>
</ng-template>


<ng-template #pleaseWaitConnectWallet let-modal>
	<button type="button" class="btn-close ConnectWalletCloseBtn"  (click)="closeModal()">
	  <span aria-hidden="true">×</span>
	</button>
  <div class="modal-body">
	<p class="welletTExt">Please wait!! we are creating your komet wallet</p>
  </div>
</ng-template>