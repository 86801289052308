import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppService } from '../services/app.service';
import { MixPanelConstants } from '../config/mix-panel-constant';
import { MixpanelService } from '../shared/services/mix-panel.service';

@Component({
	selector: 'app-promotion-page',
	templateUrl: './promotion-page.component.html',
	styleUrls: ['./promotion-page.component.css']
})
export class PromotionPageComponent implements OnInit {
	@ViewChild('contentContainer') contentContainer: ElementRef;
	// Variables
	pageId = null;
	referralId = null;
	pageHtml = null;
	mixpanelConst:any=MixPanelConstants

	constructor(
		public route: ActivatedRoute,
		private router: Router,
		private appService: AppService,
		private mixpanel:MixpanelService
	) { }

	ngOnInit(): void {
		const urlParams = new URLSearchParams(window.location.search);
		const utmSource = urlParams.get('utm_source');
		const utmMedium = urlParams.get('utm_medium');
		const utmCampaign = urlParams.get('utm_campaign');
		if(utmSource || utmMedium || utmCampaign ){
			
			this.mixpanel.trackEvent(this.mixpanelConst.UTM_CONST.PROMOTION_PAGE);
		}
		this.route.params.subscribe((params) => {
			if (params['pageId']) {
				this.pageId = params['pageId'];
				this.referralId = params['referralId']
				this.getHtml()
			} else {
				this.router.navigate(['auth']);
			}
		})

	}

	/**
	 * @description: used to get html page for landing
	 */
	getHtml() {
		let data = {
			pageId: this.pageId,
			referralId: this.referralId,
			clientUrl: environment.baseURL + "auth/signup/"
		}
		this.appService.getHtmlForLandingPage(data).subscribe(
			(response) => {
				let cssForPage = response.cssForPage;
				if (response.cssLinks) {
					response.cssLinks.forEach(element => {
						var elem = document.createElement('link');
						elem.rel = ' stylesheet'
						elem.href = element;
						document.head.appendChild(elem);
					});
				}
				var styleSheet = document.createElement("style")
				styleSheet.innerText = cssForPage
				document.head.appendChild(styleSheet)
				this.pageHtml = response.page
				var contentDiv = document.getElementById("contentDiv");
				contentDiv.innerHTML = response.page;
				if (this.contentContainer) {
					this.addEventListenersToDynamicallyLoadedContent();
				}
			},
			(error) => {
				this.router.navigate(['auth']);
			}
		)
	}
	/**
	 * @description: adds click event listeners to dynamically loaded content, handling anchor tags to either scroll to a specific section or navigate to the linked URL
	 */
	private addEventListenersToDynamicallyLoadedContent() {
		const container = this.contentContainer.nativeElement;
		const links = container.querySelectorAll('a');

		links.forEach(link => {
			link.addEventListener('click', (event) => {
				event.preventDefault();
				const targetId = link.getAttribute('href').substring(1); // Remove the '#';
				if (targetId == "demo") {
					this.scrollToSection(targetId);
				} else {
					window.location.href = link.getAttribute('href')
				}
			});
		});
	}

	scrollToSection(sectionId: string) {
		const section = this.contentContainer.nativeElement.querySelector(`#${sectionId}`);

		if (section) {
			section.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}

}
