import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberSuffix'
})
export class NumberSuffixPipe implements PipeTransform {

  transform(value: number, args?: any):any {
    var exp, rounded,
    suffixes = ['k', 'Mn', 'G', 'T', 'P', 'E'];
    var valueArr:any = value?.toString()?.split("");
    exp = Math.floor(Math.log(value) / Math.log(1000));
  if (Number.isNaN(value)) {
    return null;
  }

  if (value < 100) {
    return value;
  }
  else if(value < 1000000){
    let result = Math.round(value/1000)
    return result+suffixes[exp - 1];
  }else if(value < 1000){
    return value;
  }
  // Round and divide the input number to obtain the desired decimal representation
  var result = (valueArr[0] || 0)+'.'+(valueArr[1] || 0)+(valueArr[2] || 0)
  
  return result + suffixes[exp - 1];

  // return (result).toFixed(args) + suffixes[exp - 1];
  }

}
