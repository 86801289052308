import { Injectable } from '@angular/core';
import { HttpencapService } from 'src/app/shared/services/httpencap.service';

@Injectable({
  providedIn: 'root'
})
export class PackagesService {

constructor(private http: HttpencapService) { }

getCampaignUserListId(data?) {
	return this.http.get('campaigns/v1/details', data);
}
getBuyPackagesWithoutPagination(data?) {
	return this.http.get('userPackages/v1/list', data);
}
getAllPackagesWithoutPagination(data) {
	return this.http.get('packages/v1/list',data)

}
getCampaigns(data?) {
	return this.http.get('campaigns/v1/owned', data);
}
checkCampaignIsPublish(data?) {
	return this.http.patch('campaigns/v1/update/status', data);
}
activeInactiveCampaign(data?) {
	return this.http.patch('campaigns/v1/publish/first/time', data)
}
deleteCampaign(data?) {
	return this.http.patch('campaigns/v1/delete', data)
}
getAllCampaignTypes(data) {
	return this.http.get('campaignTypes/v1/all', data);
}
getCampaignDetailsById(data?) {
	return this.http.get('campaigns/v1/campaign/' + data.campaignId);
}
updateCampaign(data) {
	return this.http.patch('campaigns/v1/update', data);
}
addCampaign(data) {
	return this.http.post('	campaigns/v1/insert', data);
}

getTransactions(data?) {
	return this.http.get('transactions/v1/admin/transactions', data);
}
getAllPrograms() {
	return this.http.get('projects/v1/all');
}
getAllCampaigns(data?) {
	return this.http.get('campaigns/v1/all/listing', data);
}

getSpecificPackagesById(data) {
	return this.http.get('packages/v1/get/specific', data);
}
buyPackage(data) {
	return this.http.post('users/v1/buy/package', data);
}
getTokenAmount(data){
	return this.http.get('funds/v1/token/price',data);
}
getPackagePaymentDetails(data){
	return this.http.get('userPackages/v1/get/packages/cost', data)
}

}
