import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot} from '@angular/router';
import { JwtHelperServices } from './jwt-helper.service';
import { CommonApiService } from './common-api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGaurdService {

  constructor(
    private jwtHelper: JwtHelperServices, 
    private router: Router, 
    private sharedService: CommonApiService
  ){
    
  }
  
  canActivate(): boolean {
    if (this.jwtHelper.isTokenExpired) {
      window.localStorage.removeItem('_u');
      window.localStorage.removeItem('token');
      let url:any = window.location.href;
      let urlEarn = url?.split('start-earning/details/');
      let urlPay = url?.split('packages/payment/');
      const urlParams = new URLSearchParams(url?.split('?')[1]);
      const isWebBoosterRedirect = urlParams?.get('isWebBoosterRedirect');
      const referId = urlParams?.get('referId');
      if(referId && !this.sharedService.isUserLoggedIn()){
        localStorage.setItem('referId', referId)
      }
      console.log(referId, "refer Id _______________________________________________________")
      if(isWebBoosterRedirect){
        localStorage.setItem('isWebBoosterRedirect', isWebBoosterRedirect);
      }
      else if(urlEarn[1]){
        localStorage.setItem('performCampaignId', urlEarn[1]);
      }else if(urlPay[1]){
        localStorage.setItem('selectedPackageRedirect', urlPay[1]);
      }
      this.router.navigate(['auth']);
      return false;
    }
    
    this.sharedService.isUserLoggedIn();
    return true;
  }

  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    if (this.jwtHelper.isTokenExpired) {
      window.localStorage.removeItem('_u');
      window.localStorage.removeItem('token');
      this.router.navigate(['auth']);
      return false;
    }
      return true;
  }
}
