import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PromotionPageComponent } from './promotion-page/promotion-page.component';
import { AuthGaurdService as AuthGuard } from './shared/services/auth-gaurd.service';
import { OnboardingQuestionComponent } from './onboarding-question/onboarding-question.component';
import { KycVerifyComponent } from './shared/components/kyc-verify/kyc-verify.component';
import { PrivateSaleComponent } from './private-sale/private-sale.component';

const routes: Routes = [

	{
		path: 'auth',
		loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
	},
	{
		path: 'main',
		loadChildren: () => import('./main/main.module').then(m => m.MainModule)
	},
	{
		path: 'perform-task',canActivate: [AuthGuard] ,
		loadChildren: () => import('./perform-task/perform-task.module').then(m => m.PerformTaskModule)
	},
	{ path: 'sign-up-completed',canActivate: [AuthGuard] ,  component: OnboardingQuestionComponent },
	{ path: 'kyc-verification',  component: KycVerifyComponent },
	{ path: 'private-sale',  component: PrivateSaleComponent },
	{ path: 'buy',canActivate: [AuthGuard] ,  component: PrivateSaleComponent },
	{ path: 'private-sale/:data',  component: PrivateSaleComponent },

	{ path: 'promote/:pageId/:referralId',component: PromotionPageComponent },

	{ path: '**', redirectTo: 'auth', pathMatch: 'full' }
];

@NgModule({
	imports: [RouterModule.forRoot(routes , {scrollPositionRestoration:'enabled'} )],
	exports: [RouterModule]
})
export class AppRoutingModule { }
