import { Injectable } from '@angular/core';
import { HttpencapService } from '../shared/services/httpencap.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {

	constructor(private _http: HttpencapService) { }
	getHtmlForLandingPage(data) {
		return this._http.get('landing-page/getpage', data);
	}
	getOnboardingQuestions(){
		return this._http.get("questionnaires/v1/get");
	}
	submitResponse(data){
		return this._http.post("questionnaires/v1/add/answer", data);
	}
}
