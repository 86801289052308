import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { CommonApiService } from '../../services/common-api.service';
import { HeaderService } from './service/header.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LoaderService } from '../loader/loader.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WalletService } from '../../services/wallet.service';
import { AmountService } from '../../services/amount.service';
import { Messages } from 'src/app/config/messages';
import { Constants } from 'src/app/config/constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import noWhitespaceValidator from '../../services/no-white-space-validator';
import { FormValidatorService } from '../../services/form-validator.service';
import { EarningsService } from "../../../main/earnings/service/earnings.service";
import { PackagesService } from 'src/app/main/campaign-packages/services/packages.service';
import { style } from '@angular/animations';
import { MixpanelService } from '../../services/mix-panel.service';
import { MixPanelConstants } from 'src/app/config/mix-panel-constant';


declare var $: any;
declare var jQuery: any;
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

	// Staring 
	public transactionId = '';
	public kycVarified = false;
	public errorMessage = "";
	public successMessage = "";
	public transactionHash = '';
	coinName = '';
	walletAddress:string = '';
	minAmount:any;
	testingCategory = '';
	// Array
	notifications = [];
	notificationIds = [];
	userData: any = [];
	paymentMethodList:any = [];
	isKometWalletConnected:boolean=false;
	// Variable
	activatedSub: Subscription;
	@Input() isLoggedIn;
	amount = 0;
	tokenAmount = 0;
	public amountToAdd = 0;
	innerWidth = 0;

	// Object
	public gatewayData: any = {};

	// Boolean
	public gateResponse = false;
	walletConnected = false
	isKometWalletToShow:boolean = false
	buttonClicked = false;
	showProfileVerify = false;
	saleIsLive = false;

	// Constant
	constants = Messages.CONST_MSG
	mixPanelConst:any=MixPanelConstants;



	// Form Group
	reasonForCancelTransactionForm: FormGroup;

	// Element Ref
	public modalRefTwo: NgbModalRef;
	public modalRef: NgbModalRef;
	@ViewChild('buyNowModal', { static: true }) buyNowModal: ElementRef;

	constructor(
		private headerService: HeaderService,
		private commonService: CommonApiService,
		private router: Router,
		private toastrService: ToastrService,
		private loaderService: LoaderService,
		private sharedService: CommonApiService,
		private modalService: NgbModal,
		private walletService: WalletService,
		private amountService: AmountService,
		private validator: FormValidatorService,
		private fb: FormBuilder,
		private earningService: EarningsService,
		private packageService: PackagesService,
		private mixpanelservice: MixpanelService,

	) { }

	ngOnInit(): void {
		this.innerWidth = window.innerWidth;
		// $('#sidebar-scroll').click(function() {
		// 	$("html").toggleClass("import-scroll");
		//   });
		let user = this.sharedService.getCurrentUser();
		this.testingCategory = user?.testingCategory;
		this.checkWalletConnected();
		this.checkKometWalletToShow();


		this.getUserProfile();
		this.getNotifications();
		// this.getPaymentMethods();
		this.createCancelTransactionForm();
		this.sharedService.isUserLoggedIn();
		this.sharedService.getLogin().subscribe(
			(res) => {
				this.isLoggedIn = res;
			}
		);
		if (this.isLoggedIn) {
			this.amountService.getMessage().subscribe((res: any) => {
				this.amount = res || 0;
			})
			this.getWalletBalance();
			let userDetails = this.sharedService.getCurrentUser();
			this.kycVarified = userDetails?.KYCVerified;			
			this.showProfileVerify = userDetails?.emailVerified
			this.amountService.setProfileVerify(userDetails?.emailVerified);
		this.amountService.getProfileVerify().subscribe((res) => {
			this.showProfileVerify = res
		})
		// this.amountService.setKYCVerify(this.kycVarified);
		this.amountService.getKYCVerify().subscribe((res) => {
			if(res){
				this.kycVarified = res;
			}
		})
		}
		this.amountService.getOpenBuyNow().subscribe((res)=>{
			if(res){
				this.openModal(this.buyNowModal);
				this.amountService.setOpenBuyNow(false);
			}
		})

		this.activatedSub = this.headerService.activatedEmitter.subscribe(didActivated => {
			if (didActivated) {
				this.getUserProfile();
			}
		})
		jQuery("body").removeClass('active');
		if (window.matchMedia('(min-width: 992px)').matches) {
			$('.main-navbar .active').removeClass('show');
			$('.main-header-menu .active').removeClass('show');
		}

		//Window Scroll the add the class
		$(window).scroll(function () {
			var scroll = $(window).scrollTop();

			if (scroll >= 100) {
				$(".main-header").addClass("darkHeader");
			} else {
				$(".main-header").removeClass("darkHeader");
			}
		})

		// Shows header dropdown while hiding others
		$('.main-header .dropdown > a').on('click', function (e) {
			e.preventDefault();
			$(this).parent().toggleClass('show');
			$(this).parent().siblings().removeClass('show');
			$(this).find('.drop-flag').removeClass('show');
		});

		// header mobile click menu remove sidebar 
		$('.horizontalMenu-list a').on('click', function () {
			$("body").removeClass("active");


		});
		// header mobile click menu remove sidebar 
		$('.dropdown-item').on('click', function () {
			$("#myDropdown").removeClass("show");
		});
		$('.socialicons-items').on('click', function () {
			$("#socialDropdown").removeClass("show");
		});

		// ______________Search
		$('body, .main-header form[role="search"] button[type="reset"]').on('click keyup', function (event) {
			if (event.which == 27 && $('.main-header form[role="search"]').hasClass('active') ||
				$(event.currentTarget).attr('type') == 'reset') {
				closeSearch();
			}
		});
		function closeSearch() {
			var $form = $('.main-header form[role="search"].active')
			$form.find('input').val('');
			$form.removeClass('active');
		}
		// Show Search if form is not active // event.preventDefault() is important, this prevents the form from submitting
		$(document).on('click', '.main-header form[role="search"]:not(.active) button[type="submit"]', function (event) {
			event.preventDefault();
			var $form = $(this).closest('form'),
				$input = $form.find('input');
			$form.addClass('active');
			$input.focus();
		});
		// if your form is ajax remember to call `closeSearch()` to close the search container
		$(document).on('click', '.main-header form[role="search"].active button[type="submit"]', function (event) {
			event.preventDefault();
			var $form = $(this).closest('form'),
				$input = $form.find('input');
			$('#showSearchTerm').text($input.val());
			closeSearch()
		});

		// ______________Cover Image
		$(".cover-image").each(function () {
			var attr = $(this).attr('data-image-src');
			if (typeof attr !== typeof undefined && attr !== false) {
				$(this).css('background', 'url(' + attr + ') center center');
			}
		});

		// Close dropdown menu of header menu
		$(document).on('click touchstart', function (e) {
			e.stopPropagation();
			// closing of dropdown menu in header when clicking outside of it
			var dropTarg = $(e.target).closest('.main-header .dropdown').length;
			if (!dropTarg) {
				$('.main-header .dropdown').removeClass('show');
			}
			// closing nav sub menu of header when clicking outside of it
			if (window.matchMedia('(min-width: 992px)').matches) {
				// Navbar
				var navTarg = $(e.target).closest('.main-navbar .nav-item').length;
				if (!navTarg) {
					$('.main-navbar .show').removeClass('show');
				}
				// Header Menu
				var menuTarg = $(e.target).closest('.main-header-menu .nav-item').length;
				if (!menuTarg) {
					$('.main-header-menu .show').removeClass('show');
				}
				if ($(e.target).hasClass('main-menu-sub-mega')) {
					$('.main-header-menu .show').removeClass('show');
				}
			} else {
				//
				if (!$(e.target).closest('#mainMenuShow').length) {
					var hm = $(e.target).closest('.main-header-menu').length;
					if (!hm) {
						$('body').removeClass('main-header-menu-show');
					}
				}
			}
		});
		$('#mainMenuShow').on('click', function (e) {
			e.preventDefault();
			$('body').toggleClass('main-header-menu-show');
		})
		$('.main-header-menu .with-sub').on('click', function (e) {
			e.preventDefault();
			$(this).parent().toggleClass('show');
			$(this).parent().siblings().removeClass('show');
		})
		$('.main-header-menu-header .close').on('click', function (e) {
			e.preventDefault();
			$('body').removeClass('main-header-menu-show');
		})

		// ______________Horizontal-menu Active Class
		$(document).ready(function () {
			$(".horizontalMenu-list li a").each(function () {

				var pageUrl = window.location.href.split(/[?#]/)[0];
				if (this.href == pageUrl) {
					$(this).addClass("active");
					$(this).parent().addClass("active"); // add active to li of the current link
					$(this).parent().parent().prev().addClass("active"); // add active class to an anchor
					$(this).parent().parent().prev().click(); // click the item to make it drop
				}
			});
		});


		// ______________Active Class
		$(document).ready(function () {
			$(".horizontalMenu-list li a").each(function () {
				var pageUrl = window.location.href.split(/[?#]/)[0];
				if (this.href == pageUrl) {
					$(this).addClass("active");
					$(this).parent().addClass("active"); // add active to li of the current link
					$(this).parent().parent().prev().addClass("active"); // add active class to an anchor
					$(this).parent().parent().prev().click(); // click the item to make it drop
				}
			});
			$(".horizontal-megamenu li a").each(function () {
				var pageUrl = window.location.href.split(/[?#]/)[0];
				if (this.href == pageUrl) {
					$(this).addClass("active");
					$(this).parent().addClass("active"); // add active to li of the current link
					$(this).parent().parent().parent().parent().parent().parent().parent().prev().addClass("active"); // add active class to an anchor
					$(this).parent().parent().prev().click(); // click the item to make it drop
				}
			});
			$(".horizontalMenu-list .sub-menu .sub-menu li a").each(function () {
				var pageUrl = window.location.href.split(/[?#]/)[0];
				if (this.href == pageUrl) {
					$(this).addClass("active");
					$(this).parent().addClass("active"); // add active to li of the current link
					$(this).parent().parent().parent().parent().prev().addClass("active"); // add active class to an anchor
					$(this).parent().parent().prev().click(); // click the item to make it drop
				}
			});
		});

		jQuery(function () {
			'use strict';
			document.addEventListener("touchstart", function () { }, false);
			jQuery(function () {
				jQuery('body').wrapInner('<div class="horizontalMenucontainer" />');
				// jQuery('<div class="horizontal-overlapbg"></div>').prependTo('.horizontalMenu');
				jQuery('.horizontal-navtoggle').click(function () {
					jQuery('.horizontalMenu-click').removeClass("horizontal-activearrow");
					jQuery(".horizontalMenu > .horizontalMenu-list > li > .sub-menu, .horizontal-megamenu").not(jQuery(this).siblings('.horizontalMenu > .horizontalMenu-list > li > .sub-menu, .horizontal-megamenu')).slideUp('slow');
					jQuery(this).siblings('.sub-menu').slideToggle('slow');
					jQuery(this).siblings('.horizontal-megamenu').slideToggle('slow');
					jQuery(this).children('.horizontalMenu-arrow').toggleClass('horizontalMenu-rotate');
					jQuery(this).siblings('li > .sub-menu').slideToggle('slow');
					jQuery('body').toggleClass('active');

				});
				jQuery('.horizontal-overlapbg').click(function () {
					jQuery("body").removeClass('active');
				});
				jQuery('.horizontalMenu > .horizontalMenu-list > li').has('.sub-menu').prepend('<span class="horizontalMenu-click"><i class="horizontalMenu-arrow fa fa-chevron-down"></i></span>');
				jQuery('.horizontalMenu > .horizontalMenu-list > li').has('.horizontal-megamenu').prepend('<span class="horizontalMenu-click"><i class="horizontalMenu-arrow fa fa-chevron-down"></i></span>');
				jQuery('.horizontalMenu-click').click(function () {
				
					jQuery(this).toggleClass('horizontal-activearrow').parent().siblings().children().removeClass('horizontal-activearrow');
					jQuery(".horizontalMenu > .horizontalMenu-list > li > .sub-menu, .horizontal-megamenu").not(jQuery(this).siblings('.horizontalMenu > .horizontalMenu-list > li > .sub-menu, .horizontal-megamenu')).slideUp('slow');
					jQuery(this).siblings('.sub-menu').slideToggle('slow');
					jQuery(this).siblings('.horizontal-megamenu').slideToggle('slow');
				});
				jQuery('.horizontalMenu > .horizontalMenu-list > li > ul > li').has('.sub-menu').prepend('<span class="horizontalMenu-click02"><i class="horizontalMenu-arrow fe fe-chevron-down"></i></span>');
				jQuery('.horizontalMenu > .horizontalMenu-list > li > ul > li > ul > li').has('.sub-menu').prepend('<span class="horizontalMenu-click02"><i class="horizontalMenu-arrow fe fe-chevron-down"></i></span>');
				jQuery('.horizontalMenu-click02').click(function () {
					jQuery(this).children('.horizontalMenu-arrow').toggleClass('horizontalMenu-rotate');
					jQuery(this).siblings('li > .sub-menu').slideToggle('slow');
				});
				jQuery(window).on('resize', function () {
					if (jQuery(window).outerWidth() < 992) {
						jQuery('.horizontalMenu').css('height', jQuery(this).height() + "px");
						jQuery('.horizontalMenucontainer').css('min-width', jQuery(this).width() + "px");
					} else {
						jQuery('.horizontalMenu').removeAttr("style");
						jQuery('.horizontalMenucontainer').removeAttr("style");
						jQuery('body').removeClass("active");
						jQuery('.horizontalMenu > .horizontalMenu-list > li > .horizontal-megamenu, .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu, .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu, .horizontalMenu > .horizontalMenu-list > li > ul.sub-menu > li > ul.sub-menu > li > ul.sub-menu').removeAttr("style");
						jQuery('.horizontalMenu-click').removeClass("horizontal-activearrow");
						jQuery('.horizontalMenu-click02 > i').removeClass("horizontalMenu-rotate");
					}
				});
				jQuery(window).trigger('resize');
			});
		}());

	}

		//  myFunction() {
		// 	// alert('working now..!')
		// 	// document.getElementsByTagName('html')[0].style.overflow = "hidden";
		// 	document.getElementsByTagName('html')[0].style.overflow = "hidden";
			
		//   }
	/**
	 * @description: used to create cancel transaction form
	 */
	createCancelTransactionForm() {
		this.reasonForCancelTransactionForm = this.fb.group({
			reason: ["", [Validators.required, noWhitespaceValidator]],
		});
	}
	
	get reason() {
		return this.reasonForCancelTransactionForm.get("reason");
	}

	/**
	 * @description: used to log out
	 */
	onSignOut() {
		this.commonService.logout();
		this.mixpanelservice.trackEvent('Log out')
		this.mixpanelservice.resetMixpanel()
	}

	/**
	 * @description: used to get notifications listing 
	 */
	getNotifications() {
		this.headerService.getNotifications().subscribe((resp) => {
			if (!resp.error) {
				this.notifications = resp.data;
				for (let i = 0; i < this.notifications?.length; i++) {
					this.notificationIds.push(this.notifications[i].id);
				}
			}
		}, (err) => {
			console.log(err);
		});
	}
	/**
	 * @description: used to read one notification at a time
	 * @param messageId 
	 */
	readOneNotification(messageId) {
		let data = {
			messageIdArray: [{ id: messageId }]
		}
		this.headerService.readOneNotification(data).subscribe((resp) => {
			if (!resp.error) {
				this.notifications = this.notifications.filter((item)=>item?.id != messageId);
			}
		}, (err) => {
			console.log(err);
		});
	}
	
	scrollToTop(){
		window.scroll({ 
			top: 0, 
			left: 0, 
			behavior: 'smooth' 
	 });
	}

	/**
	 * @description: used to read all notifications
	 */
	readAllNotification() {
		let data = {
			messageIdArray: this.notificationIds.map(el => {
				return { id: el }
			})
		};
		this.headerService.readAllNotification(data).subscribe((resp) => {
			if (!resp.error) {
				this.getNotifications();
			}
		}, (err) => {
			console.log(err);
		});
	}

	/**
	 * @description: used to get user profile data
	 */
	getUserProfile() {
		this.loaderService.show();
		this.headerService.getUserProfile().subscribe((resp) => {
			this.loaderService.hide();
			if (!resp.error) {
				this.userData = resp.data;
				this.userData.nameInitial = this.getInitials(this.userData?.name)
				let user = this.sharedService.getCurrentUser();
				user.emailVerified = this.userData?.emailVerified;
				user.testingCategory = this.userData?.testingCategory
				localStorage.setItem('_u', JSON.stringify(user));
			}
		}, (err) => {
			this.loaderService.hide();
		});
	}

	/**
	 * @description: used to navigate to the edit profile
	 */
	editProfile() {
		jQuery("body").removeClass('active');
		this.router.navigateByUrl('main/edit-profile');
	}

	goToKYC(){
		jQuery("body").removeClass('active');
		$("#myDropdown").removeClass("show");
		this.router.navigateByUrl('main/kyc');
	}

	ngOnDestroy(): void {
		this.activatedSub.unsubscribe();
	}

	/**
	 * @description: used to open qr code popup and add fund popup
	 * @param content 
	 */
	openPopupOne(content) {
		this.gateResponse = false;
		this.gatewayData = '';
		this.transactionId = '';
		this.amountToAdd = null;
		this.minAmount = null;
		this.modalRef = this.modalService.open(content, { centered: true, size: 'md', windowClass:"withdrawl-popup", backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-basic-title' });
	}
	openModal(content){
		// this.goToSale(true)
		// this.getRoundDetails();
		this.modalService.open(content, { centered: true, size: 'md', windowClass:"addFund-popup", backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-basic-title'})
	}
	
	openBuyNow(){
		this.goToSale(true)
		this.getRoundDetails();
		localStorage.setItem('redirectAfterBuy', window.location.href);
		localStorage.removeItem("kometWalletData");
		this.router.navigate(['/buy']);
	}

	/**
	 * @description: used to enable wallet
	 */
	creatUserWallet() {
		this.loaderService.show();
		this.walletService.createUserWallet().subscribe((res) => {
			this.loaderService.hide()
			if (res?.status == 200) {
				this.toastrService.success(this.constants.WALLET_CREATED_SUCCESSFULLY);
				this.getWalletBalance();
			} else {
				this.toastrService.info(res?.message || this.constants.SOMETHING_WENT_WRONG);
			}
		}, (error) => {
			this.loaderService.hide()
			this.toastrService.error(error?.message || this.constants.SOMETHING_WENT_WRONG)
		})
	}

	/**
	 * @description: used to get and update wallet balance
	 */
	getWalletBalance() {
		this.earningService.getWalletBalance().subscribe(
			(res) => {
				if (res?.error) {
				} else {
					if(res?.data){
						this.walletConnected = true;
					}
					let amount = this.commonService.getAvailableFundBalance(res?.data)
					this.amountService.setMessage(amount);
				}
			},
			(error) => {
				this.loaderService.hide();
			}
		);
	}

	/**
	 * @description: used to close modal
	 */
	closeModal(){
		this.saleIsLive = false;
		this.tokenAmount = 0;
		this.error('');
		this.amountToAdd = null;
		this.minAmount = null;
		this.coinName = '';
		this.modalService.dismissAll();
	}

	restrictPlusMinus(event: KeyboardEvent) {
		// Check if the key pressed is + or - and prevent it
		if (event.key === '+' || event.key === '-') {
		  event.preventDefault();
		}
	}

	/**
	 * @description: on change function of fund amount
	 */
	checkFundAmount() {
		if (!this.amountToAdd) {
			this.error(this.constants.ENTER_AMOUNT);
			this.tokenAmount = 0;
		} else {
			if ( this.minAmount && this.amountToAdd < this.minAmount) {
				this.error('Amount must be greater than or equal to ' + this.minAmount);
				return;
			}
			if (this.amountToAdd > 1000000000) {
				this.error(this.constants.MAX_LIMIT);
			} else {
				this.error('')
			}
			this.getPackageTokenPrice(this.amountToAdd)
		}
	}
	/**
	 * @description: used to set the error message
	 * @param message 
	 */
	error(message) {
		this.errorMessage = message;
		this.successMessage = "";
	}

	/**
	 * @description: used to create fund transaction
	 */
	createTransaction() {
		if (!this.coinName) {
			this.error(this.constants.SELECT_COIN);
			return;
	} else {
		this.error('');
	}
		if (!this.amountToAdd) {
			this.error(this.constants.ENTER_AMOUNT);
			return;
			
		}
		if (this.amountToAdd && this.amountToAdd < this.minAmount) {
			this.error('Amount must be greater than or equal to ' + this.minAmount);
			return;
		} else {
			this.error('');
		}
		
		let dataToSend: any = {
			amount: this.amountToAdd,
			type: "deposit",
			paymode: this.coinName,
		};
		this.loaderService.show();
		this.walletService.createTransaction(dataToSend).subscribe(
			(response) => {
				this.loaderService.hide();
				if (response?.error) {
					console.error(response.message);
					this.error(response?.message);
				} else {
					this.gateResponse = true;
					this.gatewayData = response?.data;
					this.walletAddress = this.gatewayData?.address;
					this.walletAddress = JSON.stringify(this.walletAddress)
					this.transactionId = response?.data?._id;
					this.getWalletBalance();
				}
			},
			(error) => {
				this.loaderService.hide();
				console.error(error);
				this.error(error?.error?.message || this.constants.SOMETHING_WENT_WRONG);
				this.toastrService.clear();
				this.toastrService.error(error?.error?.message, this.constants.ERROR, {
					timeOut: 3000,
				});
				if(error?.error?.data?.existingTransaction){
					this.closeModal();
					this.router.navigate(['/main/wallet']);
				}
			}
		);
	}

	/**
	 * @description: used to copy data
	 * @param text 
	 */
	copyToClipboard(text) {
		navigator.clipboard.writeText(text).then(
			() => {
				this.toastrService.success(
					Messages.CONST_MSG.COPIED_TO_CLIPBOARD,
					Messages.CONST_MSG.SUCCESS,
					{
						timeOut: 3000,
					}
				);
			},
			(err) => {
				this.toastrService.success(
					Messages.CONST_MSG.ERROR_COPIED_TO_CLIPBOARD,
					Messages.CONST_MSG.ERROR,
					{
						timeOut: 3000,
					}
				);
			}
		  );
		// document.addEventListener("copy", (e: ClipboardEvent) => {
		// 	e.clipboardData.setData("text/plain", text);
		// 	e.preventDefault();
		// 	document.removeEventListener("copy", null);
		// });
		// document.execCommand("copy");
		// this.toastrService.success(
		// 	this.constants.COPIED_TO_CLIPBOARD,
		// 	this.constants.SUCCESS,
		// 	{
		// 		timeOut: 3000,
		// 	}
		// );
	}
	/**
	 * @description: used to close payment popup
	 * @param type 
	 */
	closePopupPayment(type) {
		this.tokenAmount = 0;
		this.buttonClicked = true
		let message = "";
		if (type == 1) {
			message = this.constants.TRANSACTION_SUCCESSFULL;
			this.toastrService.success(message, this.constants.SUCCESS, { timeOut: 2000 });
		} else if (type == 2) {
			message = this.constants.INVOICE_SAVED;
			this.toastrService.success(message, this.constants.SUCCESS, { timeOut: 2000 });
		} else {
			message = this.constants.INVOICE_CANCELLED_AUTOMATICALLY;
			this.toastrService.clear();
			this.toastrService.error(message);
		}
		let that = this;
		setTimeout(function () {
			that.modalService.dismissAll();
			this.buttonClicked = false
		}, 2000);
	}

	/**
	 * @description: used to submit transaction hash
	 * @returns 
	 */
	submitTransactionHash() {
		if (!this.transactionHash) {
			this.error(this.constants.ENTER_TXN);
			return;
		}
		if (!this.coinName) {
				this.error(this.constants.SELECT_COIN);
				return;
		} else {
			this.error('');
		}
		let dataToSend: any = {
			txnHash: this.transactionHash,
			transactionId: this.transactionId
		}
		
		this.loaderService.show();
		this.walletService.submitTransactionHash(dataToSend).subscribe(
			(response) => {
				this.loaderService.hide();
				if (response?.error) {
					console.error(response.message);
					
					this.toastrService.error(response?.message, this.constants.ERROR, {
						timeOut: 3000,
					});
				} else {
				
					console.error(response.message);
					
					this.toastrService.success(response?.message, this.constants.SUCCESS, {
						timeOut: 3000,
					});
					this.closeModal();
					this.getWalletBalance();				
				}
			},
			(error) => {
				this.loaderService.hide();
				console.error(error);
				this.error(error?.error?.message || this.constants.SOMETHING_WENT_WRONG);
				this.toastrService.clear();
				this.toastrService.error(error?.error?.message, this.constants.ERROR, {
					timeOut: 3000,
				});
			}
		);
	}
	/**
	 * @description: used to close transaction modal
	 */
	closeCancelTransModal(){
		this.reasonForCancelTransactionForm.reset();
		this.modalRefTwo.close();
	}

	/**
	 * @description: used to cancel transaction
	 */
	cancelTransaction(){
		if(this.reasonForCancelTransactionForm?.invalid) {
			this.validator.markControlsTouched(this.reasonForCancelTransactionForm);
			return;
		}
		this.loaderService.show();
		let dataToSend = {
			transactionId: this.transactionId,
			reason: this.reason.value,
		}
		this.walletService.cancelWalletTransaction(dataToSend).subscribe(
			(response) => {
				this.loaderService.hide();
				if (response?.error) {
					this.toastrService.error(response?.error)
				} else {
					this.toastrService.success(this.constants.TRANSACTION_CANCELLED_SUCCESSFULLY);
					this.closeModal();
				}
			},
			(error) => {
				this.loaderService.hide();
				this.toastrService.error(error?.error?.message);
			}
		);
	}

	/**
	 * @description: used to get token amount
	 * @param price 
	 */
	getPackageTokenPrice(price) {
		let data={
			usdAmount: price
		}
		this.packageService.getTokenAmount(data).subscribe(
			(res) => {
				if (res?.error) {
				} else {
					this.tokenAmount = res?.data?.tokenAmount
				}
			},
			(error) => {
				this.loaderService.hide();
			}
		);
	}
	/**
	 * @description: used to set current selected module
	 * @param type 
	 */
	setCurrentModule(type, showMenu?){
	if(!showMenu){
		console.log("closing",jQuery('.horizontalMenu-click'))
	jQuery('.horizontalMenu-click').removeClass("horizontal-activearrow");
	}
		this.sharedService.currentModule = type;
	}
	/**
	 * @description: used to get payment methods
	 */
	getPaymentMethods() {

		this.walletService.getPaymentMethods().subscribe(
			(res) => {
				if (res?.error) {
				} else {
					this.paymentMethodList = res?.data?.selectedCurrencies || []
				}
			},
			(error) => {
				this.loaderService.hide();
			}
		);
	}

	/**
	 * @description:ogs the selected option, fetches and updates the minimum amount for a payment method, and sets validation messages based on the obtained data.
	 * @param $event 
	 */
	onChangeMethod($event){
		let DTS ={
			pairValue: $event.target.value
		}
		this.loaderService.show();
		this.walletService.getMinAmountOfMethod(DTS).subscribe((res)=>{
			this.loaderService.hide();
			if(res?.error){
			this.toastrService.error(res?.message || this.constants.SOMETHING_WENT_WRONG)

			}else{
				this.minAmount = Number(res?.data?.min_amount);
				if(this.amountToAdd < this.minAmount){
				this.error('Amount must be greater than or equal to ' + this.minAmount);
				return;
				}else{
					this.coinName = $event.target.value;
					this.errorMessage = "";
				}
				
			}
		},(error)=>{
			this.loaderService.hide();
			this.toastrService.error(error?.error?.message || this.constants.SOMETHING_WENT_WRONG)
		})
		
	}
	goToSale(buy?){

		if(buy){
		localStorage.setItem('redirectAfterBuy', window.location.href)
			this.router.navigate(["/buy"]);
			this.modalService.dismissAll();
			return;
		}

			this.router.navigate(["/private-sale"]);
			this.modalService.dismissAll();
	
	}

	/**
	 * @description:function retrieves and updates round details, determining whether a sale is currently live based on the response data.
	 */
	getRoundDetails(){
		this.loaderService.show();
		this.walletService.getRoundDetails().subscribe((res)=>{
			this.loaderService.hide();
			if(!res?.error){
				if(res?.data?.title){
				this.saleIsLive = true;
			}else{
				this.saleIsLive = false;
			}
			
			}else{
				this.saleIsLive = false;

			}
		}, (error)=>{
			this.loaderService.hide();
		})
	  }

	   getInitials(name) {
		var parts = name.split(' ')
		var initials = ''
		for (var i = 0; i < parts.length; i++) {
		  if (parts[i].length > 0 && parts[i] !== '') {
			initials += parts[i][0]
		  }
		}
		return initials
	  }


	  checkWalletConnected() {
		this.loaderService.show();
		this.walletService.checkKometWalletConnected().subscribe(
		  (resp) => {
			this.loaderService.hide();
			console.log(resp, '____---____----___---____---____---__________----___---____---___--')
			if (!resp.error) {
			  console.log("checkin 2", resp.data.isExistence,'isExistence');
			  this.isKometWalletConnected=resp?.data?.isExistence||false;
			
			} 
		  },
		  (err) => {
			console.log(err.error.data, '____---____----___---____---____---__________----___---____---___--')
	
			this.loaderService.hide();
		  }
		);
	  }

	  openKometWalletBuyNow(content) {
		  if(!this.isKometWalletConnected){
			this.modalService.dismissAll();
		  this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true })
		}
	
		this.createKometWallet()
	  }
	   

	  createKometWallet() {
		let dataToSend = { email: this.commonService.getCurrentUser()?.email };
		this.loaderService.show();
		this.walletService.createKometWallet(dataToSend).subscribe(
		  (resp) => {
			this.loaderService.hide();
			console.log(resp, '____---____----___---____---____---__________----___---____---___--')
			if (!resp.error) {
			  console.log("checkin 2", resp);
			  localStorage.setItem('redirectAfterBuy', window.location.href);
			  localStorage.setItem('kometWalletData', JSON.stringify(resp?.data));
			  this.modalService.dismissAll();
			  this.router.navigate(['/buy']);
			} else {
			  this.modalService.dismissAll();
	
			  this.toastrService.info(resp?.message || this.constants.SOMETHING_WENT_WRONG, this.constants.ERROR)
			}
		  },
		  (err) => {
			console.log(err.error.data, '____---____----___---____---____---__________----___---____---___--')
			if (err.error.data) {
			  localStorage.setItem('redirectAfterBuy', window.location.href);
			  localStorage.setItem('kometWalletData', JSON.stringify(err?.error?.data));
			  this.modalService.dismissAll();
			  this.router.navigate(['/buy']);
			}
			this.toastrService.info(err.error?.message || this.constants.SOMETHING_WENT_WRONG, this.constants.ERROR)
			this.modalService.dismissAll();
			this.loaderService.hide();
		  }
		);
	  }
	  checkKometWalletToShow(){
		this.loaderService.show();
		this.walletService.checkKometWalletToShow().subscribe(
		  (resp) => {
			this.loaderService.hide();
			console.log(resp, '____---____----___---____---____---__________----___---____---___--')
			if (!resp.error) {
			  this.isKometWalletToShow=resp?.data||false;
			  
			}else{
			  this.isKometWalletToShow=false
			}
		  },
		  (err) => {
			console.log(err.error.data, '____---____----___---____---____---__________----___---____---___--')
	
			this.loaderService.hide();
		  }
		);
	  }

	  trackEvent(eventName){
		this.mixpanelservice.trackEvent(eventName,{field:'Navbar click'});
	  }
}


