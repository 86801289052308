import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
	selector: 'app-telegram-login',
	templateUrl: './telegram-login.component.html',
	styleUrls: ['./telegram-login.component.css']
})
export class TelegramLoginComponent implements OnInit, AfterViewInit   {

	constructor() { }

	@ViewChild('script', { static: true }) script: ElementRef;
	@Output() public found = new EventEmitter();
	@Input() mobileView = false;

	ngOnInit(): void {
		window['onTelegramAuth'] = loginData => this.onTelegramAuth(loginData);
	}

	onTelegramAuth(user) {
		this.found.emit(user);
	}

	convertToScript() {
		const element = this.script.nativeElement;
		const script = document.createElement('script');
		script.src = 'https://telegram.org/js/telegram-widget.js?22';
		script.async = true;
		script.setAttribute('data-telegram-login', 'coinbuck_test_bot');
		script.setAttribute('data-size', 'large');
		script.setAttribute('id', 'telegramButton');
		script.setAttribute('data-radius', '5');
		script.setAttribute('data-onauth', 'onTelegramAuth(user)');
		script.setAttribute('data-request-access', 'write');
		element.parentElement.replaceChild(script, element);
	}
	
	ngAfterViewInit() {
		this.convertToScript();
	}

}
