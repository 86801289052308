import { Component, OnInit } from '@angular/core';
import { AmountService } from '../../services/amount.service';
import { MixpanelService } from '../../services/mix-panel.service';
import { MixPanelConstants } from 'src/app/config/mix-panel-constant';

@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
	mixPanelConst:any=MixPanelConstants;
	constructor(private amountService: AmountService, private mixpanelservice: MixpanelService) { }

	ngOnInit(): void {
	}

	scrollToTop(eventName){
		this.mixpanelservice.trackEvent(eventName,{field:'Footer click'});
		window.scroll({ 
			top: 0, 
			left: 0, 
			behavior: 'smooth' 
	 });
	}


}
