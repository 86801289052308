<div class="onboardQues">
    <div class="my-auto page page-h">
        <div class="main-signin-wrapper">
            <div class="main-card-signin d-md-flex wd-100p">
             
                <div class="p-3 wd-md-50p wd-100p singup-form order-last">
                    <div class=" m-auto p-2">
                        <div class="onboardContent">
                            <div class="logo-new">
                                <img src="../../../assets/img/cb-icon.png" />
                            </div>
                            <!-- <div class="logo-full">
                                <img src="../../../assets/img/brand/logo-white.png" />
                            </div> -->
                            <h2 style="font-weight: 700;"> Congratulations you’ve successfully signed up ! </h2>
                            <div class="stepper">
                                <div class="stepper-steps">
                                    <div class="stepper-step stepper-step-isValid">
                                        <div class="stepper-stepContent">
                                            <span class="stepper-stepMarker"><i class="fa fa-check" aria-hidden="true"></i></span>Sign Up
                                        </div>
                                    </div>
                                    <div class="stepper-step stepper-step-isActive">
                                        <div class="stepper-stepContent">
                                            <span class="stepper-stepMarker">2</span>Follow CoinBuck Social
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p>You are one step close to claim {{tokenBonus}} BUCK</p>
                            <h2>Follow CoinBuck Social</h2>
                            <div class="stepper-main d-flex flex-column mt-5 ml-2">
                                <div class="d-flex mb-0">
                                    <div class="d-flex flex-column pr-4 align-items-center">
                                        <div class="rounded-circle py-2 px-3 bg-primary text-white mb-0">1</div>
                                        <div class="line h-100"></div>
                                    </div>
                                    <div class="text-dark">
                                        Follow <a (click)="goToTwitter()"><u>@<span
                                                    class="clickable-text">coinbuckX</span></u> </a> on X (Twitter)
                                    </div>
                                </div>
                                <div class="d-flex mb-0">
                                    <div class="d-flex flex-column pr-4 align-items-center">
                                        <div class="rounded-circle py-2 px-3 bg-light text-muted mb-0">2</div>
                                        <!-- <div class="line h-100"></div> -->
                                    </div>
                                    <div>
                                        <span class="text-dark">
                                            Join Telegram <a (click)="goToTelegram()"><u>@<span
                                                        class="clickable-text">coinbuckX</span></u></a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="text-left">
                                <button class="btn-next" (click)="goToDashboard()">Next</button>
                            </div>
                            <!-- <div class="wd-lg-80p mx-auto">
                                <h3>Tell us more about you.</h3>
                                <p>Answering these questions helps our AI engine match you with exclusive tasks to maximize your rewards</p>    
                            </div> -->
                            <!-- <div  class="quesBoard" [ngClass]="{'quesBoard-active': response || responseArr?.length == selectedQuestion?.options?.length}">
                                <div>
                                    <p>{{selectedQuestion?.title}} <span *ngIf="selectedQuestion?.values?.length"> You can choose from the following options:</span></p>
                                    <ng-container *ngIf="!selectedQuestion?.values?.length">
                                    <div *ngFor="let item of selectedQuestion?.options;let i = index" class="checkList" (change)="handleResponse($event)">
                                        <input type="radio" id="response_{{i}}" [value]="item" name="radio-group">
                                        <label for="response_{{i}}">{{item}}</label>
                                    </div>
                                </ng-container>
                                    <ul class="select-option" *ngIf="selectedQuestion?.values?.length"> 
                                        <li *ngFor="let item of selectedQuestion?.options;let i = index">
                                          {{item}}
                                        <select  class="form-control" (change)="handleMultipleResponse($event, i)">
                                            <option selected hidden value="">Choose</option>
                                            <option [value]="item" *ngFor="let item of selectedQuestion?.values;let i = index" >{{item}}</option>
                                            
                                        </select>
                                        </li>  
                                       </ul>
                                </div>
                                <div *ngIf="!showQuestions">
                                    <div class="skeleton-loader-question mb-4"></div>
                                    <div class="d-flex align-items-center">
                                        <div class="skeleton-loader-radio mb-2"></div>
                                        <div class="skeleton-loader-option mb-2"></div>
                                    </div>   
                                    <div class="d-flex align-items-center">
                                        <div class="skeleton-loader-radio mb-2"></div>
                                        <div class="skeleton-loader-option mb-2"></div>
                                    </div>                                 
                                    <div class="d-flex align-items-center">
                                        <div class="skeleton-loader-radio mb-2"></div>
                                        <div class="skeleton-loader-option mb-2"></div>
                                    </div>   
                                    <div class="d-flex align-items-center">
                                        <div class="skeleton-loader-radio mb-2"></div>
                                        <div class="skeleton-loader-option mb-2"></div>
                                    </div>   
                                </div>
                              
                                <p class="error_msg"  *ngIf="showErrorMsg && !selectedQuestion?.values?.length">Please answer the given question!</p>
                                <p class="error_msg"  *ngIf="showErrorMsg && selectedQuestion?.values?.length">Please answer all the given question!</p>
                                <div class="text-right">
                                    <button (click)="submitResponse()">Next</button>
                                </div>
                               
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="wd-md-50p wd-100p page-signin-style p-3 text-whit singup-text ">
                    <div class="my-auto authentication-pages">
                        <div class="wd-lg-100p wd-xl-100p wd-md-100p m-auto leftOnboard">
                            <img src="../../assets/img/singup-bg.png" />
                            <!-- <img *ngIf="selectedIndex == 1" src="../../assets/img/onboard2.png" />
							<img *ngIf="selectedIndex == 2" src="../../assets/img/onboard3.png" />
							<img *ngIf="selectedIndex == 3 || selectedIndex == 4" src="../../assets/img/onboard4.png" /> -->
                            <h2 class="text-center">You are one step close to claim <b>{{tokenBonus}} BUCK</b> </h2>
                            <!-- <h2 *ngIf="selectedIndex == 1">Engage & Earn</h2>
							<h2 *ngIf="selectedIndex == 2">Refer & Earn</h2>
							<h2 *ngIf="selectedIndex == 3 || selectedIndex == 4">Boost profile & Earn more</h2> -->
                            <!-- <p *ngIf="selectedIndex == 0">
									Promote in your community to earn unlimited rewards.
								</p>
								<p *ngIf="selectedIndex == 1">Connect your socials and engage with community to grow and earn BUCK tokens in rewards.</p>
                                <p *ngIf="selectedIndex == 2">Invite your friends to start unlimited  earning with CoinBuck.</p>
                                <p *ngIf="selectedIndex == 3 || selectedIndex == 4">Boost your Twitter followers for greater  opportunities and rewards.</p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
