// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	// apiURL: "http://localhost:3000/",
	// apiURL: "https://internal-api.coinbuck.com/",
	 apiURL: "https://dev-api.coinbuck.com/",

	//  apiURL: "http://192.168.29.171:3000/",

	// apiURL: "http://192.168.0.130:3000/",

	// apiURL: "http://192.168.29.133:3000/",
	// apiURL: "http://10.42.0.21:3000/", 
	// baseURL: 'http://localhost:4200/',
	baseURL: 'https://dev.coinbuck.com/',
	webURL: 'https://web.coinbuck.com/',
	SITE_KEY: '6Lexpj0pAAAAAHIkeMC3WiG9NAwdsBM6gARh6ZCg',
	PVTENCRYPTKEY: '$(&sdasdaFSDFs#^(*DNnkFDSdn#^^#&W^FSDBGdSDFSDasdas@$(&sdasdaFSDFs#^Q(*DNnkFDSdn##hks2332hd766sar7^^#&W^FSDBGxg7dgqw3FSQ',
	headerName: 'authorization',
	authScheme: 'Bearer ',
	whitelistedDomains: [],
	blacklistedRoutes: [],
	// mixpanelToken:'402402f8197542f309a077d692869f06' //akansha
	// mixpanelToken:'38c953dba8e578b7599910397beb792d'    //akshay
	// mixpanelToken:'dd5de0a5c6a2b986da87b56b9733d291'    //coinbuck testing
	mixpanelToken: 'bbf9348d55b82d727b30324effa9ab0a' //gourav

	
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
