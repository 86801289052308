<!-- breadcrumb -->
<div class="container p-0">
	<div class="breadcrumb-header social-new" *ngIf="!mobileView">
		
		<div class="row wd-100-p" *ngIf="showData">
			 
			<div class="right-page col-md-12 col-sm-12 col-lg-12">
				
					<div class="main-header-message responsive-device">
						<!-- <a *ngIf="socialToShow[SOCIAL_CONNECTIONS.DISCORD]" class="new nav-link bg-light" [ngClass]="{'active-icon': activeSocialConnection[SOCIAL_CONNECTIONS.DISCORD]?.active}" [ngbTooltip]="(activeSocialConnection[SOCIAL_CONNECTIONS.DISCORD]?.active == true) ? 'Connected':'Connect your account'"
						href="javascript:void(0)" (click)="open(SOCIAL_CONNECTIONS.DISCORD)">
						<i><img class="icon" src="../../../../assets/img/discord.png"></i>
		
					</a>
					<a *ngIf="!socialToShow[SOCIAL_CONNECTIONS.DISCORD]"  class="new nav-link bg-light" 
						href="javascript:void(0)" >
						<i><img class="icon" src="../../../../assets/img/discord-d.png"></i>
		
					</a> -->

					<a *ngIf="!socialToShow[SOCIAL_CONNECTIONS.DISCORD]"  class="new nav-link bg-light" 
					href="javascript:void(0)" >
					<i><img class="icon" src="../../../../assets/img/discord-d.png"></i>
					
				</a>
				<a *ngIf="socialToShow[SOCIAL_CONNECTIONS.DISCORD]"   class="new nav-link bg-light" [ngClass]="{'active-icon': activeSocialConnection[SOCIAL_CONNECTIONS.DISCORD]?.active }" [ngbTooltip]="(activeSocialConnection[SOCIAL_CONNECTIONS.DISCORD]?.active == true) ? 'Connected':'Connect your account'"
						href="javascript:void(0)" (click)="open(SOCIAL_CONNECTIONS.DISCORD)">
						<i><img class="icon" src="../../../../assets/img/discord.png"></i>
						
					</a>
					<!-- <a   class="new nav-link bg-light" 
						href="javascript:void(0)" >
						<i><img class="icon" src="../../../../assets/img/discord-d.png"></i>
		
					</a> -->

						<!-- <a *ngIf="socialToShow[SOCIAL_CONNECTIONS.YOUTUBE]"  [ngbTooltip]="(activeSocialConnection[SOCIAL_CONNECTIONS.YOUTUBE]?.active == true) ? 'Connected':'Connect your account'" class="new nav-link bg-light" [ngClass]="{'active-icon': activeSocialConnection[SOCIAL_CONNECTIONS.YOUTUBE]?.active }"
							href="javascript:void(0)" (click)="open(SOCIAL_CONNECTIONS.YOUTUBE)">
							<i><img class="icon" src="../../../../assets/img/logos_Youtube.png"></i>
							
						</a>
						<a class="new nav-link bg-light"  *ngIf="!socialToShow[SOCIAL_CONNECTIONS.YOUTUBE]"  
							href="javascript:void(0)" >
							<i><img class="icon" src="../../../../assets/img/youtube-d.png"></i>
							
						</a> -->
						<!-- <a class="new nav-link bg-light"    
							href="javascript:void(0)" >
							<i><img class="icon" src="../../../../assets/img/youtube-d.png"></i>
							
						</a> -->

						<a *ngIf="!socialToShow[SOCIAL_CONNECTIONS.YOUTUBE]"  class="new nav-link bg-light" 
					href="javascript:void(0)" >
					<i><img class="icon" src="../../../../assets/img/youtube-d.png"></i>
					
				</a>
				<a *ngIf="socialToShow[SOCIAL_CONNECTIONS.YOUTUBE]"   class="new nav-link bg-light" [ngClass]="{'active-icon': activeSocialConnection[SOCIAL_CONNECTIONS.YOUTUBE]?.active }" [ngbTooltip]="(activeSocialConnection[SOCIAL_CONNECTIONS.YOUTUBE]?.active == true) ? 'Connected':'Connect your account'"
						href="javascript:void(0)" (click)="open(SOCIAL_CONNECTIONS.YOUTUBE)">
						<i><img class="icon" src="../../../../assets/img/logos_Youtube.png"></i>
						
					</a>


						<a  class="new nav-link bg-light pt-0" *ngIf="socialToShow[SOCIAL_CONNECTIONS.TELEGRAM] && activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.active != true && !activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.profile"  [ngbTooltip]="'Connect your account'">
							<app-telegram-login  (found)="handleTelegramLogin($event)"></app-telegram-login>
						 
						</a>
						<a  class="new nav-link bg-light pt-0" *ngIf="socialToShow[SOCIAL_CONNECTIONS.TELEGRAM] && activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.active == true && activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.profile" class="active-icon"  [ngbTooltip]="'Connected'">
							<i><img  class="icon" src="../../../../assets/img/telegram_icon.png"></i>
						 
						</a>
						<a  class="new nav-link bg-light pt-0" *ngIf="socialToShow[SOCIAL_CONNECTIONS.TELEGRAM] && activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.active != true && activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.profile"   [ngbTooltip]="'Connect your account'">
							<i><img (click)="open(SOCIAL_CONNECTIONS.TELEGRAM)" class="icon" src="../../../../assets/img/telegram_icon.png"></i>
						 
						</a>
						
						<a  class="new nav-link bg-light pt-0" *ngIf="!socialToShow[SOCIAL_CONNECTIONS.TELEGRAM]"  >
							<i><img class="icon" src="../../../../assets/img/telegram-d.png"></i>
						</a>
						<!-- <a  class="new nav-link bg-light pt-0"  >
							<i><img class="icon" src="../../../../assets/img/telegram-d.png"></i>
						</a> -->
					<a *ngIf="!socialToShow[SOCIAL_CONNECTIONS.TWITTER]"  class="new nav-link bg-light" 
					href="javascript:void(0)" >
					<i><img class="icon" src="../../../../assets/img/tweet-d.png"></i>
					
				</a>
				<a *ngIf="socialToShow[SOCIAL_CONNECTIONS.TWITTER]"   class="new nav-link bg-light" [ngClass]="{'active-icon': activeSocialConnection[SOCIAL_CONNECTIONS.TWITTER]?.active }" [ngbTooltip]="(activeSocialConnection[SOCIAL_CONNECTIONS.TWITTER]?.active == true) ? 'Connected':'Connect your account'"
						href="javascript:void(0)" (click)="open(SOCIAL_CONNECTIONS.TWITTER, modalTwitter)">
						<i><img class="icon" src="../../../../assets/img/logos_Twitter.png"></i>
						
					</a>
						<!-- Reddit code commented<a *ngIf="socialToShow[SOCIAL_CONNECTIONS.REDDIT]" class="new nav-link bg-light"
						href="javascript:void(0)" (click)="open(SOCIAL_CONNECTIONS.REDDIT)">
						<i><img class="icon" src="../../../../assets/img/logos_Reddit.png"></i>
						<span
							[ngClass]="(socialConnectionIsActiveData[SOCIAL_CONNECTIONS.REDDIT] == true)?'pulse':'pulse-danger'"></span>
					</a> -->
						<!-- Instragram code commented
						<a *ngIf="socialToShow[SOCIAL_CONNECTIONS.INSTAGRAM]" class="new nav-link bg-light"
							href="javascript:void(0)" (click)="open(SOCIAL_CONNECTIONS.INSTAGRAM)">
							<i><img class="icon" src="../../../../assets/img/logos_Instagram.png"></i>
							<span
								[ngClass]="(socialConnectionIsActiveData[SOCIAL_CONNECTIONS.INSTAGRAM] == true)?'pulse':'pulse-danger'"></span>
						</a> -->
						 <!-- Facebook connect code  -->
						<!-- <a *ngIf="socialToShow[SOCIAL_CONNECTIONS.FACEBOOK]" class="new nav-link bg-light"
							href="javascript:void(0)" (click)="open(SOCIAL_CONNECTIONS.FACEBOOK)">
							<i><img class="icon" src="../../../../assets/img/logos_Facebook.png"></i>
							<span
								[ngClass]="(socialConnectionIsActiveData[SOCIAL_CONNECTIONS.FACEBOOK] == true)?'pulse':'pulse-danger'"></span>
						</a> -->
					</div>
			</div>
		</div>

     <!-- skeleton UI -->
    
	 


	</div>
	<div class="breadcrumb-header social-new" *ngIf="mobileView">
 
<ul class="mobile_social">
	<li><a *ngIf="socialToShow[SOCIAL_CONNECTIONS.TWITTER]" [ngClass]="{'active-icon-mobile': activeSocialConnection[SOCIAL_CONNECTIONS.TWITTER]?.active }"  [ngbTooltip]="(activeSocialConnection[SOCIAL_CONNECTIONS.TWITTER]?.active == true) ? 'Connected':'Connect your account'"
		href="javascript:void(0)" (click)="open(SOCIAL_CONNECTIONS.TWITTER,modalTwitter)"><img  src="../../../../assets/img/icons/new-t.png"> </a>
		<a class="" *ngIf="!socialToShow[SOCIAL_CONNECTIONS.TWITTER]" 
		href="javascript:void(0)" ><img src="../../../../assets/img/tweet-d.png"> </a>
	
	</li>
	<li class=" ">
		<!-- <a 
		href="javascript:void(0)" ><img src="../../../../assets/img/icons/telegram-l.png">Telegram</a> -->
		<a  class="new nav-link bg-light pt-0"  *ngIf="socialToShow[SOCIAL_CONNECTIONS.TELEGRAM] && activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.active != true && !activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.profile"  [ngbTooltip]="'Connect your account'">
			<app-telegram-login [mobileView]="true" (found)="handleTelegramLogin($event)" ></app-telegram-login> 
		 
		</a>
		<a  class="new nav-link bg-light pt-0" *ngIf="socialToShow[SOCIAL_CONNECTIONS.TELEGRAM] && activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.active == true && activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.profile" class="active-icon-mobile"  [ngbTooltip]="'Connected'">
			<img style="top: 0px; bottom: 0px;"  class="icon" src="../../../../assets/img/telegram_icon.png"> 
		 
		</a>
		<a  class="new nav-link bg-light pt-0" *ngIf="socialToShow[SOCIAL_CONNECTIONS.TELEGRAM] && activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.active != true && activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.profile"   [ngbTooltip]="'Connect your account'">
			<img style="top: 0px; bottom: 0px;" (click)="open(SOCIAL_CONNECTIONS.TELEGRAM)" class="icon" src="../../../../assets/img/telegram_icon.png">
		 
		</a>
		
		<a  class="new nav-link bg-light pt-0" *ngIf="!socialToShow[SOCIAL_CONNECTIONS.TELEGRAM]"  >
			<img src="../../../../assets/img/icons/telegram-l.png"></a>
		
		<!-- <a *ngIf="socialToShow[SOCIAL_CONNECTIONS.TELEGRAM]"
		 href="javascript:void(0)" [ngClass]="{'active-icon-mobile': activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.active }"
		  [ngbTooltip]="(activeSocialConnection[SOCIAL_CONNECTIONS.TELEGRAM]?.active == true) ? 'Connected':'Connect your account'"></a> -->
		 
		</li>
	<!-- <li> -->
		<!-- <a *ngIf="socialToShow[SOCIAL_CONNECTIONS.YOUTUBE]"  [ngbTooltip]="(activeSocialConnection[SOCIAL_CONNECTIONS.YOUTUBE]?.active == true) ? 'Connected':'Connect your account'" class="new nav-link bg-light" [ngClass]="{'active-icon-mobile': activeSocialConnection[SOCIAL_CONNECTIONS.YOUTUBE]?.active }"
		href="javascript:void(0)" (click)="open(SOCIAL_CONNECTIONS.YOUTUBE)"><img src="../../../../assets/img/icons/youtube.png">Youtube</a>
		<a *ngIf="!socialToShow[SOCIAL_CONNECTIONS.YOUTUBE]"  
		href="javascript:void(0)" class=""><img src="../../../../assets/img/youtube-d.png">Youtube</a> -->
		<!-- <a 
		href="javascript:void(0)" class=""><img src="../../../../assets/img/youtube-d.png">Youtube</a> -->
	<!-- </li> -->
	<li><a *ngIf="socialToShow[SOCIAL_CONNECTIONS.YOUTUBE]" [ngClass]="{'active-icon-mobile': activeSocialConnection[SOCIAL_CONNECTIONS.YOUTUBE]?.active }"  [ngbTooltip]="(activeSocialConnection[SOCIAL_CONNECTIONS.YOUTUBE]?.active == true) ? 'Connected':'Connect your account'"
		href="javascript:void(0)" (click)="open(SOCIAL_CONNECTIONS.YOUTUBE)"><img  src="../../../../assets/img/icons/youtube.png">  </a>
		<a class="" *ngIf="!socialToShow[SOCIAL_CONNECTIONS.YOUTUBE]" 
		href="javascript:void(0)" ><img src="../../../../assets/img/youtube-d.png"> </a>
	
	</li>
	
	<!-- <a [ngClass]="{'active-icon-mobile': activeSocialConnection[SOCIAL_CONNECTIONS.DISCORD]?.active}" href="javascript:void(0)" [ngbTooltip]="(activeSocialConnection[SOCIAL_CONNECTIONS.DISCORD]?.active == true) ? 'Connected':'Connect your account'"
		*ngIf="socialToShow[SOCIAL_CONNECTIONS.DISCORD]"  (click)="open(SOCIAL_CONNECTIONS.DISCORD)"><img src="../../../../assets/img/discord.png">Discord</a>
		<a href="javascript:void(0)"*ngIf="!socialToShow[SOCIAL_CONNECTIONS.DISCORD]"   
		class=""><img src="../../../../assets/img/discord-d.png">Discord</a> -->
		<li><a *ngIf="socialToShow[SOCIAL_CONNECTIONS.DISCORD]" [ngClass]="{'active-icon-mobile': activeSocialConnection[SOCIAL_CONNECTIONS.DISCORD]?.active }"  [ngbTooltip]="(activeSocialConnection[SOCIAL_CONNECTIONS.DISCORD]?.active == true) ? 'Connected':'Connect your account'"
			href="javascript:void(0)" (click)="open(SOCIAL_CONNECTIONS.DISCORD)"><img  src="../../../../assets/img/discord.png">  </a>
			<a class="" *ngIf="!socialToShow[SOCIAL_CONNECTIONS.DISCORD]" 
			href="javascript:void(0)" ><img src="../../../../assets/img/discord-d.png"> </a>
			
		</li>
		<!-- <li>
		<a href="javascript:void(0)"  
		 class=""><img src="../../../../assets/img/discord-d.png">Discord</a>
		</li> -->

	


</ul>
 
	</div>
	<div class="row wd-100-p" *ngIf="!showData">
		<!-- *ngIf="!showData" -->
		<div class="col-md-9 col-sm-12" >
					<div class="skeleton-tabl">
						<div class="skeleton__section skeleton__section--table">
							<div class="skeleton__tr">
							  <div class="skeleton__td ht-100 wd-100p"></div>
							
							</div>
				   
						  </div>
					  </div>

		</div>
		<div class="right-page col-md-3 col-sm-12 pr-0">
			<div class="d-flex justify-content-between align-items-center wd-100-p">
				<div class="skeleton-loader-content mb-0"></div>
				<div class="avatar-skeleton-soical mb-0" ></div>
				<div class="avatar-skeleton-soical mb-0"></div>
				<div class="avatar-skeleton-soical mb-0"></div>
				
			  </div>
		</div>

	 </div>
</div>

<!-- /breadcrumb -->

<ng-template #socialModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{socialConnectionTitle}}</h4>
		<button type="button" class="close" aria-label="Close" (click)="closeModal()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body">
		<h4 class="text-center">Connection with social link</h4>
		<div class="form-group">
			<input [(ngModel)]="socialConnectionUrl" class="form-control required" placeholder="Enter social URL..">
		</div>

		<p *ngIf="errorMessage" class="text-danger">
			Please add a URL
		</p>
	</div>

	<div class="modal-footer">
		<button class="btn btn-primary" (click)="onSelectSocialConnection(true)">Yes</button>
		<button class="btn btn-danger" (click)="onSelectSocialConnection(false)">No</button>
	</div>
</ng-template>

<ng-template #modalTwitter let-modal>
	<div class="modal-header">
		<h4> </h4>
		<button type="button" class="close" aria-label="Close" (click)="closeModal()">
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<div class="modal-body">
		<p class="text-warning title_modal"> Enter your Twitter handle here</p>
		<form [formGroup]="twitterForm">
			<div class="form-group" >
				<input class="form-control" placeholder="Enter your twitter username" type="text"
					formControlName="username">
				<app-show-errors [control]="username" [fname]="'Username'"></app-show-errors>
			</div>
			<!-- <div class="form-group" *ngIf="twitterConnected">
				<input class="form-control" disabled [placeholder]="twitterDetails?.profile?.username" type="text">
				<span style="font-size: 10px;color: red">(Your twitter account is already linked, please unlink to add
					new)</span>
			</div> -->
			<div class="text-right modal-footer px-0">
				<!-- <button *ngIf="twitterConnected" type="button" (click)="unLinkTwitter(twitterDetails?._id)"
				class="btn btn-main-primary">Cancel</button> -->
				<a
				href="javascript:void(0)"
				(click)="closeModal()"
				class="btn btn-danger btn-padding"
				style="margin-right: 10px"
				>Cancel</a
			  >
			<button (click)="addTwitterData()"  type="submit" class="btn btn-main-primary btn-padding">Add</button>
			</div>

		</form>
	</div>
</ng-template>

