import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
	providedIn: 'root'
})
export class CookiesService {

	constructor(private cookieService: CookieService) { }

	setCookie(cname: any, cvalue: any, exdays = 1) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	deleteCookie(name: any) {
		this.setCookie(name, '', -1);
	}
	
	get(name: string) {
		return this.cookieService.get(name)
	}
}
