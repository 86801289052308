import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hideEmail'
})
export class HideEmailPipe implements PipeTransform {

	transform(email: string) {
		let emailArr = email?.split("@")
		let modifiedEmail = email?.charAt(0)+"****"+emailArr[1];	
		return modifiedEmail;
	}

}
