import { AbstractControl } from "@angular/forms";
import { Domains } from "src/app/config/domain";
import { environment } from "src/environments/environment";


function forbiddenEmailValidation(control: AbstractControl): { [key: string]: any } | null {
	if (control.value) {
		const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		let forbidden = emailRegex.test(control.value);
		if(environment.production){
			const invalidTestingMailDomains:any = Domains.DOMAIN_LIST;
			let email = control.value?.split("@")
			if(invalidTestingMailDomains.includes(email[1]?.trim())){
				forbidden = false
			}
		}
		return !forbidden ? { 'forbiddenEmail': { value: control.value } } : null;
	}
	return null;
}

export default forbiddenEmailValidation; 