<app-header *ngIf="isUserLoggedIn"></app-header>
<div class="main-header nav nav-item hor-header" [ngClass]="{'hideHeaderLogin': isUserLoggedIn }">
    <div class="container">
        <div class="main-header-left ">

            <!-- sidebar-toggle-->
            <a class="header-brand" [ngClass]="{'logout-logo': !isUserLoggedIn}" routerLink="/main/dashboard">
                <img src="assets/img/brand/logo-white.png" class="logo-white">
            </a>

        </div>
        <div class="main-header-right">
            <div class="nav nav-item  navbar-nav-right ml-auto">
                <div class="wallet-header">
                    <a routerLink="/auth" class="loginBtn" id="add-fund" style="text-decoration: none">
                        Login
                    </a>

                </div>
            </div>
        </div>


    </div>

</div>
<div class="salePage">
    <div class="container position-relative">
        <!-- <a (click)="goBack()" *ngIf="isUserLoggedIn" class="back-button"> 
            <img src="../../assets/img/back-icon.png">
            Back To Dashboard
          </a> -->
        <div class="row justify-content-between align-items-center">
            <div class="col-lg-6 pl-0">
                <div>
                    <h3>Monetize your online world
                        with CoinBuck rewarding
                        platform</h3>
                    <p>Get started now and watch your social activity turn into <b>BUCK</b> and avail <span
                            style="font-weight: 400;">Tokens at the most
                            Discounted Price of the year!!
                        </span></p>
                    <p><span style="font-size: 12px;">Note: When adding funds, please ensure you add at least $3 after
                            accounting for gas fees to prevent your transactions from being locked.</span></p>


                    <a href="../../assets/pdf/user_guide.pdf" download>
                        <button class="btn btn-warning btn-sm" (click)="trackEvent('User Guide')">
                            User Guide
                        </button>
                    </a>

                    <a href="https://pancakeswap.finance/swap" *ngIf="buyFromSale" target="_blank">
                        <button class="btn marketBuy">
                            Buy From Market
                        </button>
                    </a>
                </div>
            </div>

            <div class="col-lg-5 form-Wrapper">
                <div class="priceDiv" [ngClass]="{'hideHeaderLogin': !roundDetails }">
                    <p class="timerText">Next price increase in</p>
                    <div class="count-down-timer">

                        <div class="wrapper">

                            <div class="times">
                                <p class="box" #days></p>
                                <p class="box" #hours></p>
                                <p class="box" #minutes></p>
                                <p class="box" #seconds></p>
                            </div>
                            <div class="description">
                                <p>Days</p>
                                <p>Hours</p>
                                <p>Minutes</p>
                                <p>Seconds</p>
                            </div>
                        </div>
                    </div>
                    <div class="progressStyle">
                        <div class="progressbar" style="width:100%;">
                            <div style="background: linear-gradient(90deg, #F1AC1C 4.49%, rgba(241, 172, 28, 0.00) 124.37%); padding-left: 4px; text-align: center; height: 1rem; border-radius: 4px;"
                                [ngStyle]="{'width': progress > 0 ? progress+'%': '0%' }">
                                <span style="color: white !important;">
                                    <a container="body">{{(roundDetails?.tokensSoldTillNow?.$numberDecimal || '0') |
                                        decimal:18}}

                                    </a>
                                </span>
                                <!-- <span style="color: white !important;" >10%</span> -->
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-between progressLimit">
                        <div></div>
                        <div style="font-size:12px;">{{(roundDetails?.tokensAlloted?.$numberDecimal || '0') |
                            decimal:18}} BUCK</div>
                    </div>
                </div>
                <div class="noteText">
                    <span *ngIf="getKometWalletAddress()">Komet Wallet Address: {{getKometWalletAddress()}}</span>

                    <p class="noteTextcontent" *ngIf="getKometWalletAddress()" style="font-size: 12px; color: #F1AC1C;">
                        Note: Gas fees will be
                        deducted from the
                        amount you provide.</p>
                </div>
                <div class="networkForm">
                    <div class="cp">Current Price : ${{currentPrice}}</div>
                    <form [formGroup]="saleForm" (submit)="openSignupModal(signupModal)">

                        <div class="row mb-4" *ngIf="buyFromSale">

                            <div class="col-12 mt15" *ngFor="let network of networkList"
                                (click)="handleNetwork(network.key)">
                                <div class="networkName "
                                    [ngClass]="{'active': selectedNetwork == network.key, 'disabled': !roundDetails}">
                                    <div class="headText">Network you are paying through </div>
                                    <span class="activeCircle"> <span class="dot"></span> </span>
                                    <label class="net-name"> {{network.value}} </label>
                                </div>
                            </div>
                            <app-show-errors [control]="baseTokenName" [fname]="'Network'"></app-show-errors>
                        </div>
                        <div class="row mb-4" *ngIf="!buyFromSale">
                            <div class="col-12 mt15">
                                <div class="networkName ">
                                    <div class="headText">Network you are paying through </div>
                                    <span class="activeCircle"> <span class="dot"></span> </span>
                                    <label class="net-name"> {{networkList[0].value}}</label>

                                </div>
                            </div>
                            <app-show-errors [control]="baseTokenName" [fname]="'Network'"></app-show-errors>
                        </div>
                        <div class="row ">
                            <div class="col-12 mb-4">
                                <div class="networkAmount active">
                                    <p>You Pay</p>
                                    <div class="d-flex justify-content-between align-items-baseline ptop">
                                        <input *ngIf="buyFromSale"
                                            [ngClass]="{'disabled': !roundDetails, 'maxDigit': baseTokenAmount?.value?.toString().length > 6}"
                                            onwheel="event.preventDefault();" class=" form-control amountN"
                                            [ngbTooltip]="baseTokenAmount?.value" type="number" placement="top-start"
                                            (input)="handleBaseTokenInput($event)" (keydown)="restrictPlusMinus($event)"
                                            name="baseTokenAmount" placeholder="Enter amount"
                                            formControlName="baseTokenAmount" />
                                        <input *ngIf="!buyFromSale"
                                            [ngClass]="{ 'maxDigit': baseTokenAmount?.value?.toString().length > 6}"
                                            onwheel="event.preventDefault();" class=" form-control amountN"
                                            [ngbTooltip]="baseTokenAmount?.value" type="number" placement="top-start"
                                            (input)="handleBaseToken($event)" (keydown)="restrictPlusMinus($event)"
                                            name="baseTokenAmount" placeholder="Enter amount "
                                            formControlName="baseTokenAmount" />
                                        <div class="coinN">
                                            <!-- {{networkObj[selectedNetwork]}} -->
                                            USD
                                        </div>
                                    </div>
                                </div>
                                <app-show-errors [control]="baseTokenAmount" [fname]="'Amount'"></app-show-errors>
                            </div>
                            <!-- <div class="col-12 mb-4">
                                <div class="networkAmount">
                                    <p>You Transfer</p>
                                    <div class="d-flex justify-content-between align-items-baseline ptop">
                                        <input *ngIf="!buyFromSale"
                                            [ngClass]="{'maxDigit': amount?.value?.toString().length > 6}"
                                            onwheel="event.preventDefault();" type="number" class="form-control amountN"
                                            [ngbTooltip]="amount?.value" name="amount"
                                            (keydown)="restrictPlusMinus($event)" (input)="handleToken($event)"
                                            placeholder="Amount" formControlName="amount" />

                                        <input *ngIf="buyFromSale"
                                            [ngClass]="{'disabled': !roundDetails, 'maxDigit': amount?.value?.toString().length > 6}"
                                            onwheel="event.preventDefault();" type="number" class="form-control amountN"
                                            [ngbTooltip]="amount?.value" name="amount"
                                            (keydown)="restrictPlusMinus($event)" (input)="handleTokenInput($event)"
                                            placeholder="Enter" formControlName="amount" />
                                        <div class="coinN">BUCK</div>
                                    </div>
                                </div>
                                <app-show-errors [control]="amount" [fname]="'Token Amount'"></app-show-errors>
                            </div> 
                            -->

                        </div>
                        <div class="row mb-4">
                            <div class="col-12">
                                <div class="networkName networkNamebig d-flex">
                                    <div class="col-6">
                                        <div class="headText">You Receive</div>
                                        <span class="activeCircle"> <span class="dot"></span> </span>
                                        <div class="recieve_list">
                                            <div class="d-flex gap-2 textgray">
                                                <span class="badgepay">USD</span>
                                                ${{baseTokenAmount?.value | decimal:2}}
                                            </div>
                                            <div class="d-flex gap-2 textgray">
                                                <span class="badgepay">BUCK</span> {{amount?.value |
                                                decimal:2}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="headText">You Transfer</div>
                                        <span class="activeCircle"> <span class="dot"></span> </span>
                                        <div class="recieve_list">
                                            <div class="d-flex gap-2 textgray">
                                                <span class="badgepay">BNB</span>
                                                {{bnbValue | decimal:6}}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 text-center">
                                <button title="Buy" *ngIf="roundDetails && buyFromSale" class="btn btn-warning w-100"
                                    type="submit">
                                    Buy Now
                                </button>
                                <button title="Buy" *ngIf="!buyFromSale" class="btn btn-warning w-100" type="submit">
                                    Buy Now
                                </button>
                                <button title="Buy" class="btn btn-warning w-100" *ngIf="!roundDetails && buyFromSale"
                                    disabled>
                                    Opening Soon
                                </button>
                                <a href="https://coinbuck.com/index.html#howtobuy" *ngIf="buyFromSale" target="_blank">
                                    <button type="button" class="btn btn-outline-warning w-100">
                                        How to buy ?
                                    </button>
                                </a>
                                <a class="cursor-pointer" *ngIf="buyFromSale"
                                    (click)="openNeedHelpModal(needHelpModal);trackEvent('Need Help in buying')">
                                    <p class="helpNft">Need help in buying?</p>
                                </a>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>

    </div>
</div>

<ng-template #signupModal let-modal>
    <div class="modal-header">
        <h6></h6>
        <button type="button" *ngIf="!gateResponse" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body" *ngIf="!gateResponse">
        <div class="wd-lg-100p  wd-md-100p  border-box-singup">
            <div class="main-signin-header">

                <h2 class="mb-3 text-center"></h2>
                <form *ngIf="signupForm" [formGroup]="completeSignUpForm" (submit)="register()" class="clearfix">
                    <div class="form-group">
                        <input class="form-control" placeholder="Enter Your Email" type="email" formControlName="email">
                        <app-show-errors [control]="email" [fname]="'Email id'"></app-show-errors>
                    </div>
                    <div class="form-group">
                        <input class="form-control" placeholder="Enter Your Name" type="text"
                            formControlName="username">
                        <app-show-errors [control]="username" [fname]="'Username'"></app-show-errors>
                    </div>
                    <div class="form-group log-pwd">

                        <input class="form-control" placeholder="Set Your Password"
                            [type]="hidePassword ? 'password' : 'text'" formControlName="password">


                        <a class="type-toggle" (click)="hidePassword = !hidePassword">
                            <div [class]="hidePassword ? 'fa fa-eye-slash' : 'fa fa-eye' "></div>
                        </a>
                        <app-show-errors [control]="password" [fname]="'Password'"></app-show-errors>
                    </div>

                    <button class="btn btn-main-primary btn-block clearfix" [disabled]="disableButton">
                        {{constants.SOCIAL_LOGIN_TYPE.CONTINUE}}
                    </button>

                </form>
                <form *ngIf="!signupForm" [formGroup]="loginForm" (submit)="proceedToLogin()" class="clearfix">
                    <div class="form-group">
                        <input class="form-control" placeholder="Enter your email" type="text" formControlName="email">
                        <app-show-errors [control]="loginEmail" [fname]="'Email id'"></app-show-errors>
                    </div>
                    <div class="form-group log-pwd">

                        <input class="form-control" placeholder="Enter your password" type="text"
                            [type]="hidePassword ? 'password' : 'text'" formControlName="password">


                        <a class="type-toggle" (click)="hidePassword = !hidePassword">
                            <div [class]="hidePassword? 'fa fa-eye-slash' : 'fa fa-eye'   "></div>
                        </a>
                        <app-show-errors [control]="loginPassword" [fname]="'Password'"></app-show-errors>
                    </div>
                    <button type="submit" class="btn btn-main-primary btn-block">Login</button>
                </form>
                <p class="hr-lines"> Or </p>
                <div class="form-group social-btn clearfix mb-0" *ngIf="signupForm">
                    <a (click)="socialLogin('google')" class="google-btn">
                        <img src="../../assets/img/google.webp" width="20px">
                        {{message.SIGN_UP.GOOGLE}}
                    </a>
                </div>
                <div class="form-group social-btn clearfix mb-3" *ngIf="!signupForm">
                    <a (click)="socialLogin(constant.SOCIAL_LOGIN_TYPE.GOOGLE)" class="google-btn">
                        <img src="../../assets/img/google.webp" width="20px">
                        {{message.LOGIN.GOOGLE}}
                    </a>
                </div>

            </div>
            <div class="main-signin-footer mt-3 mg-t-5" *ngIf="signupForm">
                <p>{{message.CONST_MSG.HAVE_ACCOUNT}}<a class="ml-2"
                        (click)="handleLogin()">{{message.CONST_MSG.LOGIN}}</a></p>
            </div>
            <div class="main-signin-footer" *ngIf="!signupForm">

                <a class="ml-2" (click)="handleLogin()">New to CoinBuck? Sign up</a>
            </div>
        </div>
    </div>
    <div class="modal-body seminor-login-modal-body transaction-popup" *ngIf="gateResponse && gatewayData">
        <div class="create-new-fau create-new-fau-popup">
            <div class="col col-sm-12 col-xs-12 col-md-12 text-center p-0" style="position: relative;">
                <!-- <p class="qrHeading">Please deposit <span>{{ gatewayData?.baseTokenAmount }}
                        {{networkObj[gatewayData?.baseTokenName]}}
                    </span> to the below address.</p> -->
                <p class="note-text qrHeading deposit_header">Note : You are depositing {{
                    gatewayData?.baseTokenAmount }} {{networkObj[gatewayData?.baseTokenName]}} to the below address and
                    will be receiving {{ gatewayData?.amount | decimal : 2 }} {{TokenName}} Tokens in return.
                </p>
                <div class="col col-sm-12 col-xs-12 col-md-12 p-0">
                    <div class="walletAddId ">
                        <p class="addressId">
                            {{ gatewayData?.address }}
                        </p>

                        <img src="../../assets/img/icons/copyWhite.png" width="24px" title="Copy to clipboard"
                            (click)="copyToClipboard(gatewayData?.address)" style="cursor: pointer;" class="cop-icon">
                    </div>
                    <div class="copytext">
                        <p>Copy this address and pay through your metamask wallet or any other wallets</p>
                    </div>
                </div>

            </div>
            <div class="popupDevider">
                <hr class="popupDeviderHR" /> OR
                <hr class="popupDeviderHR" />
            </div>
            <p class="scanAdd">Scan this QR for wallet address</p>
            <div class="qrcodeImage brButtom">
                <qrcode [qrdata]="walletAddress" [allowEmptyString]="true" [alt]="'A custom alt attribute'"
                    [ariaLabel]="'QR Code image with the following content...'" [cssClass]="'center text-center'"
                    [colorDark]="'#000000ff'" [colorLight]="'#ffffffff'" [errorCorrectionLevel]="'M'" [margin]="4"
                    [title]="'Copy this Address for payment'" [width]="300"></qrcode>
            </div>
            <div class="qrHeading mt-3">Add your transaction hash for quick verification (not mandatory)</div>
            <div class="clearfix">
                <div class="form-group">
                    <input class="form-control transactionField" placeholder="Enter transaction hash" type="text"
                        [(ngModel)]="transactionHash">
                    <!-- <app-show-errors [control]="name" [fname]="'Name'"></app-show-errors> -->
                </div>
            </div>

            <div class="d-flex justify-content-center  align-items-center popupbtnWrapper">
                <button type="button" class="btn btn-success"
                    (click)="closePopupPayment(1);trackEvent(mixPanelConst?.EVENT_NAME?.I_HAVE_PAID);">I've
                    paid
                </button>
                <button type="button" class="btn qrCancleBtn"
                    (click)="cancelPayment();trackEvent(mixPanelConst?.EVENT_NAME?.NOT_PAID );">
                    Cancel
                </button>
            </div>
        </div>
    </div>


</ng-template>

<ng-template #needHelpModal let-modal>
    <div class="modal-header justify-content-end">

        <button type="button" class="close" aria-label="Close" (click)="closeModal()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row align-items-center">
            <div class="col-lg-7">
                <h6 class="findTextHead">Want to find out more ?</h6>
                <div class="wd-lg-100p  wd-md-100p  border-box-singup">
                    <div class="main-signin-header">

                        <h2 class="mb-3 text-center"></h2>
                        <form [formGroup]="needHelpForm" class="clearfix">
                            <div class="form-group">
                                <input class="form-control" placeholder="Enter your name" type="text"
                                    formControlName="name">
                                <app-show-errors [control]="name" [fname]="'Name'"></app-show-errors>
                            </div>
                            <div class="form-group">
                                <input class="form-control" onwheel="event.preventDefault();"
                                    placeholder="Enter your contact number" type="number" formControlName="contactNo">
                                <app-show-errors [control]="contactNo" [fname]="'Contact Number'"></app-show-errors>
                            </div>
                            <div class="form-group" *ngIf="!isUserLoggedIn">
                                <input class="form-control" placeholder="Enter your email" type="email"
                                    formControlName="needHelpEmail">
                                <app-show-errors [control]="needHelpEmail" [fname]="'Email'"></app-show-errors>
                            </div>
                            <div class="form-group">
                                <div class="d-flex px-sm-3 justify-content-between">
                                    <div class="checkBoxes">
                                        <input id="investment" type="checkbox" value="Investment"
                                            [checked]="selectedHelpType == 'Investment'"
                                            (change)="onCheckboxChange('Investment')">
                                        <label for="investment">Investment</label>
                                    </div>
                                    <div class="checkBoxes">
                                        <input id="support" type="checkbox" value="Support"
                                            [checked]="selectedHelpType == 'Support'"
                                            (change)="onCheckboxChange('Support')">
                                        <label for="support">Support</label>
                                    </div>
                                    <div class="checkBoxes">
                                        <input id="others" type="checkbox" value="Other"
                                            [checked]="selectedHelpType == 'Other'"
                                            (change)="onCheckboxChange('Other')">
                                        <label for="others">Other</label>
                                    </div>
                                </div>


                            </div>

                            <button (click)="OnSubmitNeedHelp()" class="btn btn-submit-form w-100 clearfix">
                                Submit
                            </button>

                        </form>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 text-center">
                <img src="../../assets/img/findOut.png" alt="">
            </div>
        </div>

    </div>
</ng-template>
<button (click)="openKometModal(paymentSuccessModal)" [hidden]="true" id="paymentSuccessBtn"></button>

<ng-template #paymentSuccessModal let-modal>
    <div class="modal-header">
        <!-- <h4 class="modal-title">Success</h4>	  -->
    </div>
    <div class="modal-body thankspopup">
        <div class="row align-items-center thankspopuptext">
            <p>Thanks for the purchase, we will verify your transaction and transfer funds accordingly! </p>
        </div>
        <button (click)="redirectOnSuccessProceed();trackEvent(mixPanelConst?.EVENT_NAME?.PROCEED_TO_DASHBOARD);"
            class="btn btn-warning btn-sm thankyoubtn">
            Proceed
        </button>
    </div>
</ng-template>

<button (click)="openKometModal(insufficient)" [hidden]="true" id="insufficientBtn"></button>


<ng-template #insufficient let-modal>
    <div class="modal-header insufficientHeader">
        <div class="insufficientTitle">
            <h3 class="modal-title fs-5" id="insufficientLabel">Insufficient balance alert! </h3>
            <p>Recharge your Komet Wallet to proceed with the transactions.</p>
        </div>
        <button type="button" aria-label="Close" class="close" (click)="closeModal()">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <hr class="hrDivider" />
    <div class="modal-body">
        <h3 class="modal-title fs-5 mb-3" id="insufficientLabel">Don’t have Komet Wallet ? Don’t worry </h3>
        <div class="stepWrapper">
            <div class="stepbox">
                <img src="../../../assets/img/icons/step.png" alt="step icon" />
                <p>
                    <b> Step 1: </b> Download the Komet Wallet Chrome extension.
                </p>
            </div>
            <div class="stepbox">
                <img src="../../../assets/img/icons/step.png" alt="step icon" />
                <p>
                    <b> Step 2: </b> Signup into your Komet wallet with the same gmail you signed into Coinbuck.
                </p>
            </div>
            <div class="stepbox">
                <img src="../../../assets/img/icons/step.png" alt="step icon" />
                <p>
                    <b> Step 3: </b> After signing up into wallet start adding funds into the wallet.
                </p>
            </div>
        </div>
    </div>
    <div class="modal-footer insufficientfooter">
        <button class="proceedBtn" (click)="kometWalletRedirection();">
            Proceed to Komet Wallet
        </button>
    </div>
</ng-template>