import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpencapService } from './httpencap.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Constants } from 'src/app/config/constant';
import { LoaderService } from '../components/loader/loader.service';
import { AuthService } from 'src/app/auth/services/auth.service';

@Injectable({
	providedIn: 'root'
})
export class CommonApiService {

	// Boolean to check if user is logged in
	public isUserLogin = false;

	// variable to store current user
	public currentUser: any;
	public currentUserId: any;

	public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public currentModule = 'dashboard';

	constructor(public _http: HttpencapService, public _route: Router, public jwtHelper: JwtHelperService, private _title: Title,		
		public toastrService: ToastrService, public loaderService: LoaderService, public authService: AuthService) {
	}

	/************************************ SETTERS & GETTERS SECTION STARTS ************************************/
	public setTitle(title: any) {
		this._title.setTitle(title);
	}

	public setUserLoggedIn(data: any): void {
		this.currentUser = data.user;
		window.localStorage.setItem('_u', JSON.stringify(data.user));
		window.localStorage.setItem('token', data.token);
		this.isLoggedIn.next(true);
	}

	public unsetUserLoggedIn(): void {
		this.isLoggedIn.next(false);
	}

	public isUserLoggedIn(): Boolean {
		let user = window.localStorage.getItem('_u');
		let token = window.localStorage.getItem('token');
		this.currentUser = (user) ? JSON.parse(user) : {};
		if (Object.keys(this.currentUser).length && token) {
			this.isLoggedIn.next(true);
			return true;
		} else {
			this.isLoggedIn.next(false);
			return false;
		}
	}

	public getUserLoggedIn() {
		return this.isUserLogin;
	}

	public getUserRole(): string {
		return this.currentUser.role.toString().toLowerCase();
	}

	public getToken(): any {
		return window.localStorage.getItem('token');
	}

	public getCurrentUserId(): string {
		let user: any = window.localStorage.getItem('_u');
		let userObj = JSON.parse(user);
		this.currentUserId = userObj?.accountId;
		return this.currentUserId;
	}

	public getCurrentUser(): any {
		let user: any = window.localStorage.getItem('_u');
		console.log(user)
		user = JSON.parse(user)
		return user || null;
	}

	public logout(redirect?: any): void {
		let user = window.localStorage.getItem('_u');
		let token = window.localStorage.getItem('token');
		if(user && token){
			this.loaderService.show();
			this.authService.logOut().subscribe((res)=>{
				this.loaderService.hide();
			}, (error)=>{
				this.loaderService.hide();
			})
		}
		this.logOutUser(redirect)
		
	}

	logOutUser(redirect?: any){
		let isBooster:any = window.localStorage.getItem('isWebBoosterRedirect');
		window.localStorage.clear();
		if(isBooster){
			window.localStorage.setItem('isWebBoosterRedirect', isBooster);
		}
		this.currentUser = null;
		this.isLoggedIn.next(false);
		if (!redirect) {
			this._route.navigate(['/']);
		}
	}
	
	getLogin() {
		return this.isLoggedIn.asObservable();
	}
	public isOnboarding(): Boolean {
		let user:any = window.localStorage.getItem('_u');
		user = JSON.parse(user)
		if(user?.onboardingQuestionAnswered){
			return true;
		} else {
			return false;
		}
	}
	public getTokenBonus(){
		// let date1 = new Date().getTime();
		// let date2 = new Date(Constants.AIRDROP_DATE).getTime();
		// date2 = new Date(date2).getTime();
		// let diffTime:any = date1 - date2;
		// let diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
		// let rounds = Math.floor(diffDays/3);
		// let setTime = new Date().setHours(20,30,0);
		// if(diffDays%3 == 0 && ( date1 < new Date(setTime).getTime())){
		// 	rounds = rounds -1;
		// }
		return 1000
	}
	
	scrollToElementById(id: string) {
		const element = this.__getElementById(id);
		this.scrollToElement(element);
	}
	private __getElementById(id: string): HTMLElement {
		const element = document.getElementById(id);
		return element;
	}

	scrollToElement(element: HTMLElement) {
		element.scrollIntoView({ behavior: "smooth" });
	}
	
	public getAvailableFundBalance(walletData){
		return (Number(walletData?.availableInstantSignupFund?.$numberDecimal||0)+Number(walletData?.availableMandatoryTaskFund?.$numberDecimal||0)+Number(walletData?.availableKYCFund?.$numberDecimal||0)+Number(walletData?.avilableVestedFund?.$numberDecimal||0)+Number(walletData?.availableReferralFund?.$numberDecimal||0)+Number(walletData?.availableTaskFund?.$numberDecimal||0))
}
}

