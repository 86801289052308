import { Component, OnInit } from '@angular/core';
import { MainService } from 'src/app/main/services/main.service';
import { LoaderService } from '../loader/loader.service';
import { CommonApiService } from '../../services/common-api.service';
import { AmountService } from '../../services/amount.service';
import { MixpanelService } from '../../services/mix-panel.service';
import { MixPanelConstants } from 'src/app/config/mix-panel-constant';


@Component({
  selector: 'app-kyc-verify',
  templateUrl: './kyc-verify.component.html',
  styleUrls: ['./kyc-verify.component.css']
})
export class KycVerifyComponent implements OnInit {
  kycStatus: any;
  linkId: any;
  applicationId: any;
  kycReason:any = [];
  showStatus = false;
	mixPanelConst:any=MixPanelConstants;

  constructor(private service: MainService, private loderService: LoaderService, 
    private sharedService: CommonApiService, private amountService: AmountService,private mixPanle:MixpanelService) { }

  ngOnInit(): void {
    const queryString = window.location.search;

    // Parse the query string
    const urlParams = new URLSearchParams(queryString);
  
    // Get the values of "link" and "applicationId" query parameters
    const linkValue = urlParams.get("link");
    const applicationIdValue = urlParams.get("applicationId");
    this.linkId = linkValue
    this.applicationId = applicationIdValue
    this.getKycStatus();
  }
  /**
   * @description:function retrieves and updates the KYC status and related details based on a provided link and application ID, triggering further actions upon successful verification.
   */
  getKycStatus() {
    let dataToSend = {
      link: this.linkId,
      applicationId: this.applicationId
    }
    this.showStatus = false;
    this.loderService.show();
		this.service.getKycDetailsByLinkId(dataToSend).subscribe((response) => {
    this.showStatus = true;
      this.loderService.hide();
			if (!response.error) {
        this.kycStatus = response?.data[response?.data?.length - 1]?.status;
        if(this.kycStatus ==  'ACCEPTED'){
          this.mixPanle.trackEvent(MixPanelConstants?.EVENT_NAME?.KYC_VERIFICATION_SUCCESSFUL);
		      this.amountService.setKYCVerify(true);
          let user = this.sharedService.getCurrentUser();
          user.KYCVerified = true;
          localStorage.setItem("_u", JSON.stringify(user))
        }else{
          this.mixPanle.trackEvent(MixPanelConstants?.EVENT_NAME?.KYC_VERIFICATION_FAILED);

        }

        this.kycReason = response?.data[response?.data?.length - 1]?.kycResponse?.authentication?.warning
       
        // this.kycStatus = response?.data?.KYCStatus
			}
		},(error)=>{
      this.showStatus = false;
      this.loderService.hide();

    });
	}

}
