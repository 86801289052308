import { Injectable } from '@angular/core';
import { HttpencapService } from 'src/app/shared/services/httpencap.service';

@Injectable({
	providedIn: 'root'
})
export class EarningsService {

	constructor(public _http: HttpencapService) { }

	public withdrawAmount(data?) {
		return this._http.post('withdrawals/v1/add', data)
	}

	public getWalletBalance() {
		return this._http.get('users/v1/wallet')
	}
	public getReceivedAmountDetails(id) {
		return this._http.get('transactions/v1/specific/transaction/release/detail?transactionId='+id)
	}
}
