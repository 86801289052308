import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormValidatorService } from '../../services/form-validator.service';
import noWhitespaceValidator from '../../services/no-white-space-validator';
import { ToastrService } from 'ngx-toastr';
import { Messages } from 'src/app/config/messages';
import Web3 from 'web3';
import { MixpanelService } from '../../services/mix-panel.service';
import { MixPanelConstants } from 'src/app/config/mix-panel-constant';
import { LoaderService } from '../loader/loader.service';
import { WalletService } from 'src/app/main/wallet/service/wallet.service';

@Component({
  selector: 'app-withdraw-modal',
  templateUrl: './withdraw-modal.component.html',
  styleUrls: ['./withdraw-modal.component.css']
})
export class WithdrawModalComponent implements OnInit {
  public isWithdrawProceed = false;
  public isWalletAddressConfirm = false;
  public constants= Messages.CONST_MSG;
  public 	mixPanelConst:any=MixPanelConstants;
  public errorMessage= '';
  public confirmWithdraw = false;
  //Form Group
  withdrawForm: FormGroup;
  
  selectedWalletComponent = '';
  tokenName = '';
  totalWithdrawTokens = 0;
  @Output() fetchData = new EventEmitter<any>();

  @ViewChild("claim", { static: true }) claimModal: ElementRef
  constructor(private modalService: NgbModal, private fb: FormBuilder, private validator: FormValidatorService,
    private toastrService: ToastrService, private mixPanel : MixpanelService, private loaderService: LoaderService,  private walletService: WalletService,) { } 

  ngOnInit(): void {
    this.createWithdrawForm();
  }
    openModal(tokenName, totalWithdrawTokens, selectedWalletComponent){
      this.fetchWalletData()
      this.tokenName = tokenName;
      this.totalWithdrawTokens= totalWithdrawTokens;
      this.selectedWalletComponent = selectedWalletComponent
      this.errorMessage = '';
    if(this.totalWithdrawTokens < 0.00001){
      this.errorMessage = 'Insufficient fund';
    }
      this.modalService.open(this.claimModal, { centered: true, size: 'md', windowClass:"withdrawl-claim", backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-basic-title' });

    }
  /**
	 * @description: used to create withdraw form
	*/
	createWithdrawForm() {
		this.withdrawForm = this.fb.group({
      walletAddress: ["",[Validators.required, noWhitespaceValidator]]
		});
	}

  get walletAddress(): any { return this.withdrawForm.get('walletAddress'); }

  onWithdrawProceed(){
    if (this.withdrawForm?.invalid) {
			this.validator.markControlsTouched(this.withdrawForm);
			return;
		}
    if(!this.isWalletAddressConfirm){
      this.toastrService.error(this.constants.PLEASE_CONFIRM_WALLET_ADDRESS);
      return;
    }
    this.isWithdrawProceed = true;
  }

  onConfirmWalletAddress(){
    if (this.withdrawForm?.invalid) {
			this.validator.markControlsTouched(this.withdrawForm);
			return;
		}
    this.isWalletAddressConfirm = this.checkWalletAddress();
    console.log("check wallet address",this.checkWalletAddress());
    if(!this.isWalletAddressConfirm){
      this.toastrService.error(this.constants.PROVIDE_VALID_WALLET_ADDRESS);
      return;
    }
    this.mixPanel.trackEvent(MixPanelConstants?.EVENT_NAME?.CONFIRM_WITHDRAWAL_ADDRESS,{field: MixPanelConstants?.PROPERTIES?.WITHDRAW_PAGE});

  }

  checkWalletAddress(){
    return Web3.utils.isAddress(this.walletAddress?.value);
  }

  onSubmitWithdraw(){
    this.errorMessage = '';
    if (this.withdrawForm?.invalid) {
			this.validator.markControlsTouched(this.withdrawForm);
			return;
		}
    if(!this.isWalletAddressConfirm){
      this.toastrService.error(this.constants.PLEASE_CONFIRM_WALLET_ADDRESS)
      return;
    }
    if(!this.confirmWithdraw){
      this.errorMessage = 'Please provide consent!'
      return;
    }
    let dataToSend = {
      walletComponent: this.selectedWalletComponent,
      walletAddress: this.walletAddress.value,
      userConsent: this.confirmWithdraw
    }
    this.loaderService.show();
		this.walletService.createWithdrawRequest(dataToSend).subscribe((res)=>{
			this.loaderService.hide();
			if(res?.error){
				this.toastrService.error(res?.message || this.constants.SOMETHING_WENT_WRONG)
			}else{
				this.toastrService.success(res?.message);
    this.mixPanel.trackEvent(MixPanelConstants?.EVENT_NAME?.WITHDRAWAL_SUBMITTED_SUCCESSFULLY,{field:MixPanelConstants?.PROPERTIES?.WITHDRAW_PAGE});

        console.log(this.totalWithdrawTokens,'this.totalWithdrawTokens------------mixpanel trac charge with token withdrawal amount calling.....');
        this.mixPanel.trackCharge(this.totalWithdrawTokens);
        this.closeModal();
       this.fetchWalletData();
			}
		},(error)=>{
      this.mixPanel.trackEvent(MixPanelConstants?.EVENT_NAME?.WITHDRAWAL_SUBMITTED_ERROR,{field:MixPanelConstants?.PROPERTIES?.WITHDRAW_PAGE});

			this.loaderService.hide();
			this.toastrService.error(error?.error?.message || this.constants.SOMETHING_WENT_WRONG)
		})
  }
  onChangeWalletAddress(){
    this.isWalletAddressConfirm = false;
  }

  fetchWalletData(){
    console.log("checkin 1")
    this.fetchData.emit();
  }

  /**
	 * @description: used to handle of confirm withdrawal
	*/
	handleConfirmation() {	
    this.errorMessage = '';		
		this.confirmWithdraw = !this.confirmWithdraw
	}
  closeModal(){
    this.isWithdrawProceed = false;
    this.isWalletAddressConfirm = false;
    this.confirmWithdraw = false;
    this.errorMessage = '';
    this.withdrawForm.reset();
    this.modalService.dismissAll();
  }

}
