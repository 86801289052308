import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { CommonApiService } from './common-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AESEncryptDecryptService } from './encryptDecryptService';
import { Constants } from 'src/app/config/constant';
import { Router } from '@angular/router';
// import { ReCaptchaV3Service } from 'ng-recaptcha';

@Injectable({
	providedIn: 'root'
})
export class AppInterceptorService implements HttpInterceptor {
	constructor(
		private sharedService: CommonApiService,
		private modalService: NgbModal,
		private encryptDataService: AESEncryptDecryptService,
		private router:Router
		// private recaptchaV3Service: ReCaptchaV3Service

	) { }

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		request = request.clone({
			url: environment.apiURL + request.url,
		});
		const token: string = this.sharedService.getToken();
		const user = this.sharedService.getCurrentUserId();

		if (token) {
			request = request.clone({
				setHeaders: {
					'x-access-user': user + '',
					'authorization': 'Bearer ' + token + '',
				},
			});
		}

		return next.handle(request)
			.pipe(
				tap((event: HttpEvent<any>) => {
					if (event instanceof HttpResponse) {
						// console.log(event, "response")
					}
				}, (err: any) => {
					if (err instanceof HttpErrorResponse) {
						console.log(err, "error")						
						if (err.status === 401 || err.status === 403) {
							this.modalService.dismissAll();
							this.sharedService.logout(true);
							this.router.navigate(["/auth"]);
							return;
						}
						if (err.statusText == "Unknown Error") {
							err.error.message = Constants.CONSTANTS.SOMETHING_WENT_WRONG;
						}
						
					
					}
				}),
			);
	}
}
