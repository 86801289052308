import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
	providedIn: 'root'
})
export class AmountService {

	constructor() { }
	private subject = new BehaviorSubject('N');
	private profileImage = new BehaviorSubject('');
	private deposit = new BehaviorSubject('N');
	private packagePurchase = new BehaviorSubject('N');
	private sidebar = new BehaviorSubject('N');
	private profileName = new BehaviorSubject(null);
	private packages = new BehaviorSubject(false);
	public showProfileVerify: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public showKYCVerify: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public getSocialConnectionData: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public OpenBuyNow: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public ReloadTask: BehaviorSubject<boolean> = new BehaviorSubject(false);
	public openTwitterReferral: BehaviorSubject<boolean> = new BehaviorSubject(false);


	setMessage(message) {
		this.subject.next(message);
	}
	getMessage() {
		return this.subject.asObservable();
	}
	clearMessage() {
		this.subject.next(null);
	}

	setImage(image) {
		return this.profileImage.next(image);
	}
	getImage() {
		return this.profileImage.asObservable();
	}

	setPayment(message) {
		this.deposit.next(message);
	}
	getPayment() {
		return this.deposit.asObservable();
	}

	setWalletAmount(message) {
		this.packagePurchase.next(message);
	}
	getWalletAmount() {
		return this.packagePurchase.asObservable();
	}

	setSidebar(message) {
		this.sidebar.next(message);
	}
	getSidebar() {
		return this.sidebar.asObservable();
	}

	setProfileName(message) {
		this.profileName.next(message);
	}
	getProfileName() {
		return this.profileName.asObservable();
	}
	setMyPackages(data) {
		this.packages.next(data);
	}
	getMyPackages() {
		return this.packages.asObservable();
	}
	setProfileVerify(message) {
		this.showProfileVerify.next(message);
	  }
	  getProfileVerify() {
		return this.showProfileVerify.asObservable();
	  }

	setKYCVerify(message) {
		this.showKYCVerify.next(message);
	  }
	  getKYCVerify() {
		return this.showKYCVerify.asObservable();
	  }

	  setSocialConnectiom(data) {
		this.getSocialConnectionData.next(data);
	  }
	  getSocialConnection() {
		return this.getSocialConnectionData.asObservable();
	  }
	  setOpenBuyNow(data) {
		this.OpenBuyNow.next(data);
	  }
	  getOpenBuyNow() {
		return this.OpenBuyNow.asObservable();
	  }
	  setOpenTwitterReferral(data) {
		this.openTwitterReferral.next(data);
	  }
	  getOpenTwitterReferral() {
		return this.openTwitterReferral.asObservable();
	  }
	  setReloadTask(data) {
		this.ReloadTask.next(data);
	  }
	  getReloadTask() {
		return this.ReloadTask.asObservable();
	  }

}
