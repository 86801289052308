import { Component, OnInit, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';
import { Constants } from 'src/app/config/constant';

@Component({
	selector: 'app-show-errors',
	templateUrl: './show-errors.component.html',
	styleUrls: ['./show-errors.component.css']
})
export class ShowErrorsComponent implements OnInit {

	private static readonly errorMessages = {
		'required': (param?) => `${param} is required!`,
		'email': () => 'Please enter a valid email!',
		'forbiddenEmail': () => 'Please enter a valid email!',
		'minlength': (params) => 'The min  characters is ' + params.requiredLength+' !',
		'maxlength': (params) => 'The max allowed  characters is ' + params.requiredLength+' !',
		'whitespace': () => 'Please enter valid input!',
		'unique': (params) => `This field already exists!`,
		'pattern': (params) => {
			if(params.requiredPattern == "^@([a-zA-Z0-9_]){1,15}$"){
				return 'Please enter valid username, e.g. @username!'
			}else if(params.requiredPattern == Constants.SOCIAL_PATTERN_VALIDATION_REGEX.TWITTER_REGX){
				return 'Please enter valid tweet link!'
			}
			else{
				return 'Please Enter a valid value!'
			}
			},
		'min': (params) => {
			if(params?.token){
				return `Minimum $5 worth of BUCK tokens required!`
			}else if (params?.name == 'Number Of Token'){
				return `Minimum 100 BUCK tokens required!`
			}else{
				return `Atleast ${params.min} is required in this field!`
			}
		},
		'max': (params) =>{
		if(!params?.token && params?.name == 'Number Of Token' ){
			return `Withdraw limit can not exceed your wallet balance!`
		}else if(params?.token){
			return `Max value can be ${params.max} ${params.token} only!`
			
		} else{
			return `Max value can be ${params.max} only!`
		}},
		'ngxEditor': (params) => `The max allowed number of characters is ${params.allowedLength} and curent characters are ${params.textLength}!`
	};

	@Input()
	public control: AbstractControlDirective | AbstractControl;
	@Input() fname = "";
	tokenName = '';
	constructor() { }

	ngOnInit() {
	}
	
	shouldShowErrors() {
		return this.control &&
			this.control.errors &&
			(this.control.dirty || this.control.touched);
	}

	listOfErrors(): string[] {
		let arr = Object.keys(this.control.errors as string[]);
		let msg = this.getMessage(arr[0], (this.control.errors as any)[arr[0]]);
		let array: any = [];
		array.push(msg);
		return array;
	}

	private getMessage(type: string, params: any) {
		if (type == "required") {
			if (this.fname) {
				params = this.fname;
			} else {
				params = 'This field';
			}
		} if (type == 'max' || type == 'min') {
			if (this.fname) {
				params.name = this.fname
			}
		}
		if((this.fname == 'Token') && (type == 'max'|| type == 'min')){
			params.token = 'BUCK'
		}
		return ShowErrorsComponent.errorMessages[type](params);
	}

}
