import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customTimer'
})
export class CustomTimerPipe implements PipeTransform {

  transform(targetDate: any, type = 'HH') {
    let targetTime = new Date(targetDate).getTime(); 
    // setInterval(() => {

    let date = new Date();
		let now = date.getTime();
    let difference = targetTime - now;
		// let day = Math.floor(this.difference / (24*60*60*1000));
	  // let daysms = this.difference % (24*60*60*1000);
	  let hours = Math.floor(difference  / (60*60*1000));
	  let hoursms = difference % (60*60*1000);
	  let minutes = Math.floor(hoursms / (60*1000));
	  let minutesms = difference % (60*1000);
	  let sec = Math.floor(minutesms / 1000);
    if(type == 'HH'){
        return hoursms+' Hrs';
    }else if(type == 'MM'){
      return minutesms + 'Min';
    }else if(type == 'hh'){
      return hours;
    }else if(type == 'mm'){
      return minutes;
    }else{
      return sec;
    }
  // },1000);
}

}
