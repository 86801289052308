import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpencapService } from 'src/app/shared/services/httpencap.service';
@Injectable({
	providedIn: 'root'
})
export class DashboardService {

	constructor(private http: HttpencapService) { }

	getTopFiveOngoingCampaigns() {
		return this.http.get('campaigns/v1/campaign/ongoing');
	}
	getDashboardData() {
		return this.http.get('projects/v1/dashboard');
	}
	getUserWalletData() {
		return this.http.get('users/v1/wallet');
	}
	getTopFiveCompletedCampaigns(data) {
		return this.http.get('campaigns/v1/campaign/completed', data);
	}
	getOngoingCampaigns(data) {
		return this.http.get('campaigns/v1/all/ongoing', data);
	}
	getLeadershipBoardDetails() {
		return this.http.get('users/v1/leadership');
	}
	getSignUpDetails() {
		return this.http.get('users/v1/recent/signup');
	}
	getReferralLink() {
		return this.http.get('users/v1/user/referral');
	}
	getAllSignUpCount() {
		return this.http.get('users/v1/all/count');
	}
	getAverageSignUpCount(data) {
		return this.http.get('users/v1/hourly/count', data);
	}
	hideName(data?) {
		return this.http.patch('users/v1/update/hidden/status', data);
	}
	uploadAttachment(data?) {
		return this.http.put('upload/v1/user/file', data);
	}
	raiseReport(data?) {
		return this.http.post('complaints/v1/insert', data);
	}
	getUserReferralDetails() {
		return this.http.get('users/v1/joined/team/referral/user');
	}
	getActivityLogs(data) {
		return this.http.get('taskLogs/v1/user/list',data)
	}
	getDirectUserCount(data?) {
		return this.http.get('users/v1/directsignup/count',data)
	}
	getFeaturedPackageDetails() {
		return this.http.get('packages/v1/featured/dashboard');
	}
	getTotalRewardAndCompletedTask(data?) {
		return this.http.get('users/v1/user/earned/rewards/tasks',data);
	}

	getCommunityTasks(data){
		return this.http.get('communityTasks/v1/user/paginated',data)
	}
	getMarketDetails(){
		return this.http.get('prices/v1/get/token/market/cap')
	}
	payKycFee(data){
		return this.http.patch('users/v1/pay/kyc/fee',data)
	}
	payAcmKycFee(data){
		return this.http.patch('users/v1/pay/acm/kyc/fee',data)
	}
	getLockedAirdropData(data?) {
		return this.http.get('vestingSchedules/v1/get/releasetime', data);
	}
	getMarketingLink(){
		return this.http.get("marketingLinks/v1/user")
	}
	getCampaignDetails(data){
		return this.http.get('campaigns/v1/task/details/for/user',data)
	}
	getCommunityDetails(data){
		return this.http.get('communityTasks/v1/task/details/for/user',data)
	}
	getBoosterRewards(){
		return this.http.post('users/v1/activate/reward/booster')
	}
}
