import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpencapService } from 'src/app/shared/services/httpencap.service';
@Injectable({
	providedIn: 'root'
})
export class MainService {
	activatedEmitter = new Subject<boolean>();

	constructor(private http: HttpencapService) { }

	getSocialConnection() {
		return this.http.get('social/v1/get');
	}
	verifyTask(data) {
		return this.http.post('transactions/v1/user/twitter/re/verify/task', data);
	}

	updateSocialConnection(data?) {
		return this.http.patch('social/v1/update', data);
	}


	getCountryList() {
		return this.http.get("users/v1/get/countries");
	}


	getAllCampaignTypes(data?) {
		return this.http.get('campaignTypes/v1/all', data);
	}

	getAllCampaignsByCampaignType(data) {
		return this.http.get('campaigns/v1/campaign/type', data);
	}

	getCampaignsCount() {
		return this.http.get('campaignTypes/v1/campaign/count');
	}

	getTransactions(data) {
		return this.http.get('transactions/v1/user/transactions', data);
	}

	getAllEarnings(data) {
		return this.http.get('transactions/v1/user/earnings', data);
	}

	startEarning(data) {
		return this.http.post('transactions/v1/user/add/token/wallet', data);
	}

	getUserProfile() {
		return this.http.get('users/v1/profile');
	}

	updateUserProfile(data) {
		return this.http.patch('users/v1/user/update', data);
	}


	updateUserProfilePicture(file) {
		return this.http.put('upload/v1/user/profilepicture', file);
	}

	addTwitterData(data) {
		return this.http.post('social/v1/add/details/twitter', data);
	}

	loginWithTelegram(data) {
		return this.http.patch('social/v1/add/details/telegram', data);
	}

	updateUserSocialProfile(data) {
		return this.http.patch('social/v1/update/list', data);
	}

	changePassword(data) {
		return this.http.patch('auth/v1/user/reset/password', data);
	}

	getContractAbi(data) {
		return this.http.get('transactions/v1/contract/abi', data);
	}
	getTopFiveCompletedCampaigns() {
		return this.http.get('campaigns/v1/campaign/completed');
	}
	getTotalReward(data) {
		return this.http.get('transactions/v1/total/distribution/coins', data);
	}
	getThisMonthReward(data) {
		return this.http.get('transactions/v1/month/distribution/coins', data);
	}
	getThisWeekReward(data) {
		return this.http.get('transactions/v1/week/distribution/coins', data);
	}
	getTodayReward(data) {
		return this.http.get('transactions/v1/today/distribution/coins', data);
	}

	insertActionLog(data) {
		return this.http.post('taskLogs/v1/insert ', data);
	}

	hideMyName(data?) {
		return this.http.patch('users/v1/update/hidden/status', data);
	}
	getYouTubeSocialConnection(id) {
		return this.http.get(`auth/v1/google/social?id=${id}`);
	}
	grantReward(data) {
		return this.http.post('transactions/v1/user/add/token/wallet', data);
	}
	checkRewardAvailable(data) {
		return this.http.get('campaigns/v1/get/campaign/reward', data);
	}
	getWithDrawal(data) {
		return this.http.get('withdrawals/v1/get/user', data);
	}
	addTelegramConfig(data){
		return this.http.post('telegramConfigs/v1/create', data)
	}
	getTelegramDiscordConfig(data){
		return this.http.get('telegramConfigs/v1/list', data)
	}
	updateTelegramConfigStatus(data){
		return this.http.patch('telegramConfigs/v1/update/status',data)
	}
	deleteChannelConfig(data){
		return this.http.patch('telegramConfigs/v1/delete',data)
	}
	updateTwitterProfiling(){
		return this.http.get('social/v1/refresh/user/category')
	}
	addDiscordConfig(data){
		return this.http.post('telegramConfigs/v1/create/discord/config', data)
	}
	updateDiscordConfigStatus(data){
		return this.http.patch('DiscordConfigs/v1/update/status',data)
	}
	deleteDiscordConfig(data){
		return this.http.patch('DiscordConfigs/v1/delete',data)
	}
	getDiscordChannel(data){
		return this.http.get('campaigns/v1/get/discord/details',data)
	}
	sendOTP(data?){
		return this.http.post('auth/v1/user/send/verification/otp',data)
	}
	verifyOTP(data){
		return this.http.post('auth/v1/user/verify/sent/otp',data);
	}
	updateUserProfiling(data?){
		return this.http.patch('social/v1/unlock/user/category',data)
	}
	getKYCDetails(){
		return this.http.get("users/v1/get/kyc/status");
	}
	getKycVerifyLink(){
		return this.http.get("users/v1/get/faceki/kyc/link")
	}
	saveFacekiKycDetails(data) {
		return this.http.post('users/v1/add/faceki/kyc/details', data);
	  }

	  getKycDetailsByLinkId(data?){
		return this.http.get('users/v1/get/kyc/status/by/link',data);
	  }
	  getVestingListDetails(data){
		return this.http.get("vestingSchedules/v1/get", data)
	  }
	  getVestingDetailsData(data){
		return this.http.get("vestingTransactions/v1/get",data)
	  }
	  performCummintyTask(data){
		return this.http.post('communityTasks/v1/user/perform',data)
	  }
	  getTaskCounts() {
		return this.http.get('communityTasks/v1/user/stats');
	}
	getTelegramBotLink() {
		return this.http.get("campaigns/v1/get/telegram/bot/link");
	}
	getChainId(data?) {
		return this.http.get("campaigns/v1/telegram/channel/chat/id",data);
	}
	getAddBotLink(data?) {
		return this.http.get("campaigns/v1/get/discord/bot/link",data);
	}
	updateTelegramChannelConfig(data?) {
		return this.http.patch("telegramConfigs/v1/update/config",data);
	}
	reportCampaignTaskIssue(data?) {
		return this.http.post("complaints/v1/report/campaign/issue",data);
	}
	reportCommunityTaskIssue(data?) {
		return this.http.post("complaints/v1/report/community/task/issue",data);
	}
}

