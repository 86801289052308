import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Messages } from '../config/messages';
import { Constants } from '../config/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonApiService } from '../shared/services/common-api.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import forbiddenEmailValidation from '../shared/services/email-validator.service';
import noWhitespaceValidator from '../shared/services/no-white-space-validator';
import { FormValidatorService } from '../shared/services/form-validator.service';
import { LoaderService } from '../shared/components/loader/loader.service';
import { AuthService } from '../auth/services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { WalletService } from '../shared/services/wallet.service';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { MixpanelService } from '../shared/services/mix-panel.service';
import { MixPanelConstants } from 'src/app/config/mix-panel-constant';
import { TitleService } from '../shared/services/title.service';


@Component({
  selector: 'app-private-sale',
  templateUrl: './private-sale.component.html',
  styleUrls: ['./private-sale.component.css']
})
export class PrivateSaleComponent implements OnInit, OnDestroy {

  @ViewChild("signupModal", { static: true }) QRModal: ElementRef;
  @ViewChild("paymentSuccessModal", { static: true }) paymentSuccessModal: ElementRef;

  public message = Messages;
  public constants = Constants;
  networkList = this.constants.NETWORK_LIST;
  networkObj = this.constants.NETWORK_OBJ;
  minTokenAmount = this.constants.MIN_TOKEN_AMOUNT;
  TokenName = Constants.SYSTEM_TOKEN_DETAILS.name;
	mixPanelConst:any=MixPanelConstants;


  // Form Group
	completeSignUpForm: FormGroup;
  loginForm: FormGroup;
  saleForm: FormGroup;
  needHelpForm: FormGroup;
	// boolean variable to show and hide password
	hidePassword = true
	hideCpassword = true;
	disableButton = false;
  public gateResponse = false;
  public buttonClicked = false;
  buyFromSale = true;
  KometWalletDetails:any;
  walletAddress:string = '';
  selectedNetwork = '';
  transactionId = '';
  currentPrice = 0;
  progress = 0;
  usdAmountData:any;
  bnbValue : any;
  userDetails:any;
  selectedHelpType = 'Investment';
  isUserLoggedIn: any;
  	// Object
	public gatewayData: any = {};
	public roundDetails:any;
  dataToSend:any;
  signupForm= true;
  transactionHash = '';

  date: any;
  now: any;
  targetDate: any = new Date();
  targetTime: any = this.targetDate.getTime();
  difference: number;
  months: Array<string> = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  currentTime: any = `${
    this.months[this.targetDate.getMonth()]
  } ${this.targetDate.getDate()}, ${this.targetDate.getFullYear()}`;

  @ViewChild('days', { static: true }) days: ElementRef;
  @ViewChild('hours', { static: true }) hours: ElementRef;
  @ViewChild('minutes', { static: true }) minutes: ElementRef;
  @ViewChild('seconds', { static: true }) seconds: ElementRef;

  constructor(private modalService: NgbModal, private commonApi: CommonApiService, private fb: FormBuilder,
    private validator: FormValidatorService, private loaderService: LoaderService, private authService: AuthService,
    private toastrService: ToastrService, private walletService: WalletService, private route: ActivatedRoute,
    private router: Router, private sharedService: CommonApiService,private mixpanelService:MixpanelService,private titleService: TitleService
	) {
	 }

  ngOnInit(): void {
	const urlParams = new URLSearchParams(window.location.search);
	const utmSource = urlParams.get('utm_source');
	const utmMedium = urlParams.get('utm_medium');
	const utmCampaign = urlParams.get('utm_campaign');
	if(utmSource || utmMedium || utmCampaign ){
		
		this.mixpanelService.trackEvent(this.mixPanelConst.UTM_CONST.BUY);
	}
	this.titleService.setTitle("Buy");
	this.networkList = this.networkList.filter(item => item?.value == 'BNB');
	this.selectedNetwork = this.networkList[0]?.key;
	// if(this.router.url == "/buy"){
	// 	this.buyFromSale = false;
	// }
	this.route.queryParams.subscribe(params => {
		if (this.route?.snapshot?.url?.join('/') === "buy" && params) {
			this.buyFromSale = false;
		  
		}
	  });
	this.isUserLoggedIn = this.sharedService.isUserLoggedIn()
    this.createSaleForm();
    this.getBaseTokenList();
	if(this.buyFromSale){
		this.getRoundDetails();
	}else{
		this.getTokenAmountEx();
	}
    this.route.params.subscribe((params) => {
      if(params?.data){
        let socailData = JSON.parse(atob(params.data));
        if (!socailData || (socailData && socailData.error)) {
          this.toastrService.info(socailData?.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG);
          return;
        }
        socailData = socailData.data
  
        if (!socailData.token || !socailData.user) {
          return;
        }
  
        let data = {
          token: socailData.token,
          user: socailData.user
        }
		this.isUserLoggedIn = true;
		this.userDetails = data.user;
        this.commonApi.setUserLoggedIn(data);
      }
      
		});
    this.userDetails = this.commonApi.getCurrentUser();
    if(localStorage.getItem("formData")){
      this.dataToSend = JSON.parse(localStorage.getItem("formData"));
      this.createTransaction(this.QRModal)
    }
	this.createNeedHelpForm();
	this.setNeedHelpEmailValidation()
 
  }
	/**
	 * @description: used to create sale form
	 */
	createSaleForm() {
		this.saleForm = this.fb.group({
			baseTokenName: [this.networkList[0]?.key, [Validators.required]],
			baseTokenAmount: ['', [Validators.required, Validators.max(100000000)]],
			amount: ['', [Validators.required]],
		});
	}
  	get baseTokenName(): any { return this.saleForm.get('baseTokenName'); }
	get baseTokenAmount(): any { return this.saleForm.get('baseTokenAmount'); }
	get amount(): any { return this.saleForm.get('amount'); }

  	/**
	 * @description: used to create signup form
	 */
	createForm() {
		this.completeSignUpForm = this.fb.group({
			username: ['', [Validators.required, Validators.pattern("^[A-Za-z ]*$"), noWhitespaceValidator]],
			email: ['', [Validators.required, forbiddenEmailValidation]],
			password: ['', [Validators.required, noWhitespaceValidator, Validators.minLength(8), Validators.maxLength(20)]],
		});
	}

	get username(): any { return this.completeSignUpForm.get('username'); }
	get email(): any { return this.completeSignUpForm.get('email'); }
	get password(): any { return this.completeSignUpForm.get('password'); }


  /**
	 * @description: used to create login form
	 */
	createLoginForm() {
		this.loginForm = this.fb.group({
			email: ['', [Validators.required, forbiddenEmailValidation]],
			password: ['', [Validators.required, noWhitespaceValidator, Validators.minLength(8), Validators.maxLength(50)]]
		});
	}

	get loginEmail(): any { return this.loginForm.get('email'); }
	get loginPassword(): any { return this.loginForm.get('password'); }

	createNeedHelpForm() {
		this.needHelpForm = this.fb.group({
			name: ['', [Validators.required]],
			contactNo: ['',],
			needHelpEmail: ['', [Validators.required, forbiddenEmailValidation]],
		});
	}
  	get name(): any { return this.needHelpForm.get('name'); }
	get contactNo(): any { return this.needHelpForm.get('contactNo'); }
	get needHelpEmail(): any { return this.needHelpForm.get('needHelpEmail'); }

	setNeedHelpEmailValidation(){
		if(this.isUserLoggedIn){
			this.needHelpEmail.clearValidators();
		}
		else{
			this.needHelpEmail.setValidators([Validators.required,forbiddenEmailValidation])
		}
		this.needHelpEmail.updateValueAndValidity();
	}

  getBaseTokenList(){
    this.loaderService.show();
    this.walletService.getBaseToken().subscribe((res)=>{
      this.loaderService.hide();
      if(!res?.error){
        this.usdAmountData = res?.data;
      }
    }, (error)=>{
      this.loaderService.hide();
    })
  }
  getTokenAmountEx(){
    this.loaderService.show();
    this.walletService.getTokenAmountEx().subscribe((res)=>{
      this.loaderService.hide();
      if(!res?.error){
        this.currentPrice = res?.data.cbxPrice || 0
      }
    }, (error)=>{
      this.loaderService.hide();
    })
    
  }

  getTokenAmount(){
	let DTS ={
		saleId: this.roundDetails?._id
	}
    this.loaderService.show();
    this.walletService.getTokenAmount(DTS).subscribe((res)=>{
      this.loaderService.hide();
      if(!res?.error){
        this.currentPrice = res?.data.cbxPrice || 0
      }
    }, (error)=>{
      this.loaderService.hide();
    })
    
  }
  getRoundDetails(){
	this.loaderService.show();
	this.walletService.getRoundDetails().subscribe((res)=>{
		this.loaderService.hide();
		if(!res?.error){
			if(res?.data?.title){
			this.roundDetails = res?.data;
			this.getTokenAmount();
			 this.progress = (this.roundDetails?.tokensSoldTillNow?.$numberDecimal/this.roundDetails?.tokensAlloted?.$numberDecimal)*100;
			this.targetDate = new Date(this.roundDetails?.endDate);
			this.targetTime = this.targetDate.getTime();
			this.currentTime = `${
			  this.months[this.targetDate.getMonth()]
			} ${this.targetDate.getDate()}, ${this.targetDate.getFullYear()}`;
			setInterval(() => {
		
				this.tickTock();
				
				this.difference = this.targetTime - this.now;
				let day = Math.floor(this.difference / (24*60*60*1000));
				let daysms = this.difference % (24*60*60*1000);
				let hours = Math.floor(daysms / (60*60*1000));
				let hoursms = this.difference % (60*60*1000);
				let minutes = Math.floor(hoursms / (60*1000));
				let minutesms = this.difference % (60*1000);
				let sec = Math.floor(minutesms / 1000);
				!isNaN(this.days.nativeElement.innerText)
				  ? (this.days.nativeElement.innerText = day)
				  : (this.days.nativeElement.innerHTML = `<img src="https://i.gifer.com/VAyR.gif" />`);
				  !isNaN(this.hours.nativeElement.innerText)
				  ? (this.hours.nativeElement.innerText = hours)
				  : (this.hours.nativeElement.innerHTML = `<img src="https://i.gifer.com/VAyR.gif" />`);
				  !isNaN(this.minutes.nativeElement.innerText)
				  ? (this.minutes.nativeElement.innerText = minutes)
				  : (this.minutes.nativeElement.innerHTML = `<img src="https://i.gifer.com/VAyR.gif" />`);
				  !isNaN(this.seconds.nativeElement.innerText)
				  ? (this.seconds.nativeElement.innerText = sec)
				  : (this.seconds.nativeElement.innerHTML = `<img src="https://i.gifer.com/VAyR.gif" />`);
			
		 
		}, 1000);
		}else{
			this.baseTokenAmount.disable();
			this.amount.disable();
		}
		}
	}, (error)=>{
		this.loaderService.hide();
	})
  }
 async openSignupModal(content){
    if (this.saleForm?.invalid) {
			this.validator.markControlsTouched(this.saleForm);
			return;
		}
		if(this.buyFromSale && (this.amount?.value * this.currentPrice < this.minTokenAmount.sale)){
			this.toastrService.error(`The purchase amount must meet the minimum requirement of $${this.minTokenAmount.sale}. Please adjust your purchase amount and try again`);
			return;
		  }
		  if(!this.buyFromSale && (this.amount?.value * this.currentPrice < this.minTokenAmount.buy)){
			  this.toastrService.error(`The purchase amount must meet the minimum requirement of $${this.minTokenAmount.buy}. Please adjust your purchase amount and try again`);
			  return;
			}

		if(this.getKometWalletAddress()){
			let checkKometWallet= await this.checkKometWalletFund();
			let checkKometWalletAddress=  this.getKometWalletAddress();
			console.log(checkKometWallet,checkKometWalletAddress,'checkKometWallet,checkKometWalletAddress');
			if(!checkKometWallet && checkKometWalletAddress){
				document.getElementById('insufficientBtn')?.click();
	
				return;
			}
			if(checkKometWallet){
				this.createKometTransaction();
				return;
			}

		}
  
	  this.trackEvent(MixPanelConstants?.EVENT_NAME?.BUY_NOW)
    if(this.userDetails){
     this.createTransaction(content)
    }else{
      this.createForm();
      this.createLoginForm();
      this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true })
    }

  }


  createKometTransaction(){
	let dts :any ={
		baseTokenAmount:this.bnbValue
	}

	this.loaderService.show();
	this.walletService.createKometTransaction(dts).subscribe((resp: any) => {
		this.loaderService.hide();
		if (resp?.error) {
			this.toastrService.error(resp?.message)
		} else {
			this.toastrService.success(resp?.message );
			document.getElementById('paymentSuccessBtn')?.click();
		}

	}, (err) => {
		this.loaderService.hide();
		this.toastrService.error(err?.error?.message )
	})
  }

  	/**
	 * @description: used to signup to the panel 
	 */
	register() {
		if (this.completeSignUpForm?.invalid) {
			this.validator.markControlsTouched(this.completeSignUpForm);
			return;
		}
	
		let dataToSend:any = {
			"email": this.completeSignUpForm.value.email.toLowerCase(),
			"name": this.completeSignUpForm.value.username,
			"password": this.completeSignUpForm.value.password,
			"provider": "email",
    "buyTokens": true,
    "baseTokenName":this.baseTokenName.value,
    "baseTokenAmount":this.baseTokenAmount.value,
    "amount":this.amount.value,
    "usdAmount": (this.amount.value * this.currentPrice),
    "currentPrice": this.currentPrice,
	"saleId": this.roundDetails?._id
		}

		this.disableButton = true;
		this.loaderService.show();
		this.authService.register(dataToSend).subscribe((resp: any) => {
		this.disableButton = false;
			this.loaderService.hide();
			if (resp.error) {
				this.toastrService.error(resp.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
					timeOut: 3000
				});
			} else {
				this.isUserLoggedIn = true;
				let data = resp.data;

				let authData = {
					token: data.token,
					user: data.user
				};
				this.commonApi.setUserLoggedIn(authData);
				this.userDetails = authData.user;
				localStorage.setItem('new_user', "true");
				localStorage.setItem('connect_social', "true");
		this.completeSignUpForm.reset();
        this.gatewayData = resp?.data?.addressDetails;
        this.transactionId = this.gatewayData?.fundTransactionId
        this.walletAddress = this.gatewayData?.address;
        this.walletAddress = JSON.stringify(this.walletAddress)
        this.gateResponse = true;
				

			}
		}, (err) => {
			this.disableButton = false;
			this.loaderService.hide();
			this.toastrService.error(err?.error?.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
				timeOut: 3000
			});
		});
	}
  /**
	 * @description: used to login into panel
	 */
	proceedToLogin() {
		if (this.loginForm.invalid) {
			this.validator.markControlsTouched(this.loginForm)
			return;
		}
		let data: any = {};
		data.email = this.loginEmail.value.toLowerCase();
		data.password = this.loginPassword.value;
		this.loaderService.show();
		this.authService.login(data).subscribe((resp: any) => {
			this.loaderService.hide();
			if (resp.error) {
				this.toastrService.error(resp.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
					timeOut: 3000
				});
			} else {
				this.isUserLoggedIn = true;
				this.userDetails = resp?.data?.user;
				this.commonApi.setUserLoggedIn(resp.data);
				this.loginForm.reset();
        this.createTransaction(this.QRModal)
			}
		}, (err) => {
			this.loaderService.hide();
			this.toastrService.error(err?.error?.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
				timeOut: 3000
			});
		});
	}
  handleLogin(){
	this.completeSignUpForm.reset();
	this.loginForm.reset();
    this.signupForm = !this.signupForm
  }
  closeModal(){
	this.transactionHash = '';
	this.signupForm = true;
    this.gatewayData = null;
    // this.gateResponse = false;
    this.modalService.dismissAll();
  }

  	/**
	 * @description: used to social login
	 * @param socialType 
	 */
	socialLogin(socialType) {
    if(this.signupForm){
      localStorage.setItem('new_user', "true");
      localStorage.setItem('connect_social', "true");
    }
    this.dataToSend = {
      "baseTokenName": this.baseTokenName?.value,
      "baseTokenAmount": this.baseTokenAmount?.value,
      "amount": this.amount?.value,
      "usdAmount": this.amount?.value * this.currentPrice,
      "currentPrice": this.currentPrice,
	  "saleId": this.roundDetails?._id
    }
    localStorage.setItem("formData", JSON.stringify(this.dataToSend))
		window.location.href = environment.apiURL + 'auth/v1/' + socialType+"?buyTokens=true" ;
	}

  	/**
	 * @description: used to copy data
	 * @param text 
	 */
	copyToClipboard(text) {
		navigator.clipboard.writeText(text).then(
			() => {
				this.toastrService.success(
					Messages.CONST_MSG.COPIED_TO_CLIPBOARD,
					Messages.CONST_MSG.SUCCESS,
					{
						timeOut: 3000,
					}
				);
			},
			(err) => {
				this.toastrService.success(
					Messages.CONST_MSG.ERROR_COPIED_TO_CLIPBOARD,
					Messages.CONST_MSG.ERROR,
					{
						timeOut: 3000,
					}
				);
			}
		  );
		// document.addEventListener("copy", (e: ClipboardEvent) => {
		// 	e.clipboardData.setData("text/plain", text);
		// 	e.preventDefault();
		// 	document.removeEventListener("copy", null);
		// });
		// document.execCommand("copy");
		// this.toastrService.success(
		// 	this.constants.CONSTANTS.COPIED_TO_CLIPBOARD,
		// 	this.constants.CONSTANTS.SUCCESS,
		// 	{
		// 		timeOut: 3000,
		// 	}
		// );
	}

  

  /**
	 * @description: used to close payment popup
	 * @param type 
	 */
	closePopupPayment(type) {
		
		this.buttonClicked = true
		let message = "";
		if (type == 1) {
			this.confirmPayment()
		} else if (type == 2) {
			this.selectedNetwork = '';
			this.saleForm.reset();
			this.closeModal();
			message = this.message.CONST_MSG.INVOICE_SAVED;
			this.toastrService.success(message, this.message.CONST_MSG.SUCCESS, { timeOut: 2000 });
		} else {
			this.selectedNetwork = '';
			this.saleForm.reset();
			this.closeModal();
			message = this.message.CONST_MSG.INVOICE_CANCELLED_AUTOMATICALLY;
			this.toastrService.clear();
			this.toastrService.error(message);
		}
		let that = this;
		setTimeout(function () {
			this.buttonClicked = false
		}, 2000);
	}

  handleNetwork(netwrok){
	if(this.roundDetails || (!this.roundDetails && !this.buyFromSale)){
		this.selectedNetwork = netwrok;
		this.baseTokenName.patchValue(netwrok);
		this.amount.patchValue();
		this.baseTokenAmount.patchValue();
	}
  
  }
  handleBaseTokenInput($event){
    let amount = $event.target.value;
	console.log("==================",this.usdAmountData,this.selectedNetwork);
    let totalUsd = Number(amount) * Number(this.usdAmountData[this.selectedNetwork]?.usd) || 0;
    let token = (totalUsd/this.currentPrice);
    this.amount.patchValue(token)

  }
  handleBaseToken($event){
    let amount = $event.target.value;
	console.log("amounttttttttttttttt",amount,this.usdAmountData);
    let token = (amount/this.currentPrice);
	this.bnbValue = (amount/this.usdAmountData[this.selectedNetwork]?.usd);
	console.log(this.bnbValue);
    this.amount.patchValue(token)

  }
  handleTokenInput($event){
    let amount = $event.target.value;
    let totalUsd = Number(amount) * Number(this.currentPrice) ;
    let token = (totalUsd/ Number(this.usdAmountData[this.selectedNetwork]?.usd)) || 0;
    this.baseTokenAmount.patchValue(token)
  }
  handleToken($event){
    let amount = $event.target.value;
    let totalUsd = Number(amount) * Number(this.currentPrice) ;
    this.baseTokenAmount.patchValue(totalUsd)
  }


  /**
	 * @description: used to create fund transaction
	 */
	createTransaction(content) {
    let DTS: any 
		if(this.dataToSend){
      DTS = this.dataToSend
    }else{
      DTS = {
        "baseTokenName": this.baseTokenName?.value,
      "baseTokenAmount": Number(this.baseTokenAmount?.value)/this.usdAmountData[this.selectedNetwork]?.usd,
      "amount": this.amount?.value,
      "usdAmount": this.amount?.value * this.currentPrice,
      "currentPrice": this.currentPrice,
	  "saleId": this.roundDetails?._id
      };
    }
		
		this.loaderService.show();
		this.walletService.createTransaction(DTS).subscribe(
			(response) => {
        localStorage.removeItem("formData");
				this.loaderService.hide();
				if (response?.error) {
					console.error(response.message);
					this.toastrService.error(response?.message);
				} else {
					this.gatewayData = response?.data?.addressDetails;
          this.transactionId = this.gatewayData?.fundTransactionId
					this.walletAddress = this.gatewayData?.address;
					this.walletAddress = JSON.stringify(this.walletAddress)
          this.gateResponse = true;
          this.modalService.open(content, { size: 'md', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true })
        
				}
			},
			(error) => {
        localStorage.removeItem("formData");
				this.loaderService.hide();
				console.error(error);
				this.toastrService.clear();
				this.toastrService.error(error?.error?.message, this.message.CONST_MSG.ERROR, {
					timeOut: 3000,
				});
				
			}
		);
	}
  confirmPayment(){
    let DTS:any={
      fundTransactionId: this.transactionId
    }
	if(this.transactionHash){
		DTS.transactionHash = this.transactionHash
	}
    this.loaderService.show();
    this.walletService.confirmPayment(DTS).subscribe((res)=>{
      this.loaderService.hide();
      if(!res?.error){
      // this.closeModal();
	  this.transactionHash = '';
      this.modalService.dismissAll();
	  this.openPaymentSuccessPopup();
		// this.toastrService.success( this.message.CONST_MSG.TRANSACTION_SUCCESSFULL, this.message.CONST_MSG.SUCCESS, { timeOut: 2000 });
      }
    }, (error)=>{
		this.transactionHash = ''
      this.loaderService.hide();
      this.toastrService.error(error?.error?.message || this.message.CONST_MSG.PAYMENT_NOT_DONE)
    })
  }

  openPaymentSuccessPopup(){
	this.modalService.open(this.paymentSuccessModal, { size: 'md', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true,  windowClass:'getHelp' })
  }

  redirectOnSuccessProceed(){
	this.modalService.dismissAll();
	let url = localStorage.getItem('redirectAfterBuy')
	window.open(url, '_self');
  }

  restrictPlusMinus(event: KeyboardEvent) {
	// Check if the key pressed is + or - and prevent it
	if (event.key === '+' || event.key === '-' || event.key === 'e' || event.key === 'E') {
	  event.preventDefault();
	}
}
goBack(){
	this.router.navigate(["/main"])
}

  openNeedHelpModal(content){
	this.needHelpForm.reset();
	this.selectedHelpType = 'Investment';
    this.modalService.open(content, { size: 'xl', ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false, centered: true })
  }

  onCheckboxChange(value){
	this.selectedHelpType = value
  }

  OnSubmitNeedHelp(){
	if (this.needHelpForm.invalid) {
		this.validator.markControlsTouched(this.needHelpForm)
		return;
	}
	let dataToSend : any = {
		name: this.name.value,
		type: this.selectedHelpType,
		contact: this.contactNo.value
	}
	if(this.needHelpEmail.value){
		dataToSend.email = this.needHelpEmail.value
	}
	if(this.isUserLoggedIn){
		this.needHelpSubmitWithAuthentication(dataToSend);
	}
	else{
		this.needHelpSubmitWithoutAuthentication(dataToSend);
	}
  }

  needHelpSubmitWithAuthentication(dataToSend){
	this.loaderService.show();
	this.walletService.createNeedHelpDataAuthenticated(dataToSend).subscribe((resp: any) => {
		this.loaderService.hide();
		if (resp.error) {
			this.toastrService.error(resp.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
				timeOut: 3000
			});
		} else {
			this.toastrService.success('Submitted Successfully');
			this.closeModal();
		}
	}, (err) => {
		this.loaderService.hide();
		this.toastrService.error(err?.error?.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
			timeOut: 3000
		});
	});
  }

  needHelpSubmitWithoutAuthentication(dataToSend){
	this.loaderService.show();
	this.walletService.createNeedHelpDataUnauthenticated(dataToSend).subscribe((resp: any) => {
		this.loaderService.hide();
		if (resp.error) {
			this.toastrService.error(resp.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
				timeOut: 3000
			});
		} else {
			this.toastrService.success('Submitted Successfully');
			this.closeModal();
		}
	}, (err) => {
		this.loaderService.hide();
		this.toastrService.error(err?.error?.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
			timeOut: 3000
		});
	});
  }
  cancelPayment() {
	this.transactionHash = '';
	// this.selectedNetwork = ''; 
	
	this.saleForm.reset(); 
  
	this.saleForm.patchValue({
		baseTokenName: this.networkList[0]?.key || '', // Set to the first network in the list or leave empty
		baseTokenAmount: '', // Set baseTokenAmount to an empty string to display blank
		amount: '' // Set amount to an empty string to display blank
	  });
  
	// Reset bnbValue to prevent NaN issue
	this.bnbValue = 0;
  
	this.closeModal();
  
	let DTS = {
	  fundTransactionId: this.transactionId
	};
  
	this.loaderService.show();
	this.walletService.cancelPayment(DTS).subscribe((res) => {
	  this.loaderService.hide();
	  if (!res?.error) {
		this.toastrService.info(this.message.CONST_MSG.INVOICE_CANCELLED_AUTOMATICALLY);
	  }
	}, (error) => {
	  this.loaderService.hide();
	  this.toastrService.error(error?.error?.message || this.message.CONST_MSG.PAYMENT_NOT_DONE);
	});
  }


  tickTock() {
	this.date = new Date();
    this.now = this.date.getTime();
	let day = Math.floor(this.difference / (24*60*60*1000));
  let daysms = this.difference % (24*60*60*1000);
  let hours = Math.floor(daysms / (60*60*1000));
  let hoursms = this.difference % (60*60*1000);
  let minutes = Math.floor(hoursms / (60*1000));
  let minutesms = this.difference % (60*1000);
  let sec = Math.floor(minutesms / 1000);
    this.days.nativeElement.innerText = day;
    this.hours.nativeElement.innerText = hours;
    this.minutes.nativeElement.innerText = minutes;
    this.seconds.nativeElement.innerText = sec
  }

  trackEvent(input){
	this.mixpanelService.trackEvent(input,{field: MixPanelConstants?.PROPERTIES?.BUY_PAGE});
  }
getKometWalletAddress(){
	return JSON.parse(localStorage.getItem('kometWalletData'))?.address || null;
}

async checkKometWalletFund(): Promise<boolean> {
    let dts: any = {
        baseTokenAmount: Number(this.bnbValue),
    };
    
    try {
        this.loaderService.show();
        const res = await this.walletService.checkKometWalletBalance(dts).toPromise(); // convert to promise
        
        this.loaderService.hide();
        
        if (!res?.error) {
            console.log(res?.data?.sufficient, 'sufficient');
            return res?.data?.sufficient || false;  // returning the result correctly
        } else {
            return false;
        }
    } catch (error:any) {
        this.loaderService.hide();
        this.toastrService.error(error?.error?.message || this.message.CONST_MSG.PAYMENT_NOT_DONE);
        return false;
    }
}

openKometModal(modal){
	this.modalService.open(modal, { centered: true, size: 'md', windowClass: "withdrawl-popup", backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-basic-title' });
}
  ngOnDestroy(): void {
	localStorage.removeItem('redirectAfterBuy');
}

kometWalletRedirection(){
	this.closeModal();
	window.open('https://app.komet.me/home/discover', '_blank');
  }
}
