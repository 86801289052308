import { Injectable } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { CommonApiService } from './common-api.service';
import { environment } from 'src/environments/environment';

interface MixpanelProperties {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private userAccountId: string | null = null;

  constructor(private commonApi: CommonApiService) {
    this.initMixpanel();
    this.setUserEmail();
  }

  private initMixpanel() {
    console.log(environment.mixpanelToken, '-------------------------- env.mixpanel token -----------------------');
    mixpanel.init(environment.mixpanelToken, { debug: true, ignore_dnt: true });
  }

  private setUserEmail() {
    const currentUser = this.commonApi.getCurrentUser();
    this.userAccountId = currentUser?.accountId || null;
    if (this.userAccountId) {
      console.log(this.userAccountId, '-------------------------- user account id for identify() -----------------------');
      mixpanel.identify(this.userAccountId);
      this.setPeopleProperties(currentUser);
    }
  }

  private setPeopleProperties(user: any): void {
    if (user) {
      mixpanel.people.set({
        $email: user.email,
      });
    }
  }

  public aliasUser(newUserId: string): void {
    const distinctId = mixpanel.get_distinct_id();
    console.log(distinctId, '-------------------------- distinctId -----------------------');
    console.log(mixpanel.get_distinct_id(), '-------------------------- mixpanel.get_distinct_id() -----------------------');
    console.log(newUserId, '-------------------------- newUserId -----------------------');
    mixpanel.alias(newUserId, distinctId);
  }

  public trackEvent(eventName: string, properties: MixpanelProperties = {}): void {
    try {
      let now = new Date();
      let ISTTime = new Date(now.getTime());
      let timeString = ISTTime.getDate() + '-' + (ISTTime.getMonth() + 1) + '-' + ISTTime.getFullYear() + ' (' + ISTTime.getHours() + ':' + ISTTime.getMinutes() + ':' + ISTTime.getSeconds() + ')';
      
      // Extracting UTM parameters when tracking an event
      const utmParams = ['utm_source', 'utm_medium', 'utm_campaign']
        .map(param => new URLSearchParams(window.location.search).get(param))
        .filter(param => param);
      console.log(this.extractUtmParams(utmParams), '-------------------------- utmParams -----------------------');

      properties = {
        ...properties,
        userId: this.userAccountId,
        timeStamp: timeString,
        ...this.extractUtmParams(utmParams) // Including UTM parameters
      };

      console.log('Tracking event:', eventName, properties);
      mixpanel.track(eventName, properties);
    } catch (error) {
      console.error('Error tracking Mixpanel event:', error);
    }
  }

  private extractUtmParams(utmParams: string[]): { [key: string]: string } {
    const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign'];
    const result: { [key: string]: string } = {};
  
    utmKeys.forEach((key, index) => {
      const value = utmParams[index];
      if (value) {
        result[key] = value;
      }
    });
  
    return result;
  }
  

  public updateUserEmail(): void {
    console.log('updateUserEmail called');
    this.setUserEmail();
  }

  public updateUserProfile(user: any): void {
    this.setUserEmail();
    this.setPeopleProperties(user);
  }

  public resetMixpanel(): void {
    console.log('resetMixpanel called');
    mixpanel.reset();
  }

  public setMixpanelPeopleProperties(properties: MixpanelProperties): void {
    mixpanel.people.set(properties);
  }

  public trackCharge(amount: number): void {
    mixpanel.people.track_charge(amount);
  }
}
