export class Constants {
	public static get SOCIAL_CONNECTIONS() {
		return {
			FACEBOOK: "Facebook",
			INSTAGRAM: "Instagram",
			TWITTER: "Twitter",
			REDDIT: "Reddit",
			LINKEDIN: "Linkedin",
			YOUTUBE: "Youtube",
			TELEGRAM: 'Telegram',
			DEX: "Dex",
			LIKE: "Like",
			QUOTE: "Quote",
			MENTION: "Mention",
			COMMENT: "Comment",
			SHARE: "Share",
			WATCH: "Watch",//image not added
			RETWEET: "Retweet",
			JOIN: "Join",
			SUBSCRIBE: "Subscribe", //image not added
			UNLIKE: "Unlike",
			FOLLOW: "Follow",
			GROUP: "Group",
			UPVOTE: "Upvote",
			DOWNVOTE: "Downvote",
			PAIR: "Pair",
			LIQUIDITY: "Liquidity",
			SWAP: "Swap",
			JOIN_CHAT: "Join & Chat",
			TWEET: "Tweet",
			DISCORD: "Discord",
			SUBSCRIBE_CHANNEL: "Subscribe Channel",
			WEBSITE : "Website",
			ACTIVITY: "activity"
		}
	}

	public static get SOCIAL_LOGIN_TYPE() {
		return {
			FACEBOOK: "facebook",
			GOOGLE: "google",
			CONTINUE: "Continue"

		};

	}
	public static get TXN_FEE() {
		return {
			ONE_TIME_FEES: 5,
		};

	}

	public static get TABS() {
		return {
			INCOME: 'INCOME',
			CLAIMED: 'CLAIMED',
			ACTIVITY: 'ACTIVITY'
		}
	}
	public static get IMAGE_SIZE() {
		return 5 * (2 ** 20);
	}

	public static get copyData() {
		return {
			COPY_LINK: 'Copy Link',
			COPIED: 'Copied!',
			COPY_BANNER_Code: 'Copy Banner Code',
			COPY_Refer_LINK: 'Copy link'
		};
	}

	public static get ANALYTICS() {
		return {
			ANATYTICS: "analytics",
			DEFAULT: "DEFAULT",
			APPROVED: "APPROVED",
			PREVIOUS_TAB: "previousTab",
		};
	}

	public static get TRANSACTION_STATUS() {
		return {
			COMPLETED: "COMPLETED",
			CANCELLED: "CANCELLED",
		};
	}

	public static get TWITTER_COMMENT_REGX() {
		return /^https:\/\/twitter|X|x\.com\/(?:#!\/)?(\w+)\/status(es)?\/(\d+)$/;
	}
	public static get PROMOTION_TAB() {
		return {
			PAGE: 'PAGE',
			BANNER: 'BANNER'
		}
	}
	public static get REFER_EARN_TABS() {
		return {
			USERS: "referred-users",
			CAMPAIGNS: "referral-campaigns"
		}
	}

	public static get TWITTER_URL() {
		return "https://twitter.com/login?lang=en"
	}


	public static get CONSTANTS() {
		return {
			MANUAL: "MANUAL",
			TARGET: "TARGET",
			ERROR: "Error",
			NO_DATA: " No Data Found",
			SOMETHING_WENT_WRONG: "Oops! We couldn't connect to our servers due to an internet issue. Please check your connection and try again",
			HIDDEN: "hidden",
			CAMPAIGN_SAVED_SUCCESSFULLY: "Campaign Saved Successfully",
			CAMPAIGN_PUBLISHED_SUCCESSFULLY: "Campaign published successfully",
			SUCCESS: "Success",
			ADD_PROJECT: "Add Project",
			ADD_CAMPAIGN: "Create Campaign",
			CAMPAIGN_DETAILS: "Campaign details",
			PROJECT_NAME: "Project name",
			TITLE: "Title",
			CAMPAIGN_TYPE: "Campaign Type",
			ACTION: "Action",
			DESCRIPTION: "Description",
			REWARD: "Reward",
			TOTAL_REWARD: "Total Reward",
			URL: "URL",
			TOTAL_REWARD_DISTRIBUTED: "Total Reward Distributed",
			TOTAL_PARTICIPATIONS: "Total Participations",
			USER_LIST: "User List",
			NO_DATA_MESSAGE_FOR_CAMPAIGN_DETAILS: "No action performed by user ",
			PER_DAY_LIMIT: "Per day limit can't be less than daily limit",
			OVERALL_LIMIT: "overall limit can't be less than per day limit or daily limit",
			REFERRAL: "Referral",
			TRANSACTION_CANCELED_SUCCESSFULLY: "Transaction Canceled Successfully",
			BANNERS: "Promotional Banner",
			ADD_BANNER: "Add Banner",
			EDIT_BANNER: "Edit Banner",
			LANDING_PAGES: "Landing Pages",
			ADD_LANDING_PAGE: "Add Landing Page",
			EDIT_LANDING_PAGE: "Edit Landing Page",
			DEFAULT_LANDING_PAGES: "Default Landing Pages",
			YOUTUBE: "Youtube",
			COPIED_TO_CLIPBOARD: "Copied to clipboard",
			ERROR_COPIED_TO_CLIPBOARD: "Could not copy to clipboard, please try again!",
			CAMPAIGN_ACTIVATE_SUCCESSFULLY: "Campaign activated successfully",
			CAMPAIGN_DEACTIVATE_SUCCESSFULLY: "Campaign deactivated successfully",
			IMAGE_ERROR: "Please select proper file {.jpeg .jpg .png}",
			IMAGE_SIZE_ERROR: "Max 10 mb allowed",
			BANNER_NAME_ERROR: "Banner name should not be greater than 40",
			PLEASE_SELECT_IMAGE: "Please add a image",
			SUCCESSFULLY_CREATED: "Successfully Created",
			SUCCESSFULLY_UPDATED: "Successfully Updated",
			NEXT_LEVEL: "Next Level Unclocked Successfully",
			SUCCESSFULLY_DELETED: "Successfully Deleted",
			SUCCESSFULLY_INACTIVATED: "Successfully Inactivated",
			SUCCESSFULLY_ACTIVATED: "Successfully Activated",
			NO_PAGE_FOUND: "No page found",
			TITTLE_ERROR: "Title should not be greater than 40",
			PAGE: "PAGE",
			REWARD_DISTRIBUTED_SUCCESSFULLY: "Reward Distributed Successfully",
			ISSUE_RESOLVED_SUCCESSFULLY: "Issue Resolved Successfully",
			WITHDRAWAL: "Withdrawals",
			CONFIGURATION: "Configuration",
			NO_CAMPAIGN: "No Campaign Type Data Exist",
			CREATE_CAMPAIGN: "Create Project",
			URL_VALIDATION_MESSAGE: "url can not be changed please enter verified link",
			HANDLER_CHECK: "Check to mention twitter handle in the tweet",
			SAVE_TASK: "Save Task",
			ADD_CONFIG: "Add Telegram Configuration",
			ADD_NEW_CAMPAIGN: "Add New Campaign",
			LANDING_PAGE_LIST: "Default Landing-pages list",
			NO_USER_JOINED: "No User Joined",
			ADD_PLATFORM: "Add Platform",
			NO_ISSUES_RAISED: "No ticket raised by the user",
			ISSUE_DETAIL: "ISSUE DETAIL",
			USER_DETAILS: "User Details",
			UPLOAD_AGAIN: 'Upload again',
			SUCCESSFULLY_UPLOADED: 'Successfully Uploaded',
			MAX_10_MB_ALLOWED: 'Max 10 mb allowed',
			PROJECT_DELETED_SUCCESSFULLY: "Project deleted successfully",
			CAMPAIGN_DELETED_SUCCESSFULLY: "Campaign deleted successfully",
			PROJECT_ACTIVATE_SUCCESSFULLY: "Project activated successfully",
			PROJECT_DEACTIVATE_SUCCESSFULLY: "Project deactivated successfully",
			LEFT: 'left',
			RIGHT: 'right',
			AUTO: 'AUTO',
			TIME: 'TIME',
			ACTIVE_PROJECT_TO_ADD_CAMPAIGNS: 'Active Project, to add campaigns',
			PROJECT_SUCCESSFULLY_CREATED: "Project Successfully Created",
			PROJECT_SUCCESSFULLY_UPDATED: "Project Successfully Updated",
			PLEASE_CHECK_YOUR_EMAIL: 'Please check your email',
			DEFAULT: 'DEFAULT',
			APPROVED: 'APPROVED',
			DECLINED: 'DECLINED',
			ACCEPTED: 'ACCEPTED',
			FAILED: 'FAILED',
			INVALID:'INVALID',
			DEPOSIT: 'DEPOSIT',
			PURCHASE: 'PURCHASE',
			REJECTED: 'REJECTED',
			PLEASE_VERIFY_CAPTCHA: 'Looks like the captcha verification failed. Please try again to verify'
		};
	}
	public static get numberOfRowsArray() {
		return [10, 20, 30, 40, 50]
	}

	
	public static get MIN_DEPOSIT_AMOUNT() {
		return 25;
	}
	public static get SOCIAL_PATTERN_VALIDATION_REGEX() {
		return {
			TWITTER_REGX: /^https:\/\/(twitter\.com|x\.com|X\.com)\/.*$/,
			TWITTER_PROFILE_REGEX: /^https:\/\/(?:twitter\.com|x\.com)\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+)(?:\?s=\d+)?$/,
			TWITTER_LIKE_COMMENT_REGEX: /^https:\/\/(?:twitter\.com|x\.com)\/(?:#!\/)?(\w+)\/status(?:es)?\/(\d+)(?:\?s=\d+)?$/,
			YOUTUBE_LIKE_WATCH_REGEX: /https:\/\/(?:www\.)?youtu(?:\.be\/|be.com\/\S*(?:watch|embed|shorts)(?:(?:(?=\/[-a-zA-Z0-9_]{11,}(?!\S))\/)|(?:\S*v=|v\/)))([-a-zA-Z0-9_]{11,})/,
			YOUTUBE_CHANNEL_REGEX: /^https:\/\/(www\.|m\.)?youtube\.com\/(((channel\/UC[\w-]{21}[AQgw]|(c\/|user\/)?[\w-]+))|((c\/|user\/)?[\w-]+)|@([\w.\\.@:%_+~#=-]+))$/,
			TELEGRAM_GROUP_LINK_REGEX: /^(https:\/\/)?(www[.])?(telegram|t)\.me\//,
			URL_REGEX: "^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?"
		}
	}
	public static get CONTENT_IMAGE_SIZE() {
		return 2 * (2 ** 20);
	}
	public static get SYSTEM_TOKEN_DETAILS(){
		return {name: 'BUCK',  network: "Polygun", image: ''}
	}



	public static get WALLET_TRANSACTION_TYPE(){
		return [
			{key: 'Add Fund', value: 'ADD_FUND'},
			{key: 'Fund Withdrawal', value: 'FUND_WITHDRAWAL'},
			{key: 'Package Purchased', value: 'PACKAGE_PURCHASED'}
		]
	}

	public static get WALLET_TYPE_ARR(){
		return [
			{key: 'Wallet Transactions', value: 'Transactions'},
			{key: 'Wallet Activities', value: 'Logs'},
			{key: 'Vesting Activities', value: 'vesting'}
		]
	}

	public static get PACKAGE_STATUS() {
		return {
			ACTIVE: 'ACTIVE',
			EXPIRED: 'EXPIRED',
			SUSPENDED: 'SUSPENDED',
			INACTIVE: 'INACTIVE'
		}
	}

	public static get RECEIVED_AMOUNT_PHASE() {
		return {
			FIRST_PHASE: 'FIRST_PHASE',
			SECOND_PHASE: 'SECOND_PHASE',
			THIRD_PHASE: 'THIRD_PHASE',
		}
	}
	public static get GET_USER_CATEGORY_LIST(){
		return{
			NOVICE: 'Novice',
			MICRO_INFLUENCER: 'Micro-Influencer',
			PROFESSIONAL_INFLUENCER: 'Professional-Influencer',

		}
	}
	public static get GET_USER_CATEGORY(){
		return{
			NOVICE: 'NOVICE',
			MICRO_INFLUENCER: 'MICRO_INFLUENCER',
			PROFESSIONAL_INFLUENCER: 'PROFESSIONAL_INFLUENCER',

		}
	}
	public static get GET_PACKAGE_CATEGORY(){
		return [
			{key:"NOVICE", value:"Novice"},{ key:"MICRO",value:"Micro"},{ key: "PROFESSIONAL", value:"Professional"}
		]
	}
	
	public static get NETWORK_LIST(){
		return [{key: "ethereum", value:"ETH"},   {key: "binancecoin", value:"BNB" },
	{key: "matic-network", value: "MATIC"}]
	}
	public static get NETWORK_OBJ(){
		return { "ethereum":"ETH", "binancecoin":"BNB","matic-network":"MATIC" }
	}
	public static get MIN_TOKEN_AMOUNT(){
		return {"sale": 5, "buy": 3, "withdraw": 100}
	}
	public static get GET_MIN_WITHDRAWAL_IN_DOLLLAR(){
		return {"minimum": 1}
	}

	public static get AIRDROP_DATE(){
		 return 'Sat Jan 06 2024 20:30:00 GMT+0530 (India Standard Time)'
	}
	public static get PACKAGE_TYPE(){
		return [{key: 'ACTIVITY_LIMITED', value:'regular'}, {key: 'CUSTOM_PACKAGE', value:'custom'}]
	}
	public static get CAMPAIGN_TYPE_OF_CATEGORY(){
		return {
			'Twitter': ['Tweet','Like','Retweet','Follow','Comment'],
			'Telegram': ['Group','Join & Chat', 'Subscribe Channel'],
			'Youtube': ['Like','Watch','Subscribe'],
			'Discord': ['Group','Join & Chat']
		}
	}
	public static get TASK_VIDEO_LINKS(){
		return {
			'Twitter': {'Tweet': '','Like': 'https://www.youtube.com/embed/tjREgw1GIWQ?si=xJ1PK9Vrp7yG_aAI','Retweet': 'https://www.youtube.com/embed/b1yuh06M3Vw?si=dKarH1wrz0nSbKub','Follow':'https://www.youtube.com/embed/MjD-F8SI80Q?si=oeAY4Xfon01fjYBP','Comment': 'https://www.youtube.com/embed/hUu8J3SCzUw?si=ROjZlrKoLZ8BRVeT', 'Quote':''},
			'Telegram': {'Join & Chat': 'https://www.youtube.com/embed/vj6I0T6EyP4?si=5hRmohAEkYTd5c9v', 'Subscribe Channel': 'https://www.youtube.com/embed/_BzytARBtXY?si=xkM6d0GoRWEGDVA8', 'Group': ''},
			'Youtube': {'Like': 'https://www.youtube.com/embed/12H4XkiHeZU?si=iNKDzLnyG4n6OwvK','Watch': 'https://www.youtube.com/embed/tPHe5qLO1bU?si=LxENixUftj8QtWmt','Subscribe': 'https://www.youtube.com/embed/0JFWVowyjwc?si=Scyr4cSurfDBe7fA'},
			'Discord': {'Group':'','Join & Chat':''},
			"Website":{"activity":"https://app.coinbuck.com"}
		}
	}
}