import { Injectable } from '@angular/core';
import { HttpencapService } from 'src/app/shared/services/httpencap.service';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class HeaderService {
	activatedEmitter = new Subject<boolean>();

	constructor(private http: HttpencapService) { }

	getNotifications() {
		return this.http.get('notification/v1/get/notification');
	}

	readOneNotification(data) {
		return this.http.patch('notification/v1/read/notification', data);
	}

	readAllNotification(data) {
		return this.http.patch('notification/v1/read/notification', data);
	}

	getUserProfile() {
		return this.http.get('users/v1/profile');
	}
}
