import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { ShowErrorsComponent } from './components/show-errors/show-errors.component';
import { LoaderComponent } from './components/loader/loader.component';
import { LoaderService } from './components/loader/loader.service';
import { environment } from 'src/environments/environment';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CommonApiService } from './services/common-api.service';
import { JwtHelperServices } from './services/jwt-helper.service';
import { CookiesService } from './services/cookies.service';
import { DecimalPipe } from './pipe/decimal.pipe';
import { TruncatePipe } from './pipe/truncate.pipe';
import { SocialConnectionComponent } from './components/social-connection/social-connection.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TelegramLoginComponent } from './components/telegram-login/telegram-login.component';
import { SafePipe } from './pipe/safe.pipe';
import { FilterPipe } from './pipe/filter.pipe';
import { FetchImageSrcPipe } from './pipe/fetch-image-src.pipe';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { QRCodeModule } from 'angularx-qrcode';
import { HideEmailPipe } from './pipe/hide-email.pipe';
import { NumberPipe } from './pipe/number.pipe';
import { KycVerifyComponent } from './components/kyc-verify/kyc-verify.component';
import { ShowAmountPipe } from './pipe/show-amount.pipe';
import { CustomTimerPipe } from './pipe/custom-timer.pipe';
import { NumberSuffixPipe } from './pipe/number-suffix.pipe';
import { WithdrawModalComponent } from './components/withdraw-modal/withdraw-modal.component';

const SHARED_COMPONENTS = [
	ShowErrorsComponent,
	FooterComponent,
	LoaderComponent,
	DecimalPipe,
	FilterPipe,
	TruncatePipe,
	HeaderComponent,
	SocialConnectionComponent,
	TelegramLoginComponent,
	SafePipe,
	FetchImageSrcPipe,
	HideEmailPipe,
	NumberPipe,
	ShowAmountPipe,
	CustomTimerPipe,
	NumberSuffixPipe,
	WithdrawModalComponent
	
];

const SINGLETON_SERVICES = [
	LoaderService,
	JwtHelperServices,
	CommonApiService,
	CookiesService,
	HeaderComponent
];

export const jwtConfig = {
	config: {
		tokenGetter: tokenGetter,
		headerName: environment.headerName,
		authScheme: environment.authScheme,
		throwNoTokenError: false,
		whitelistedDomains: environment.whitelistedDomains,
		blacklistedRoutes: environment.blacklistedRoutes,
	},
};
export function tokenGetter() {
	return localStorage.getItem('token');
}

@NgModule({
	declarations: [...SHARED_COMPONENTS, FilterPipe, KycVerifyComponent],
	imports: [
		CommonModule,
		RouterModule,
		HttpClientModule,
		ReactiveFormsModule,
		FormsModule,
		NgbTooltipModule,
		NgbModule,
		QRCodeModule
	],

	exports: [
		...SHARED_COMPONENTS,
	],
	providers: [
		CommonApiService,
		CookiesService
	],
})
export class SharedModule {
	static forRoot(): ModuleWithProviders<SharedModule> {
		return {
			ngModule: SharedModule,
			providers: [
				...SINGLETON_SERVICES,
			],
		};
	}
}
