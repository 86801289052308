import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppInterceptorService } from './shared/services/app-interceptor.service';
import { jwtConfig, SharedModule } from './shared/shared.module';
import { CommonModule } from '@angular/common';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbPaginationModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { CommonApiService } from './shared/services/common-api.service';
import { CookieService } from 'ngx-cookie-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PromotionPageComponent } from './promotion-page/promotion-page.component';
import { QRCodeModule } from 'angularx-qrcode';
import { OnboardingQuestionComponent } from './onboarding-question/onboarding-question.component';
import { PrivateSaleComponent } from './private-sale/private-sale.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, ReCaptchaV3Service } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';

export const httpInterceptorProvider = [
	{
		provide: HTTP_INTERCEPTORS,
		useClass: AppInterceptorService,
		multi: true
	},
];

@NgModule({
	declarations: [
		AppComponent,
		PromotionPageComponent,
  OnboardingQuestionComponent,
  PrivateSaleComponent
	],
	imports: [
		AppRoutingModule,
		BrowserModule,
		CommonModule,
		JwtModule.forRoot(jwtConfig),
		NgbModule,
		NgbPaginationModule,
		SharedModule.forRoot(),
		ToastrModule.forRoot({
			maxOpened: 1,
			autoDismiss: true
		}
		),
		BrowserAnimationsModule,
		ReactiveFormsModule,
		FormsModule,
		QRCodeModule

	],
	providers: [
		CommonApiService,
		CookieService,
		httpInterceptorProvider,
		{
			provide: RECAPTCHA_V3_SITE_KEY,
			useValue: environment.SITE_KEY,
		  },
		  ReCaptchaV3Service
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
