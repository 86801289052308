import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
	providedIn: 'root'
})

export class AESEncryptDecryptService {
    constructor() { }

     generateKey(pvtEncKey) {
        let newEncKey = CryptoJS.HmacSHA1(pvtEncKey, pvtEncKey).toString(CryptoJS.enc.Base64);
        let finalEncKey = CryptoJS.PBKDF2(newEncKey, pvtEncKey, {
            keySize: 512 / 32,
            iterations: 1000,
        }).toString(CryptoJS.enc.Hex);
        return finalEncKey;
    };
    
    generateIv () {
        let pvtEncKey = environment.PVTENCRYPTKEY; // From env
        let iv = this.generateKey(pvtEncKey);
        iv = CryptoJS.SHA256(iv).toString(CryptoJS.enc.Hex);
        return iv;
    };
    
   encyptPvtKey(pvtKey) {
        try {
            let pvtEncKey = environment.PVTENCRYPTKEY; // From env
            let key = this.generateKey(pvtEncKey);
            let iv = this.generateIv();
            let encrypted = CryptoJS.AES.encrypt(pvtKey, key, { iv: iv }).toString();
            return encrypted;
        } catch (err) {
            return "";
        }
    };
    

   decryptPvtKey(encrypted) {
        try {
            let pvtEncKey = environment.PVTENCRYPTKEY; // From env
            let key = this.generateKey(pvtEncKey);
            let iv = this.generateIv();
            let decrypted = CryptoJS.AES.decrypt(encrypted, key, { iv: iv }).toString(CryptoJS.enc.Utf8);
            return decrypted
        } catch (err) {
            return "Error in decryption"
        }
    };
}