import { Injectable } from '@angular/core';
import { HttpencapService } from './httpencap.service';

@Injectable({
  providedIn: 'root'
})
export class WalletService {

  constructor(private http: HttpencapService) { }

  getWallet(data?) {
	return this.http.get('users/v1/admin/get/wallet', data);
   }

  createUserWallet(data?) {
	return this.http.post('users/v1/admin/create/wallet', data)
  }

  createTransaction(data) {
	return this.http.post("funds/v1/user/add", data);
   }

  submitTransactionHash(data){
	return this.http.post("transactions/v1/admin/check/fund/transaction",data)
   }
  
  cancelWalletTransaction(data){
	return this.http.patch('transactions/v1/admin/update/wallet/transaction',data)
  }
  getPaymentMethods(){
	return this.http.get('funds/v1/enabled/coins')
  }
  getMinAmountOfMethod(data){
    return this.http.get('funds/v1/minimum/payout', data)
  }
  getBaseToken(){
    return this.http.get("prices/v1/get/base/tokens");
  }
  getTokenAmount(data?){
    return this.http.get("prices/v1/get/cbx/token",data);
  }
  getTokenAmountEx(){
    return this.http.get("prices/v1/get/cbx/token/exchange/price");
  }
  confirmPayment(data){
    return this.http.patch("funds/v1/user/verify",data)
  }
  cancelPayment(data){
    return this.http.patch("funds/v1/user/cancel",data)
  }
  createNeedHelpDataAuthenticated(data) {
    return this.http.post("saleleads/v1/add/authenticated", data);
  }
  checkKometWalletBalance(dts) {
    return this.http.get("kometWallets/v1/check/sufficient/funds",dts);
  }

  createKometTransaction(data) {
    return this.http.post("kometWallets/v1/transaction/verify-funds", data);
  }
  createNeedHelpDataUnauthenticated(data) {
    return this.http.post("saleleads/v1/add", data);
  }
  createKometWallet(data){
		return this.http.post("kometWallets/v1/create",data)
	}
	checkKometWalletConnected(){
		return this.http.get("kometWallets/v1/exists")
	}
  checkKometWalletToShow(){
		return this.http.get("kometWallets/v1/check/gsuite/email")
	}
  getRoundDetails(){
    return this.http.get("sales/v1/ongoing");
  }
}
