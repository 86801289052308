import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainService } from 'src/app/main/services/main.service';
import { environment } from 'src/environments/environment';
import { Constants } from '../../../config/constant';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AmountService } from '../../services/amount.service';
import { Subscription } from 'rxjs';
import { FormValidatorService } from '../../services/form-validator.service';
import noWhitespaceValidator from '../../services/no-white-space-validator';
import { Messages } from 'src/app/config/messages';
import { LoaderService } from 'src/app/shared/components/loader/loader.service';
import { DashboardService } from '../../../main/dashboard/services/dashboard.service';
import { CommonApiService } from '../../services/common-api.service';
import {  Router } from '@angular/router';
import { PackagesService } from 'src/app/main/campaign-packages/services/packages.service';

declare var $: any;

@Component({
	selector: 'app-social-connection',
	templateUrl: './social-connection.component.html',
	styleUrls: ['./social-connection.component.css']
})
export class SocialConnectionComponent implements OnInit {
	@ViewChild("buyPackage", { static: true }) buyPackage: ElementRef;
	// Decoretor
	@Input() mobileView = false;
	// object
	socialConnectionIsActiveData = {
		// Boolean to check if the social connection is active
		Facebook: false,
		Instagram: false,
		Twitter: false,
		Reddit: false,
		Linkedin: false,
		Youtube: false
	};

	// varable
	selectedSocialConnection = null;
	socialConnectionTitle = null;
	socialConnectionUrl = null;
	twitterDetails: any
	copyText: string = 'Copy';
	packageId='';

	packageDetails:any;
	tokenAmount:any;

	// String
	referralLink: any = ''
	urlPath = ''

	// Boolean to Check errorMessage
	errorMessage = false;
	twitterConnected = false;
	showData = true;


	// Array
	socialArray = [];
	socialToShow = [];
	activeSocialConnection = []

	// Constant
	SOCIAL_CONNECTIONS = Constants.SOCIAL_CONNECTIONS;
	tokenName = Constants.SYSTEM_TOKEN_DETAILS.name;

	message = Messages;

	// Form Group
	twitterForm: FormGroup;
	twitterHandleForm: FormGroup;
	



	constructor(private modalService: NgbModal,
		private mainService: MainService,
		private fb: FormBuilder,
		private toastrService: ToastrService,
		private obsService: AmountService,
		private validator: FormValidatorService,
		private loaderService: LoaderService,
		private dashboardService: DashboardService,
		public sharedService: CommonApiService,
		public router: Router,
		private packageService: PackagesService,
		private amountService: AmountService

	) { 
		this.router.events.subscribe((url:any) => {
			this.urlPath  = this.router.url
		}
		);
	}

	ngOnInit(): void {		
		this.getActiveSocialConnection(true);
		$(function(){
			$("iframe").on("load", function(e){
				  $(this).contents().find("#telegram-login-coinbuck_test_bot").css("display", "none");
			});
	  });
		this.createForm();
		this.getAllCampaignTypes();
		this.amountService.getSocialConnection().subscribe((res)=>{
			if(res){
				this.getActiveSocialConnection(true);
				this.amountService.setSocialConnectiom(false);
			}
		})

		// this.subscription = this.obsService.getMessage().subscribe(res => {
		// 	this.getSocialConnection();
		// });
		// this.activatedSub = this.mainService.activatedEmitter.subscribe(didActivated => {
		// 	if (didActivated) {
		// 		this.getSocialConnection();

		// 	}
		// });
		
		
		
	}
	/**
	 * @description: used to create twitter form
	 */
	createForm() {
		this.twitterForm = this.fb.group({
			username: ['', [Validators.required, noWhitespaceValidator, Validators.pattern("^@([a-zA-Z0-9_]){1,15}$")]]
		});
	}

	get username(): any { return this.twitterForm.get('username'); }

	/**
	 * @description: used to add twitter data
	 */
	addTwitterData() {
		if (this.twitterForm.invalid) {
			this.validator.markControlsTouched(this.twitterForm)
			return;
		}
		let data = {
			"username": this.twitterForm.value.username,
		}
		this.loaderService.show();

		this.mainService.addTwitterData(data).subscribe((resp) => {
			this.loaderService.hide();

			if (resp.error) {
				this.toastrService.error(resp.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
					timeOut: 3000
				});
			} else {
				this.closeModal();
				this.amountService.setSocialConnectiom(true);
				this.toastrService.success(this.message.CONST_MSG.DETAILS_ADDED_SUCCESS, "Success", {
					timeOut: 3000
				});
			}
		}, err => {
			this.loaderService.hide();

			this.toastrService.error(err?.error?.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
				timeOut: 3000
			});
		});

	}

	/**
	 * @description: used to connect social account
	 * @param value 
	 */
	open(value, content?) {
		if(!this.activeSocialConnection[value]?.active &&  !this.activeSocialConnection[value]?.profile){
			let id = JSON.parse(localStorage.getItem('_u')).id;
			if (value == this.SOCIAL_CONNECTIONS.FACEBOOK || value == this.SOCIAL_CONNECTIONS.INSTAGRAM) {
				window.location.href = `${environment.apiURL}auth/v1/facebook/social?id=${id}`;
			}
			else if (value == this.SOCIAL_CONNECTIONS.YOUTUBE) {
				const token: string = this.sharedService.getToken();
				const user = this.sharedService.getCurrentUserId();
				let headers: any = {
					accountId: user,
					token: token,
				}
				headers = JSON.stringify(headers);
				window.location.href = `${environment.apiURL}auth/v1/google/social?id=${id}&headers=${headers}`;
			}
			else if (value == this.SOCIAL_CONNECTIONS.TWITTER) {
				// window.location.href = `${environment.apiURL}auth/v1/twitter/social?id=${id}`;
				this.openTwitterModal(content)
			}
			else if (value == this.SOCIAL_CONNECTIONS.REDDIT) {
				window.location.href = `${environment.apiURL}auth/v1/reddit/social?id=${id}`;
			}else if (value == this.SOCIAL_CONNECTIONS.DISCORD){
				window.location.href = `${environment.apiURL}auth/v1/discord/social?id=${id}`;
			}
			else {
				return;
			}
		}else if(!this.activeSocialConnection[value]?.active && this.activeSocialConnection[value]?.profile){
			this.toastrService.info("Social connection is currently deactivated from your profile. Please enable your social accounts from profile section to proceed");
			this.router.navigate(["/main/edit-profile"])
		}
		else{
			return false;
		}
	}

	/**
	 * @description: used to login with telegram
	 * @param event 
	 */
	handleTelegramLogin(event) {
		let data = event;
		this.toastrService.info(this.message.CONST_MSG.CONNECTING_TELEGRAM_ACOUNT, "Please wait", {
			timeOut: 5000
		});
		this.mainService.loginWithTelegram(data).subscribe(response => {
			if (response.error) {
				console.log("Error");
			} else {
				this.amountService.setSocialConnectiom(true);
			}
		}, err => {
			this.toastrService.error(err?.error?.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
				timeOut: 2000
			});
		});
	}



	/**
	 * @description: used to update social connection
	 * @param value 
	 */
	onSelectSocialConnection(value) {
		if (!this.selectedSocialConnection) return;
		if (!this.socialConnectionUrl && value) {
			this.errorMessage = true;
			setTimeout(() => {
				this.errorMessage = false;
			}, 3000);
			return;
		}
		this.socialConnectionIsActiveData[this.selectedSocialConnection] = value;
		let data: any = {};
		let social = [];
		let checkIfSocialConnectionExist = false;
		for (let i = 0; i < this.socialArray.length; i++) {
			let tempData = this.socialArray[i];
			tempData.isActive = this.socialConnectionIsActiveData[tempData.type];
			if (tempData.type == this.selectedSocialConnection) {
				tempData.url = this.socialConnectionUrl || tempData.url;
				checkIfSocialConnectionExist = true;
			}
			social.push(tempData);
		}

		if (!checkIfSocialConnectionExist && value) {
			social.push({
				type: this.selectedSocialConnection,
				url: this.socialConnectionUrl
			});
		}
		data.social = social;
		this.mainService.updateSocialConnection(data).subscribe(resp => {
			if (!resp.error) {
				this.closeModal();
				this.getActiveSocialConnection();
			}
		}, err => {
			this.toastrService.error(err?.error?.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
				timeOut: 2000
			});
		});
	}

	/**
	 * @description: used to get all campaign types
	 */
	getAllCampaignTypes() {
		this.mainService.getAllCampaignTypes().subscribe((resp) => {
			if (!resp.error) {
				for (let i = 0; i < resp.data.length; i++) {
					this.socialToShow[resp.data[i].category] = true;
				}
			}
		}, (err) => {
			// this.toastrService.error(err?.error?.message || this.message.CONST_MSG.SOMETHING_WENT_WRONG, "Error", {
			// 	timeOut: 2000
			// });
		});
	}

	/**
	 * @description: used to unlink twitter account
	 * @param id 
	 */
	unLinkTwitter(id) {
		let dataToConnectSocial = {
			socailId: id,
			value: false
		};
		this.mainService.updateUserSocialProfile(dataToConnectSocial).subscribe(resp => {
			if (!resp.error) {
				this.obsService.setMessage("updatedSocialConnection");
				this.toastrService.success(this.message.CONST_MSG.SOCIAL_CONNECTION_UPDATE_SUCCESS, "Success", {
					timeOut: 3000
				});
				this.twitterConnected = false
			}
		}, error => {
			this.toastrService.error(error?.error?.message || this.message.CONST_MSG.ERROR_UPDATING_SOCIAL_CONNECTION, "Error", {
				timeOut: 3000
			});
		});
	}

	/**
	 * @description: used to close modal
	 */
	closeModal() {
		this.twitterForm.reset();
		this.twitterForm.reset();
		this.modalService.dismissAll();
	}




	

	/**
	 * @description: used to get active social accounts data
	 */
	getActiveSocialConnection(loader?) {
		if(loader){
			this.showData =false;
		}
		this.loaderService.show();
		this.mainService.getSocialConnection().subscribe(
			(resp) => {
				this.showData = true;
				this.loaderService.hide();
				if (!resp.error) {
					if (!resp.data) return;
					this.socialArray = resp?.data[0]?.social || [];
					for (let i = 0; i < this.socialArray.length; i++) {
						this.activeSocialConnection[this.socialArray[i].type] = {
							active: this.socialArray[i].isActive,
							profile: this.socialArray[i].profile,
						};
					}
				}
			},
			(err) => {
				this.showData = true;
				this.loaderService.hide();
			}
		);
	}

	openTwitterModal(content){
		this.modalService.open(content,{ size: 'md', ariaLabelledBy: 'modal-basic-title', keyboard: false, centered: true })
	}
		




}
