import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showAmount'
})
export class ShowAmountPipe implements PipeTransform {

  transform(value: any) {
    value = Number(value);
    let data:any = value;
    if(value < 1){
      data = 'Less than $1'
    }else{
      let valueArray = (value.toString().split('.'));
			let firstvalue = valueArray[0];
			let secondValue = "";
			if (valueArray[1]) {
				secondValue = "." + valueArray[1].slice(0, 4);

			}
			data = '$'+parseFloat(firstvalue + secondValue);
    }
    return data;
  }

}
