import { Injectable } from '@angular/core';
import { HttpencapService } from 'src/app/shared/services/httpencap.service';

@Injectable({
	providedIn: 'root'
})
export class AuthService {

	constructor(private http: HttpencapService) { }

	login(credentials) {
		return this.http.post('auth/v1/user/login', credentials);
	}

	register(data) {
		return this.http.post('auth/v1/user/registry', data);
	}

	verifyOTP(data) {
		return this.http.post('auth/v1/otp/verify', data);
	}

	getPasswordResetLink(data) {
		return this.http.post('auth/v1/user/forgot/password', data);
	}
	
	checkValidityOfResetLink(data) {
		return this.http.get('auth/v1/user/forgot/verify/link/' + data.token);
	}

	resetPassword(data) {
		return this.http.post('auth/v1/user/forgot/change/password', data);
	}

	verifyReferralLink(data) {
		return this.http.get('affiliates/v1/exists/sponsor', data);
	  }
	
	updateReferralLinkVisit(data) {
		return this.http.patch('affiliates/v1/update/visit', data);
	  }
	  verifyGoogleCaptcha(data) {
		return this.http.post( 'auth/v1/user/verify/captcha', data);
	  }
	logOut(data?){
		return this.http.patch('auth/v1/logout',data)
	}
}
