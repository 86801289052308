export class Messages {
	public static get CONST_MSG() {
		return {
			SLOGAN: "Your #1 Free Source of Likes, Subscribers, Tweets, Followers & Web Traffic",
			RESET: "Reset Password",
			FORGET: "Forgot password?",
			LOGIN: "Log In",
			RESET_PASSWORD: "Reset your password",
			CONFIRM_PASSWORD: "Confirm Password and Password not matched!",
			FORGET_PASSWORD: "Forgot your password?",
			GET_RESET_PASSWORD: "Get Reset Password Link",
			HAVE_ACCOUNT: "Have an account?",
			VERIFICATION_CODE: "Enter Your Verification Code.",
			VERIFY_OTP: "Verify OTP",
			NO_DATA_FOUND: 'No Data Found',
			REFER_EARN: 'Refer And Earn',
			LEADERSHIP_BOARD: 'Leader Board',
			SIGN_UP: 'Signups',
			PASSWORD_CHANGE_SUCCESS: 'Password Changed Successfully',
			PASSWORD_RESET_LINK_VERIFY: 'Password reset link has been sent on your email',
			SOMETHING_WENT_WRONG: "Oops! We couldn't connect to our servers due to an internet issue. Please check your connection and try again",
			SOMETHING_UNUSUAL_HAPPEN: 'Something unusual happend, please try again!',
			DAILY_REWARD_LIMIT_EXCEED: 'Sorry, we have reached the reward limit for this task. Please explore other tasks',
			EARNED_SUCCESS: 'Your task submitted successfully for verification',
			PERFFORMED_SUCCESS: 'Your task submitted successfully for verification',
			TASK_PERFORMED: 'Task performed succesfully',
			COPIED_TO_CLIPBOARD: 'Copied To Clipboard',
			ERROR_COPIED_TO_CLIPBOARD: "Could not copy to clipboard, please try again!",
			WRONG_LINK_OR_TASK_NOT_PERFORM: 'Wrong link provided or task not performed, Could not find any ',
			REQUEST_SUBMITTED: 'Request Submitted',
			SELECT_PROPER_IMG_FILE: 'Please upload an image in the correct format (e.g., .JPEG, .JPG,.PNG)',
			SELECT_PROPER_IMG_FILE_RAISE_ISSUE: 'Please upload an image in a supported format (e.g., JPEG, PNG) for this issue',
			MAX_5MB_ALLOWED: "The image you're trying to upload exceeds the maximum file size limit of 5MB",
			UPLOAD_AGAIN: 'Upload again',
			SERVER_ERROR_RAISE_ISSUE: "We're unable to upload the image for this issue at the moment. Please try again later",
			UPLOADED_SUCCESSFULLY: 'Uploaded succesfully',
			REPORT_RAISED_SUCCESS: 'Report raised succesfully',
			PROMOTION_NAME_LIMIT_VALIDATION: 'Promotion creation failed. Please ensure your text meets the character limit of 80 characters and try again',
			NAME_ALREADY_EXIST: 'A promotion with the same name already exists. Please choose a different name',
			REWARD_GRANTED: 'Reward Granted',
			PROFILE_UPDATED_SUCCESSFULLY: 'Profile Updated Successfully',
			CONFIG_UPDATED_SUCCESSFULLY: 'Config Updated Successfully',
			ERROR_IN_UPDATE_PROFILE_PIC: 'Oops! We encountered an error while updating your profile picture. Please try again later',
			SOCIAL_CONNECTION_UPDATE_SUCCESS: 'Social connection updated successfully',
			ERROR_UPDATING_SOCIAL_CONNECTION: "We're sorry! There was an error updating your Twitter account. Please try again",
			DETAILS_ADDED_SUCCESS: 'Details Added Successfully',
			COULD_NOT_COPY_TEXT: 'Could not copy text',
			TRY_AGAIN: 'Please try again!',
			WALLET_CREATED_SUCCESSFULLY:'Wallet created successfully',
			SUCCESS: 'Success',
			ERROR: 'Error',
			ENTER_AMOUNT:'Please enter amount!',
			MAX_LIMIT:'Max limit exceeded!',
			SELECT_COIN:'Please select coin to make payment!',
			INVOICE_SAVED:'This invoice is saved in "My Deposit" inside Wallet and is valid for 1 hour',
			ENTER_TXN:'Please enter transaction hash!',
			MAX_2_MB_ALLOWED: 'Max 2 mb allowed!',
			TRANSACTION_SUCCESSFULL:'Thank you! This transaction will be completed on Successful Payment',
			INVOICE_CANCELLED_AUTOMATICALLY:'Your token purchase transaction has been cancelled. If you encounter any issues, please reach out to support',
			PLEASE_SELECT_FILE: 'Please select png, jpeg, jpg, gif or svg file!',
			TRANSACTION_CANCELLED_SUCCESSFULLY: 'Your transaction has been cancelled. If you need further assistance, please contact our support team',
			CONNECTING_TELEGRAM_ACOUNT: 'Connecting your telegram account',
			PLEASE_CHECK_YOUR_EMAIL: 'Please check your email',
			PLEASE_CONFIRM_WALLET_ADDRESS: 'Please confirm your address to proceed with the withdrawal',
			PROVIDE_VALID_WALLET_ADDRESS: 'The withdrawal address provided is not valid. Please double-check and try again',
			PACKAGE_PURCHASE_SUCCESS: 'Package Purchased Successfully',
			COUPON_APPLIED: 'Coupon applied successfully',
			COUPON_NOT_APPLICABLE: "Sorry, this coupon is not applicable for the selected package. Please choose another package",
			PAYMENT_NOT_DONE: "Uh-oh! Something went wrong with your purchase. Please try again or contact support for assistance"
		}
	}
	public static get LOGIN() {
		return {
			GOOGLE: "Sign in with Google",
			FACEBOOK: "Log in with Facebook",
			SIGN_IN: "Sign in with your social media account",
			LOGIN: "Welcome back !"


		}
	}
	public static get SIGN_UP() {
		return {
			SIGN_UP: "Sign up with your social media account",
			GOOGLE: "Sign up with Google",
			FACEBOOK: "Sign up with Facebook",
			INSTANT_ACCESS: "Fill in the below to get instant access."


		}
	}
	public static get DASHBOARD() {
		return {
			CONNECT_TELEGRAM: 'Please connect your telegram from connections menu.',
			ACTIVITY: 'ACTIVITY LOGS'
		}
	}
	public static get EARNINGS() {
		return {
			EARN_TODAY: " Today",
			TODAY_OVERVIEW: 'Overview of Today',
			EARNED_THIS_WEEK: 'This Week',
			EARNED_LAST_WEEK: 'Overview of Last Week',
			TOTAL_COIN_EARNINGS: 'Total  ',
			OVERVIEW_LAST_MONTH: 'Overview of Last month',
			EARNED_THIS_MONTH: 'This Month'

		}
	}

	public static get message() {
		return {
			RECORD_NOT_FOUND: 'Record not found',
		}
	}
}