import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'number'
})
export class NumberPipe implements PipeTransform {
	transform(value: any, type = 0) {
		if (type == 1) {
			return Math.ceil(value);
		} else if (type = 2){
			return Math.round(value);
		} else {
			return Math.floor(value);
		}

	}
}
